import { SpacingPropsType } from './getSpacingClassNames';

const stripSpacingProps = <PropsType>({
  margin,
  marginX,
  marginY,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  ...otherProps
}: PropsType & SpacingPropsType): Omit<
  PropsType,
  | 'margin'
  | 'marginX'
  | 'marginY'
  | 'marginTop'
  | 'marginRight'
  | 'marginBottom'
  | 'marginLeft'
> => otherProps;

export default stripSpacingProps;
