'use client';

import { Skeleton } from './skeleton';

const Loading = () => {
  // Define an array to represent the skeleton structure
  const skeletonSections = [
    { className: 'h-14 w-full rounded-md', key: 'select-airport' }, // Step 1: Select Airport
    { className: 'h-14 w-full rounded-md', key: 'departure-date' }, // Step 2: Departure Date
    { className: 'h-14 w-full rounded-md', key: 'arrival-date' }, // Step 3: Arrival Date
    { className: 'h-14 w-full rounded-md', key: 'vehicle-type' }, // Step 4: Vehicle Type
    { className: 'h-12 w-40 rounded-full mx-auto', key: 'submit-button' }, // Step 5: Submit Button
  ];

  return (
    <div className="relative z-40">
      <div className="animate-pulse">
        <div className="flex flex-col space-y-[30.5px] w-full">
          {skeletonSections.map(section => (
            <Skeleton key={section.key} className={section.className} />
          ))}
        </div>
      </div>
    </div>
  );
};

export { Loading };
