export const Germany = (props: Omit<React.ComponentProps<'svg'>, 'children'>) => (
  <svg
    {...props}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 12H32V19.2H0V12Z" fill="#EC1C24" />
    <path
      d="M27 4.80005H5C1.6865 4.80005 0 7.16405 0 10.08V12H32V10.08C32 7.16405 30.3135 4.80005 27 4.80005Z"
      fill="#25333A"
    />
    <path
      d="M0 21.12C0 24.036 1.6865 26.4 5 26.4H27C30.3135 26.4 32 24.036 32 21.12V19.2H0V21.12Z"
      fill="#F9CB38"
    />
  </svg>
);
