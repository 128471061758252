export const CardIcon = (props: Omit<React.ComponentProps<'svg'>, 'children'>) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_1029_5758"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="25"
    >
      <rect y="0.5" width="24" height="24" fill="currentColor" />
    </mask>
    <path
      d="M15.8333 14.1666V10.8333H4.16667V14.1666H15.8333ZM15.8333 9.16663V5.83329H4.16667V9.16663H15.8333ZM4.16667 15.8333C3.70833 15.8333 3.31597 15.6701 2.98958 15.3437C2.66319 15.0173 2.5 14.625 2.5 14.1666V5.83329C2.5 5.37496 2.66319 4.9826 2.98958 4.65621C3.31597 4.32982 3.70833 4.16663 4.16667 4.16663H15.8333C16.2917 4.16663 16.684 4.32982 17.0104 4.65621C17.3368 4.9826 17.5 5.37496 17.5 5.83329V14.1666C17.5 14.625 17.3368 15.0173 17.0104 15.3437C16.684 15.6701 16.2917 15.8333 15.8333 15.8333H4.16667Z"
      fill="currentColor"
    />
  </svg>
);
