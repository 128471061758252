import { format, Locale } from 'date-fns';
import { nl, enUS } from 'date-fns/locale';
import * as React from 'react';

import { Icon } from '@icons/icons';
import { TimePicker } from '../time-picker/custom-time-picker';
import { Popover, PopoverContent, PopoverTrigger } from '../popover';

import { Calendar, CalendarProps } from '../calendar';
import Button from '../button/Button';

type CalendarVariant = 'default' | 'overlay';
export type DateTimePickerProps = {
  value?: Date;
  onChange?: (date: Date | undefined) => void;
  isDisabled?: boolean;
  placeholder?: string;
  yearRange?: number;
  displayDateFormat?: 'dd/MM/yyyy' | 'dd-MM-yyyy';
  title?: string;
  className?: string;
  localeCalendar?: string;
  calendarVariant?: CalendarVariant;
  position?: 'departure' | 'arrival';
  calendarTitle?: string;
  timePlaceholder?: string;
  hourPlaceholder?: string;
  side?: 'left' | 'bottom';
  isTimeSeparated?: boolean;
  isGray?: boolean;
  minDate?: Date;
} & Pick<
  CalendarProps,
  'locale' | 'weekStartsOn' | 'showWeekNumber' | 'showOutsideDays'
>;

const DateTimePicker = ({
  localeCalendar = 'enUS',
  value,
  onChange,
  yearRange = 50,
  isDisabled = false,
  displayDateFormat = 'dd/MM/yyyy',
  placeholder = 'Pick a date',
  title = '',
  calendarTitle = '',
  calendarVariant = 'default',
  timePlaceholder = '',
  hourPlaceholder = '',
  className,
  position,
  side = 'left',
  isTimeSeparated = true,
  isOpen = false,
  isGray = false,
  ...props
}: DateTimePickerProps & { isOpen?: boolean }) => {
  const [isDateActive, setIsDateActive] = React.useState(false); // Manage popover state for date
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(value); // Date state
  const [currentMonth, setCurrentMonth] = React.useState<Date>(value || new Date()); // Track current month
  const cardRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (isOpen) {
      setIsDateActive(true);
    }
  }, [isOpen]);

  // Handle date selection
  const localeMap: { 'en-US': Locale; 'nl-NL': Locale } = {
    'en-US': enUS,
    'nl-NL': nl,
  };
  const locale = localeMap[localeCalendar as keyof typeof localeMap];

  const handleDateSelect = (newDay: Date | undefined) => {
    if (!newDay) return;
    setSelectedDate(prev => {
      const newDate = new Date(newDay);
      if (prev) {
        newDate.setHours(prev.getHours(), prev.getMinutes(), 0, 0); // Retain time if already set
      }
      return newDate;
    });
  };

  // Handle time selection
  const handleTimeSelect = (time: Date | undefined) => {
    if (!time || !selectedDate) return;
    const updatedDate = new Date(selectedDate);
    updatedDate.setHours(time.getHours(), time.getMinutes(), 0, 0); // Update hours and minutes
    setSelectedDate(updatedDate);
    onChange?.(updatedDate); // Pass full date and time to parent
    setIsDateActive(false); // Close popover after setting both date and time
  };

  // Handle month change
  const handleMonthChange = (newMonth: Date) => {
    setCurrentMonth(newMonth); // Update current month
  };

  const initHourFormat = 'HH:mm';

  return (
    <>
      {isTimeSeparated ? (
        <div className="flex items-center space-x-4 w-full">
          <Popover open={isDateActive} onOpenChange={setIsDateActive}>
            <PopoverTrigger asChild disabled={isDisabled}>
              <Button
                variant="input"
                size="dropdown"
                radius="fullRounded"
                fullWidth
                isActive={isDateActive}
              >
                <div className="flex items-center space-x-2 w-full">
                  <div className="flex-shrink-0">
                    <Icon name="EventRoundedIcon" iconColor="default" size={7} />
                  </div>
                  <div className="flex flex-col items-start w-full">
                    <span className="text-sm text-eyebrowText">{title}</span>
                    <span className="text-primaryText text-sm font-regular truncate text-left w-full">
                      {value
                        ? format(value, displayDateFormat, { locale })
                        : placeholder}
                    </span>
                  </div>
                </div>
              </Button>
            </PopoverTrigger>
            <PopoverContent
              variant="compact"
              rounded="xl"
              shadow
              side={side}
              position={position}
              ref={cardRef}
            >
              <Calendar
                mode="single"
                variant={calendarVariant}
                title={calendarTitle}
                selected={selectedDate}
                month={currentMonth}
                onSelect={handleDateSelect} // Trigger on date selection
                onMonthChange={handleMonthChange} // Trigger on month navigation
                locale={locale}
                disabled={{ before: props.minDate || new Date() }}
                {...props}
              />
              <div className="p-1">
                <TimePicker
                  placeholder={timePlaceholder}
                  value={
                    value
                      ? value.toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false,
                        })
                      : timePlaceholder
                  }
                  date={selectedDate}
                  onChange={handleTimeSelect} // Trigger on time selection
                  interval={15} // Optional: Adjust time interval
                />
              </div>
            </PopoverContent>
          </Popover>
          <Popover open={isDateActive} onOpenChange={setIsDateActive}>
            <PopoverTrigger asChild disabled={isDisabled}>
              <Button
                variant="input"
                size="dropdown"
                radius="fullRounded"
                fullWidth
                isActive={isDateActive}
              >
                <div className="flex items-center space-x-2 w-full">
                  <div className="flex-shrink-0">
                    <Icon
                      name="WatchLaterOutlinedIcon"
                      iconColor="default"
                      size={7}
                    />
                  </div>
                  <div className="flex flex-col items-start w-full">
                    <span className="text-sm text-gray-600">{hourPlaceholder}</span>
                    <span className="text-primaryText text-sm font-regular truncate text-left w-full">
                      {value ? format(value, initHourFormat, { locale }) : 'Time'}
                    </span>
                  </div>
                </div>
              </Button>
            </PopoverTrigger>
          </Popover>
        </div>
      ) : (
        <Popover open={isDateActive} onOpenChange={setIsDateActive}>
          <PopoverTrigger asChild disabled={isDisabled}>
            <Button
              variant={isGray ? 'dropdownGray' : 'dropdown'}
              size="dropdown"
              radius={isGray ? 'fullRounded' : 'normal'}
            >
              <div className="flex justify-between items-center w-full">
                <span className="text-md font-normal text-eyebrowText">{title}</span>
              </div>

              <div className="flex items-center justify-between space-x-1">
                <div className="flex items-center justify-start">
                  <div className="mr-1">
                    <Icon name="EventRoundedIcon" iconColor="default" size={6} />
                  </div>
                  <span className="text-primaryText text-md font-regular truncate ">
                    {value
                      ? format(value, displayDateFormat, { locale })
                      : placeholder}
                  </span>
                </div>

                <div className="flex items-center justify-end w-[5rem]">
                  <div className="mr-1 text-blue-400">
                    <Icon
                      name="WatchLaterOutlinedIcon"
                      size={6}
                      iconColor="default"
                    />
                  </div>
                  <span className="text-primaryText text-md font-regular truncate">
                    {value ? format(value, initHourFormat, { locale }) : '--:--'}
                  </span>
                </div>
              </div>
            </Button>
          </PopoverTrigger>
          <PopoverContent variant="compact" shadow rounded="xl">
            <Calendar
              mode="single"
              variant={calendarVariant}
              title={calendarTitle}
              selected={selectedDate}
              month={currentMonth}
              onSelect={handleDateSelect} // Trigger on date selection
              onMonthChange={handleMonthChange} // Trigger on month navigation
              locale={locale}
              disabled={{ before: props.minDate || new Date() }}
              {...props}
            />
            <div className="p-1">
              <TimePicker
                placeholder={timePlaceholder}
                value={
                  value
                    ? value.toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                      })
                    : timePlaceholder
                }
                date={selectedDate}
                onChange={handleTimeSelect}
                interval={15}
              />
            </div>
          </PopoverContent>
        </Popover>
      )}
    </>
  );
};

export { DateTimePicker };
