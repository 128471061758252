'use client';
import React from 'react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './dropdown-menu';
import { Icon } from '@icons/icons';

type DropDownItems = {
  label: string;
  link: string;
};

export interface CustomDropDownProps {
  children: React.ReactNode;
  items: Array<DropDownItems>;
  dropHeader?: string;
  fontColour?: string;
}

export const CustomDropDown = ({
  children,
  items,
  dropHeader = '',
  fontColour = 'text-black',
}: CustomDropDownProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <ul>
          <li
            className={`flex px-3 cursor-pointer items-center text-md ${fontColour}`}
          >
            <span className="font-normal lg:text-base-desktop">{children}</span>
            <span className="ml-2 w-4">
              {<Icon name="KeyboardArrowDownRoundedIcon" />}
            </span>
          </li>
        </ul>
      </DropdownMenuTrigger>

      <DropdownMenuContent>
        {dropHeader && <DropdownMenuLabel>{dropHeader}</DropdownMenuLabel>}
        {dropHeader && <DropdownMenuSeparator />}
        {items.map(item => (
          <DropdownMenuItem asChild key={item.label}>
            <a href={item.link}>{item.label}</a>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
