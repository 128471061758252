export const Netherlands = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    {...props}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 12.8H32V20H0V12.8Z" fill="#EEEEEE" />
    <path
      d="M28.4444 4.80005H3.55556C2.61256 4.80005 1.70819 5.17465 1.0414 5.84145C0.374602 6.50824 0 7.41261 0 8.3556L0 12.8H32V8.3556C32 7.41261 31.6254 6.50824 30.9586 5.84145C30.2918 5.17465 29.3874 4.80005 28.4444 4.80005Z"
      fill="#AE1F28"
    />
    <path
      d="M3.55556 27.2H28.4444C29.3874 27.2 30.2918 26.8253 30.9586 26.1586C31.6254 25.4918 32 24.5874 32 23.6444V19.2H0V23.6444C0 24.5874 0.374602 25.4918 1.0414 26.1586C1.70819 26.8253 2.61256 27.2 3.55556 27.2Z"
      fill="#20478B"
    />
  </svg>
);
