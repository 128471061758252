import { cn } from '@icons/lib/utils';

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  width?: string | number | 'fullWidth';
  minWidth?: string | number;
  maxWidth?: string | number;
  height?: string | number;
  minHeight?: string | number;
  maxHeight?: string | number;
  rounded?: boolean | string;
  bgColor?: string;
  animation?: 'pulse' | 'wave' | 'none';
  variant?: 'card' | 'input' | 'text' | 'avatar';
}

function Skeleton({
  className,
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  maxHeight,
  rounded = 'md',
  bgColor = 'bg-gray-300',
  animation = 'pulse',
  variant,
  style,
  ...props
}: SkeletonProps) {
  // Animation Classes
  const animationClass =
    animation === 'pulse'
      ? 'animate-pulse'
      : animation === 'wave'
        ? 'animate-[wave_1.5s_infinite]'
        : '';

  // Variant Default Styles
  const variantStyles = (() => {
    switch (variant) {
      case 'card':
        return { width: width || '100%', height: height || '300px', rounded: 'lg' };
      case 'input':
        return { width: width || '100%', height: height || '40px', rounded: 'md' };
      case 'text':
        return { width: width || '80%', height: height || '20px', rounded: 'sm' };
      case 'avatar':
        return { width: width || '50px', height: height || '50px', rounded: 'full' };
      default:
        return {};
    }
  })();

  const resolvedWidth =
    width === 'fullWidth' ? '100%' : variantStyles.width || width;

  const resolvedRounded =
    typeof rounded === 'string' ? `rounded-${rounded}` : rounded ? 'rounded-md' : '';

  return (
    <div
      className={cn(animationClass, resolvedRounded, bgColor, className)}
      style={{
        width:
          typeof resolvedWidth === 'number' ? `${resolvedWidth}px` : resolvedWidth,
        minWidth: minWidth
          ? typeof minWidth === 'number'
            ? `${minWidth}px`
            : minWidth
          : undefined,
        maxWidth: maxWidth
          ? typeof maxWidth === 'number'
            ? `${maxWidth}px`
            : maxWidth
          : undefined,
        height: variantStyles.height || height,
        minHeight: minHeight
          ? typeof minHeight === 'number'
            ? `${minHeight}px`
            : minHeight
          : undefined,
        maxHeight: maxHeight
          ? typeof maxHeight === 'number'
            ? `${maxHeight}px`
            : maxHeight
          : undefined,
        ...style,
      }}
      {...props}
    />
  );
}

export { Skeleton };
