import { cn } from '../../../../lib/utils';

const baseClassNames = cn(
  'relative',
  'inline-flex',
  'align-middle',
  'justify-center',
  'items-center',
  'border',
  'outline-none',
  'whitespace-nowrap',
  'transition',
  'focus:outline-none',
  'disabled:cursor-not-allowed',
  'active:shadow-none',
  'hover:no-underline'
);

export default baseClassNames;
