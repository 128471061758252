import * as React from 'react';
import { Icon } from '@icons/icons';

interface TimePickerProps {
  placeholder?: string;
  value?: string;
  date?: Date | undefined;
  onChange?: (date: Date | undefined) => void;
  interval?: number;
}

const TimePicker: React.FC<TimePickerProps> = ({
  date,
  placeholder = '',
  value,
  onChange,
  interval = 15,
}) => {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [selectedTime, setSelectedTime] = React.useState<Date | undefined>();
  const dropdownRef = React.useRef<HTMLDivElement | null>(null); // Reference for dropdown container

  const generateTimeOptions = () => {
    const times = [];
    const start = new Date();
    start.setHours(0, 0, 0, 0);

    for (let i = 0; i < 24 * (60 / interval); i++) {
      const optionDate = new Date(start);
      optionDate.setMinutes(i * interval);
      times.push(optionDate);
    }
    return times;
  };

  const handleTimeSelect = (time: Date) => {
    const updatedDate = date ? new Date(date) : new Date();
    updatedDate.setHours(time.getHours());
    updatedDate.setMinutes(time.getMinutes());
    updatedDate.setSeconds(0);
    onChange?.(updatedDate);
    setSelectedTime(updatedDate);
    setShowDropdown(false); // Close dropdown on select
  };

  const scrollDropdown = (direction: 'up' | 'down') => {
    if (dropdownRef.current) {
      const scrollAmount = 100; // Amount to scroll
      dropdownRef.current.scrollBy({
        top: direction === 'up' ? -scrollAmount : scrollAmount,
        behavior: 'smooth', // Smooth scrolling
      });
    }
  };

  return (
    <div className="relative flex items-center justify-center gap-2 p-2 mt-4 rounded-full shadow-sm">
      <label
        htmlFor="datetime-picker-hour-input"
        className="cursor-pointer flex items-center gap-1"
      >
        <span className="text-sm">{placeholder}</span>
      </label>
      <div
        className="flex items-center gap-1 cursor-pointer bg-gray-100 w-[5.5rem] mx-2 p-3 rounded-full justify-between"
        onClick={() => setShowDropdown(prev => !prev)}
      >
        <span className="text-center text-sm">
          {selectedTime
            ? selectedTime.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
              })
            : value}
        </span>
        <Icon name="UnfoldMoreOutlinedIcon" iconColor="default" size={5} />
      </div>

      {showDropdown && (
        <div className="absolute bottom-full left-1/2 ml-4 transform -translate-x-1/2 w-36 bg-white shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] rounded-2xl">
          <div
            className="flex justify-center items-center cursor-pointer py"
            onClick={() => scrollDropdown('up')}
          >
            <Icon name="KeyboardArrowUpRoundedIcon" iconColor="dark" />
          </div>
          <div
            ref={dropdownRef} // Attach ref to the dropdown container
            className="max-h-60 overflow-y-auto"
          >
            {generateTimeOptions().map((timeOption, index) => (
              <div
                key={index}
                onClick={() => handleTimeSelect(timeOption)}
                className="py-2 text-center cursor-pointer hover:bg-gray-100 rounded"
              >
                {timeOption.toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                })}
              </div>
            ))}
          </div>
          <div
            className="flex justify-center items-center cursor-pointer"
            onClick={() => scrollDropdown('down')}
          >
            <Icon name="KeyboardArrowDownRoundedIcon" iconColor="dark" />
          </div>
        </div>
      )}
    </div>
  );
};

export { TimePicker };
export type { TimePickerProps };
