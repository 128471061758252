import { getCookie, deleteCookie, setCookie } from 'cookies-next';
import { environment } from '../../environment';

type CookiesKey = 'accessToken' | 'refreshToken' | 'user';

// Set a value in cookies
export const setItemInCookies = (
  key: CookiesKey,
  value: string,
  options?: object
): void => {
  setCookie(key, value, {
    path: '/', // Default path
    httpOnly: false, // Prevent JavaScript access
    secure: environment.NODE_ENV === 'production', // Use only over HTTPS in production
    sameSite: 'lax', // CSRF protection
    ...options, // Allow overriding options
  });
};

// Get a value from cookies
export const getItemFromCookies = (key: CookiesKey): string | undefined => {
  const value = getCookie(key);
  return typeof value === 'string' ? value : undefined;
};

export const removeItemFromCookies = (key: CookiesKey): void => {
  deleteCookie(key, { path: '/' });
};
