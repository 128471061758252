export const AirplaneIcon = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M14.3666 13.8666L6.03322 10.2666C5.49989 10.0221 5.17211 9.6277 5.04989 9.08325C4.92767 8.53881 5.07767 8.05547 5.49989 7.63325L6.03322 7.09992C6.211 6.92214 6.42767 6.78881 6.68322 6.69992C6.93878 6.61103 7.19989 6.58881 7.46656 6.63325L19.4666 8.76659L23.5999 4.59992C24.111 4.08881 24.7388 3.83325 25.4832 3.83325C26.2277 3.83325 26.8554 4.08881 27.3666 4.59992C27.8777 5.11103 28.1332 5.73881 28.1332 6.48325C28.1332 7.2277 27.8777 7.85547 27.3666 8.36659L23.2332 12.5333L25.3332 24.4999C25.3777 24.7666 25.361 25.0333 25.2832 25.2999C25.2054 25.5666 25.0777 25.7888 24.8999 25.9666L24.3666 26.4666C23.9443 26.8888 23.4554 27.0444 22.8999 26.9333C22.3443 26.8221 21.9443 26.4999 21.6999 25.9666L18.1332 17.6333L14.7332 20.9999L15.4666 25.1333C15.511 25.3555 15.4999 25.5721 15.4332 25.7833C15.3666 25.9944 15.2554 26.1777 15.0999 26.3333L14.5332 26.8999C14.2221 27.211 13.8388 27.3333 13.3832 27.2666C12.9277 27.1999 12.5888 26.9666 12.3666 26.5666L9.93322 22.0666L5.43322 19.6333C5.03322 19.411 4.79989 19.0721 4.73322 18.6166C4.66656 18.161 4.78878 17.7777 5.09989 17.4666L5.66656 16.8999C5.82211 16.7444 6.00545 16.6333 6.21656 16.5666C6.42767 16.4999 6.64433 16.4888 6.86656 16.5333L10.9666 17.2333L14.3666 13.8666Z"
      fill="#12A3FD"
    />
  </svg>
);
