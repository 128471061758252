'use client';

import { useState, useEffect } from 'react';
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselNext,
  CarouselPrevious,
} from './carousel';

type CustomCarouselPropsType = {
  children: React.ReactNode;
  carouselNumber: number;
  dotClassName?: string;
  carouselClassName?: string;
  isNavigation?: boolean;
  previousClassName?: string;
  nextClassName?: string;
};

export const CustomCarousel = ({
  children,
  carouselNumber,
  dotClassName,
  carouselClassName,
  isNavigation = false,
  previousClassName,
  nextClassName,
}: CustomCarouselPropsType) => {
  const [carouselApi, setCarouselApi] = useState<CarouselApi | null>();
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (!carouselApi) return;

    const onSelect = () => {
      setSelectedIndex(carouselApi.selectedScrollSnap());
    };

    carouselApi.on('select', onSelect);

    return () => {
      carouselApi.off('select', onSelect);
    };
  }, [carouselApi]);
  return (
    <>
      <Carousel
        setApi={setCarouselApi}
        opts={{ loop: true }}
        className={`relative ${carouselClassName}`}
      >
        <CarouselContent className="flex gap-4 mx-0 h-full items-stretch">
          {children}{' '}
        </CarouselContent>
        {isNavigation && (
          <>
            <CarouselPrevious className={previousClassName} />
            <CarouselNext className={nextClassName} />
          </>
        )}
      </Carousel>

      <div className={`flex justify-center ${dotClassName || 'mt-6'}`}>
        {Array.from({ length: carouselNumber }, (_, index) => (
          <span
            key={index}
            onClick={() => carouselApi?.scrollTo(index)}
            className={`h-3 w-3 mx-1 cursor-pointer rounded-full z-20 ${
              selectedIndex === index
                ? 'bg-dotBackgroundActive w-5'
                : 'bg-dotBackgroundInactive'
            }`}
          />
        ))}
      </div>
    </>
  );
};
