import { config } from 'dotenv';
import { z } from 'zod';

const environmentSchema = z.object({
  NODE_ENV: z
    .union([z.literal('development'), z.literal('production'), z.literal('test')])
    .default('development'),
  API_ENDPOINT: z.string().default('http://localhost:4010'),
  SESSION_SECRET: z.string().default('ipR10nYRmJmEmaJCcofvkEC0M76t+mW9AuwPyDezMuQ='),

  // OAuth2 configuration
  CLIENT_ID: z.string().default('shared-iam-client'),
  REDIRECT_URI: z.string().default('http://localhost:3000/callback'),
  AUTH_ENDPOINT: z.string().default('http://localhost:4444/oauth2/auth'),
  LOGOUT_ENDPOINT: z
    .string()
    .default('http://localhost:4444/oauth2/sessions/logout'),
  GOOGLE_MAPS_API: z.string().default('AIzaSyCJtaw1280YkpDH03UeZGGIXRBCAH_28A0'),
});

config();

const parsed = environmentSchema.safeParse(process.env);

if (!parsed.success) {
  throw new Error(`❌ Invalid environment variables: ${parsed.error.toString()}`);
}

export const environment = parsed.data;
