export const MoreIcon = (props: Omit<React.ComponentProps<'svg'>, 'children'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M6.66669 20C5.55558 20 4.61113 19.6111 3.83335 18.8333C3.05558 18.0556 2.66669 17.1111 2.66669 16C2.66669 14.8889 3.05558 13.9444 3.83335 13.1667C4.61113 12.3889 5.55558 12 6.66669 12C7.7778 12 8.72224 12.3889 9.50002 13.1667C10.2778 13.9444 10.6667 14.8889 10.6667 16C10.6667 17.1111 10.2778 18.0556 9.50002 18.8333C8.72224 19.6111 7.7778 20 6.66669 20ZM6.66669 17.3333C7.04447 17.3333 7.36113 17.2056 7.61669 16.95C7.87224 16.6944 8.00002 16.3778 8.00002 16C8.00002 15.6222 7.87224 15.3056 7.61669 15.05C7.36113 14.7944 7.04447 14.6667 6.66669 14.6667C6.28891 14.6667 5.97224 14.7944 5.71669 15.05C5.46113 15.3056 5.33335 15.6222 5.33335 16C5.33335 16.3778 5.46113 16.6944 5.71669 16.95C5.97224 17.2056 6.28891 17.3333 6.66669 17.3333ZM16 20C14.8889 20 13.9445 19.6111 13.1667 18.8333C12.3889 18.0556 12 17.1111 12 16C12 14.8889 12.3889 13.9444 13.1667 13.1667C13.9445 12.3889 14.8889 12 16 12C17.1111 12 18.0556 12.3889 18.8334 13.1667C19.6111 13.9444 20 14.8889 20 16C20 17.1111 19.6111 18.0556 18.8334 18.8333C18.0556 19.6111 17.1111 20 16 20ZM16 17.3333C16.3778 17.3333 16.6945 17.2056 16.95 16.95C17.2056 16.6944 17.3334 16.3778 17.3334 16C17.3334 15.6222 17.2056 15.3056 16.95 15.05C16.6945 14.7944 16.3778 14.6667 16 14.6667C15.6222 14.6667 15.3056 14.7944 15.05 15.05C14.7945 15.3056 14.6667 15.6222 14.6667 16C14.6667 16.3778 14.7945 16.6944 15.05 16.95C15.3056 17.2056 15.6222 17.3333 16 17.3333ZM25.3334 20C24.2222 20 23.2778 19.6111 22.5 18.8333C21.7222 18.0556 21.3334 17.1111 21.3334 16C21.3334 14.8889 21.7222 13.9444 22.5 13.1667C23.2778 12.3889 24.2222 12 25.3334 12C26.4445 12 27.3889 12.3889 28.1667 13.1667C28.9445 13.9444 29.3334 14.8889 29.3334 16C29.3334 17.1111 28.9445 18.0556 28.1667 18.8333C27.3889 19.6111 26.4445 20 25.3334 20Z"
      fill="#12A3FD"
    />
  </svg>
);
