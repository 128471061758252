export const FacilitiesIcon = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1 15H12.9C13.05 15 13.1792 14.9375 13.2875 14.8125C13.3958 14.6875 13.4333 14.55 13.4 14.4L12.925 11.775C13.2583 11.6083 13.5208 11.3667 13.7125 11.05C13.9042 10.7333 14 10.3833 14 10C14 9.45 13.8042 8.97917 13.4125 8.5875C13.0208 8.19583 12.55 8 12 8C11.45 8 10.9792 8.19583 10.5875 8.5875C10.1958 8.97917 10 9.45 10 10C10 10.3833 10.0958 10.7333 10.2875 11.05C10.4792 11.3667 10.7417 11.6083 11.075 11.775L10.6 14.4C10.5667 14.55 10.6042 14.6875 10.7125 14.8125C10.8208 14.9375 10.95 15 11.1 15ZM12 21.9C11.8833 21.9 11.775 21.8917 11.675 21.875C11.575 21.8583 11.475 21.8333 11.375 21.8C9.125 21.05 7.33333 19.6625 6 17.6375C4.66667 15.6125 4 13.4333 4 11.1V6.375C4 5.95833 4.12083 5.58333 4.3625 5.25C4.60417 4.91667 4.91667 4.675 5.3 4.525L11.3 2.275C11.5333 2.19167 11.7667 2.15 12 2.15C12.2333 2.15 12.4667 2.19167 12.7 2.275L18.7 4.525C19.0833 4.675 19.3958 4.91667 19.6375 5.25C19.8792 5.58333 20 5.95833 20 6.375V11.1C20 13.4333 19.3333 15.6125 18 17.6375C16.6667 19.6625 14.875 21.05 12.625 21.8C12.525 21.8333 12.425 21.8583 12.325 21.875C12.225 21.8917 12.1167 21.9 12 21.9ZM12 19.9C13.7333 19.35 15.1667 18.25 16.3 16.6C17.4333 14.95 18 13.1167 18 11.1V6.375L12 4.125L6 6.375V11.1C6 13.1167 6.56667 14.95 7.7 16.6C8.83333 18.25 10.2667 19.35 12 19.9Z"
      fill="#1C1B1F"
    />
  </svg>
);
