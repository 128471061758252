import { cn } from '../../lib/utils';
import React from 'react';

const sizeClassNames = [
  [],
  ['h-2.5', 'w-2.5'],
  ['h-3', 'w-3'],
  ['h-3.5', 'w-3.5'],
  ['h-4', 'w-4'],
  ['h-5', 'w-5'],
  ['h-6', 'w-6'],
  ['h-7', 'w-7'],
  ['h-8', 'w-8'],
];

export type IconProps = {
  className?: string;
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  children?: React.ReactNode;
};

export function Spinner({ size = 6 }: IconProps) {
  return (
    <span>
      <div
        className={cn(
          'inline-block animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"',
          ...(sizeClassNames[size] || [])
        )}
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]" />
      </div>
    </span>
  );
}
