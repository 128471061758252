export const TerminalIcon = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.97 5.92003C14.9028 5.67396 14.741 5.46443 14.52 5.33706C14.299 5.2097 14.0366 5.17482 13.79 5.24003L10.39 6.15003L5.95 2.03003L4.72 2.36003L7.38 6.95003L4.19 7.80003L2.93 6.82003L2 7.07003L3.66 9.95003L14.28 7.11003C14.8 6.96003 15.1 6.43003 14.97 5.92003ZM21 10L20 12H15L14 10L15 9.00003H17V7.00003H18V9.00003H20L21 10ZM22 20V22H2V20H15V13H20V20H22Z"
      fill="currentColor"
    />
  </svg>
);
