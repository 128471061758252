'use client';

import * as React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { cn } from '../../../lib/utils';

const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;

interface PopoverContentProps
  extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> {
  shadow?: boolean;
  variant?: 'default' | 'compact';
  title?: string;
  rounded?: 'sm' | 'md' | 'lg' | 'xl';
  side?: 'left' | 'bottom';
  position?: 'departure' | 'arrival';
}

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  PopoverContentProps
>(
  (
    {
      className,
      align = 'start',
      side = 'bottom',
      shadow = true,
      variant = 'default',
      title,
      rounded = 'md',
      position = '',
      ...props
    },
    ref
  ) => {
    // Dynamically determine alignment and offsets based on props
    const [dynamicSide, setDynamicSide] = React.useState<
      'top' | 'right' | 'bottom' | 'left'
    >(side);

    React.useEffect(() => {
      if (side === 'left') {
        const updateSide = () => {
          if (window.innerWidth <= 1024) {
            setDynamicSide('bottom');
          } else {
            setDynamicSide('left');
          }
        };
        updateSide();
        window.addEventListener('resize', updateSide);
        return () => window.removeEventListener('resize', updateSide);
      }
    }, [side]);

    // Customize offsets and alignment for departure and arrival
    const sideOffset = (() => {
      if (position === 'departure') {
        return dynamicSide === 'left' ? 50 : 15; // Adjust for departure
      }
      if (position === 'arrival') {
        return dynamicSide === 'left' ? 50 : 15; // Adjust for arrival
      }
      return dynamicSide === 'left' ? 50 : 8; // Default
    })();

    const alignOffset = (() => {
      if (position === 'departure') {
        return dynamicSide === 'left' ? -110 : 0; // Adjust for departure
      }
      if (position === 'arrival') {
        return dynamicSide === 'left' ? -181 : 0; // Adjust for arrival
      }
      return dynamicSide === 'left' ? -35 : 0; // Default
    })();

    const variantClass =
      variant === 'compact' ? 'p-6 w-full mt-3' : 'w-full p-0 mt-3';

    const roundedClass = {
      sm: 'rounded-sm',
      md: 'rounded-md',
      lg: 'rounded-xl',
      xl: 'rounded-3xl',
    }[rounded];

    return (
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          ref={ref}
          align={align}
          side={dynamicSide}
          sideOffset={sideOffset}
          alignOffset={alignOffset}
          className={cn(
            'z-40 border bg-popover p-4 text-popover-foreground outline-none',
            shadow ? 'shadow-[0_8px_30px_rgb(0,0,0,0.12)]' : '',
            roundedClass,
            variantClass,
            'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
            className
          )}
          {...props}
        >
          {title && (
            <div className="mb-4 text-2xl font-normal text-black">{title}</div>
          )}
          {props.children}
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    );
  }
);
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverTrigger, PopoverContent };
