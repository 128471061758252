export const France = (props: Omit<React.ComponentProps<'svg'>, 'children'>) => (
  <svg
    {...props}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.2 4.80005H21.6V27.2H11.2V4.80005Z" fill="#E6E7E8" />
    <path
      d="M5.09091 4.80005C1.71716 4.80005 0 7.30732 0 10.4V21.6C0 24.6928 1.71716 27.2 5.09091 27.2H11.2V4.80005H5.09091Z"
      fill="#1B75BB"
    />
    <path
      d="M25.8909 4.80005H20.8V27.2H26.9091C30.2828 27.2 32 24.6928 32 21.6V10.4C32 7.30681 31.9786 4.80005 25.8909 4.80005Z"
      fill="#EC1C24"
    />
  </svg>
);
