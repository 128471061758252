export const CurvedBackground = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    preserveAspectRatio="none"
    viewBox="0 0 1440 519"
    fill="none"
  >
    <path
      d="M-531 -79.3667C-531 -79.3667 -274.284 377.144 645.722 377.144C1565.73 377.144 1971 867.954 1971 867.954V-81H-531V-79.3667Z"
      fill="#0283D1"
    />
  </svg>
);
