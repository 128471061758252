export const CaravanIcon = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M16.875 11.2219H15.3562V7.53755C15.3562 7.39692 15.3281 7.2563 15.2437 7.11567L13.2469 3.90942C13.0219 3.57192 12.6562 3.34692 12.2344 3.34692H2.22185C1.2656 3.34692 0.506226 4.1063 0.506226 5.06255V10.7719C0.506226 11.6157 1.12498 12.3188 1.9406 12.4594V12.5719C1.9406 13.6969 2.86873 14.625 3.99373 14.625C5.11873 14.625 6.04685 13.6969 6.04685 12.5719V12.4875H16.875C17.2125 12.4875 17.5219 12.2063 17.5219 11.8407C17.5219 11.475 17.2125 11.2219 16.875 11.2219ZM3.99373 13.3875C3.54373 13.3875 3.20623 13.0219 3.20623 12.6C3.20623 12.15 3.57185 11.8125 3.99373 11.8125C4.44373 11.8125 4.78123 12.1782 4.78123 12.6C4.78123 13.0219 4.4156 13.3875 3.99373 13.3875ZM3.99373 10.5188C3.37498 10.5188 2.81248 10.8 2.44685 11.25H2.22185C1.96873 11.25 1.77185 11.0532 1.77185 10.8V5.06255C1.77185 4.80942 1.96873 4.61255 2.22185 4.61255H12.2062L14.0906 7.67817V11.2219H11.7844V7.1438C11.7844 6.63755 11.3625 6.21567 10.8562 6.21567H9.28123C8.77498 6.21567 8.3531 6.63755 8.3531 7.1438V11.25H5.5406C5.17498 10.8 4.61248 10.5188 3.99373 10.5188ZM9.64685 11.2219V7.4813H10.5187V11.2219H9.64685Z"
      fill="currentColor"
    />
  </svg>
);
