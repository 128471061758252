import React from 'react';
import getSpacingClassNames, {
  SpacingPropsType,
} from '../helpers/getSpacingClassNames';
import stripSpacingProps from '../helpers/stripSpacingProps';
import { cn } from '../../lib/utils';

export type ContainerPropsType = React.HTMLAttributes<HTMLDivElement> & {
  isFlex?: boolean;
  fullWidth?: boolean;
  hasNoPadding?: boolean;
} & Omit<SpacingPropsType, 'margin' | 'marginX' | 'marginLeft' | 'marginRight'>;

export const Container = ({
  isFlex,
  className,
  fullWidth = false,
  hasNoPadding = false,
  ...otherProps
}: ContainerPropsType) => {
  const spacingClassNames = getSpacingClassNames(otherProps);
  const htmlElementProps = stripSpacingProps(otherProps);

  return (
    <div
      className={cn(
        'container mx-auto px-4',
        {
          flex: isFlex,
          'p-0': hasNoPadding,
        },
        spacingClassNames,
        className
      )}
      {...htmlElementProps}
    />
  );
};
