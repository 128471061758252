import { UrqlProvider, createClient, fetchExchange, ssrExchange } from '@urql/next';
import { relayPagination } from '@urql/exchange-graphcache/extras';
import { devtoolsExchange } from '@urql/devtools';
import { cacheExchange } from '@urql/exchange-graphcache';
import { useMemo } from 'react';
import { environment } from '../../environment';
import getProduction from './utils/get-production';
import getIsServer from './utils/get-is-server';
import { useAuth } from './auth-provider';
import { getItemFromCookies } from '../helpers/cookies-helper';

export default function Provider({ children }: React.PropsWithChildren) {
  const { accessToken } = useAuth();
  const [client, ssr] = useMemo(() => {
    const isServerSide = getIsServer();
    const ssr = ssrExchange({ isClient: !isServerSide });
    const isProduction = getProduction();

    const cache = cacheExchange({
      resolvers: {
        Query: {
          getAllSites: relayPagination(), // Automatically handles merging paginated results
        },
      },
      keys: {
        // eslint-disable-next-line unicorn/no-null
        AirportPaginatedResponse: () => null,
        // eslint-disable-next-line unicorn/no-null
        SitePaginatedResponse: () => null,
        Site: data => `${data.id}`,
        Airport: data => `${data.id}`,
        Location: data => `${data.id}`,
        Country: data => `${data.id}`,
      },
      updates: {},
      optimistic: {},
      ttl: 60 * 60 * 1000,
    });

    const client = createClient({
      url: environment.API_ENDPOINT,
      exchanges: [
        ...(!isServerSide && !isProduction ? [devtoolsExchange] : []),
        cache,
        ssr,
        fetchExchange,
      ],
      fetchOptions: () => {
        const token = accessToken || getItemFromCookies('accessToken');
        const userJson = getItemFromCookies('user');
        const user = userJson ? JSON.parse(userJson) : undefined;

        const headers: Record<string, string> = {
          Authorisation: token ? `${token}` : '',
          'X-ROOSH-HOST': 'Site',
        };

        if (user && user.roles && user.roles[0]?.name) {
          headers['X-ROOSH-SCOPE'] = user.roles[0]?.name;
        }

        return {
          headers,
        };
      },
      suspense: true,
    });

    return [client, ssr];
  }, [accessToken]);

  return (
    <UrqlProvider client={client} ssr={ssr}>
      {children}
    </UrqlProvider>
  );
}
