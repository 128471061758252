import { Icon, IconColor, IconSize, IconName } from '@icons/icons';
import { cn } from '../../../lib/utils';
import * as React from 'react';

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'inputSize'> {
  startIcon?: IconName;
  endIcon?: IconName;
  iconColor?: IconColor;
  iconSize?: keyof typeof IconSize;
  variant?: 'outlined' | 'filled';
  roundedFull?: boolean;
  color?: string;
  inputSize?: 'default' | 'medium' | 'large';
  ring?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      startIcon,
      endIcon,
      iconColor = 'inherit',
      iconSize = 8,
      variant = 'outlined',
      roundedFull = false,
      color,
      inputSize = 'default',
      ring = true,
      ...props
    },
    ref
  ) => {
    const sizeClasses = {
      default: 'py-2 text-sm px-8',
      medium: 'py-4 text-base px-12',
      large: 'py-6 text-lg px-14',
    };

    return (
      <div className="w-full relative">
        {startIcon && (
          <div className="absolute left-3.5 top-1/2 transform -translate-y-1/2">
            <Icon name={startIcon} size={iconSize} iconColor={iconColor} />
          </div>
        )}
        <input
          type={type}
          className={cn(
            'flex w-full border ring-offset-background file:border-0 file:bg-transparent file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
            ring ? 'focus-visible:ring-1 focus-visible:ring-ring' : '',
            variant === 'filled'
              ? 'bg-inputFieldDefaultBackground border-none'
              : 'border-input bg-background',
            roundedFull ? 'rounded-full' : 'rounded-md',
            color ? color : '',
            startIcon,
            endIcon,
            sizeClasses[inputSize],
            className
          )}
          ref={ref}
          {...props}
        />
        {endIcon && (
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
            <Icon name={endIcon} size={iconSize} iconColor={iconColor} />
          </div>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

export { Input };
