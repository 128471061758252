import { cn } from '@ui//lib';

export type SpacingPropsType = {
  margin?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  marginX?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  marginY?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  marginTop?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  marginRight?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  marginBottom?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  marginLeft?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
};

const classNamesMap = {
  margin: ['m-0', 'm-1', 'm-2', 'm-3', 'm-4', 'm-5', 'm-6', 'm-7', 'm-8'],
  marginX: ['mx-0', 'mx-1', 'mx-2', 'mx-3', 'mx-4', 'mx-5', 'mx-6', 'mx-7', 'mx-8'],
  marginY: ['my-0', 'my-1', 'my-2', 'my-3', 'my-4', 'my-5', 'my-6', 'my-7', 'my-8'],
  marginTop: [
    'mt-0',
    'mt-1',
    'mt-2',
    'mt-3',
    'mt-4',
    'mt-5',
    'mt-6',
    'mt-7',
    'mt-8',
  ],
  marginRight: [
    'mr-0',
    'mr-1',
    'mr-2',
    'mr-3',
    'mr-4',
    'mr-5',
    'mr-6',
    'mr-7',
    'mr-8',
  ],
  marginBottom: [
    'mb-0',
    'mb-1',
    'mb-2',
    'mb-3',
    'mb-4',
    'mb-5',
    'mb-6',
    'mb-7',
    'mb-8',
  ],
  marginLeft: [
    'ml-0',
    'ml-1',
    'ml-2',
    'ml-3',
    'ml-4',
    'ml-5',
    'ml-6',
    'ml-7',
    'ml-8',
  ],
};

function getSpacingClassNames({
  margin,
  marginX,
  marginY,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
}: SpacingPropsType = {}) {
  return cn(
    typeof margin === 'number' && classNamesMap.margin[margin],
    typeof marginX === 'number' && classNamesMap.marginX[marginX],
    typeof marginY === 'number' && classNamesMap.marginY[marginY],
    typeof marginTop === 'number' && classNamesMap.marginTop[marginTop],
    typeof marginRight === 'number' && classNamesMap.marginRight[marginRight],
    typeof marginBottom === 'number' && classNamesMap.marginBottom[marginBottom],
    typeof marginLeft === 'number' && classNamesMap.marginLeft[marginLeft]
  );
}

export default getSpacingClassNames;
