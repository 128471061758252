'use client';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetClose,
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from '@ui/components';
import { Icon } from '@icons/icons';
import Button from '@ui/components/ui/button/Button';
import { useEffect, useState } from 'react';
import {
  ParkingItem,
  NavigationItem,
  HelpItem,
} from './navigation-header/navigation-header';
import {
  DropdownItem,
  ModalDropdownCountry,
} from '@components/modal-country/modal-dropdown-country';
import { AirportFieldsFragment } from '../../../graphql/generated/graphql';
import { setCookie } from 'cookies-next';
import { useTranslations } from 'next-intl';

interface HeaderMobileProps {
  navigationLinks: Array<NavigationItem>;
  countries: Array<DropdownItem>;
  currencies: Array<DropdownItem>;
  languages: Array<DropdownItem>;
}

export function HeaderMobile({
  navigationLinks,
  countries,
  currencies,
  languages,
}: HeaderMobileProps) {
  // State to manage views
  const [currentView, setCurrentView] = useState<
    'main' | 'airport' | 'parking' | 'country'
  >('main');
  const [selectedCountry, setSelectedCountry] = useState<DropdownItem | null>();
  const [selectedCurrency, setSelectedCurrency] = useState<DropdownItem | null>();
  const [selectedLanguage, setSelectedLanguage] = useState<DropdownItem | null>();
  const t = useTranslations('header');
  useEffect(() => {
    if (!selectedCountry && countries.length > 0) {
      setSelectedCountry(
        countries.find(country => country.isDefault) || countries[0]
      );
    }
    if (!selectedCurrency && currencies.length > 0) {
      setSelectedCurrency(
        currencies.find(currency => currency.isDefault) || currencies[0]
      );
    }
    if (!selectedLanguage && languages.length > 0) {
      setSelectedLanguage(
        languages.find(language => language.isDefault) || languages[0]
      );
    }
  }, [
    countries,
    currencies,
    languages,
    selectedCountry,
    selectedCurrency,
    selectedLanguage,
  ]);
  const handleContinue = () => {
    if (selectedLanguage) {
      setCookie('lang', selectedLanguage.tag, {
        path: '/',
        sameSite: 'lax',
      });
    }

    // Reload the page to apply the new locale
    location.reload();
  };

  return (
    <Sheet>
      <SheetTrigger asChild>
        <div className="flex items-center gap-6 lg:hidden cursor-pointer">
          <Icon name="MenuOutlinedIcon" iconColor="light" />
        </div>
      </SheetTrigger>

      <SheetContent>
        <SheetHeader>
          <div className="flex items-center justify-between py-4">
            <SheetTitle>
              <Icon name="VnpLogoBlack" size={14} />
            </SheetTitle>
            <SheetClose>
              <Icon name="CloseIcon" size={8} iconColor="blueGrey" />
            </SheetClose>
          </div>
        </SheetHeader>

        {/* Render content based on the current view */}
        {currentView === 'main' && (
          <div>
            {/* Main View */}
            <div className="mt-6 space-y-3 text-primaryText">
              {navigationLinks.map(nav => (
                <div
                  key={nav.label}
                  className={`flex flex-col py-2 ${
                    nav.label === t('help') ? 'space-y-3' : 'cursor-pointer'
                  }`}
                  onClick={() => {
                    if ('items' in nav) {
                      if (nav.label === t('airports')) setCurrentView('airport');
                      else if (nav.label === t('type_of_parking'))
                        setCurrentView('parking');
                    }
                  }}
                >
                  {/* Check if it's the help section */}
                  {nav.label === t('help') && 'items' in nav ? (
                    <div className="space-y-8">
                      {(nav.items as Array<HelpItem>).map(helpItem => (
                        <a
                          key={helpItem.label}
                          href={helpItem.link}
                          className="font-normal text-lg text-primaryText block"
                        >
                          {helpItem.label}
                        </a>
                      ))}
                    </div>
                  ) : (
                    <div className="flex justify-between items-center">
                      <span className="font-normal text-lg">{nav.label}</span>
                      {'items' in nav && (
                        <Icon
                          name="KeyboardArrowRightRoundedIcon"
                          iconColor="default"
                          size={8}
                        />
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="mt-6 border-t py-4">
              <div
                className="flex items-center justify-between cursor-pointer"
                onClick={() => setCurrentView('country')}
              >
                <div className="flex items-center gap-2">
                  <Icon name="LanguageOutlinedIcon" size={8} iconColor="default" />
                  <span className="font-normal text-lg">
                    {t('choose_your_preferences')}
                  </span>
                </div>
                <Icon
                  name="KeyboardArrowRightRoundedIcon"
                  iconColor="default"
                  size={8}
                />
              </div>
            </div>

            <div className="mt-6">
              <Button
                leftIcon="AccountIcon"
                iconSize={6}
                fullWidth
                isCenter
                variant="primary"
                iconColor="inherit"
                size="input"
              >
                {t('my_booking')}
              </Button>
            </div>
          </div>
        )}

        {currentView === 'airport' && (
          <>
            <div
              className="flex rounded-xl my-5 items-center gap-2 p-3 cursor-pointer text-primaryText bg-gray-100"
              onClick={() => setCurrentView('main')}
            >
              <Icon
                name="KeyboardArrowLeftRoundedIcon"
                size={7}
                iconColor="default"
              />
              <span className="text-lg font-medium">Return</span>
            </div>
            <Accordion type="single" collapsible>
              {(() => {
                const groupedAirports: Record<
                  string,
                  Array<AirportFieldsFragment>
                > = {};
                const airports = navigationLinks
                  .filter(
                    (
                      nav
                    ): nav is {
                      label: 'Airports';
                      items: Array<AirportFieldsFragment>;
                    } => nav.label === t('airports') && 'items' in nav
                  )
                  .flatMap(nav => nav.items);

                for (const airport of airports) {
                  const countryName =
                    airport.location?.country?.name || 'Unknown Country';
                  if (!groupedAirports[countryName]) {
                    groupedAirports[countryName] = [];
                  }
                  groupedAirports[countryName].push(airport);
                }

                // Convert grouped object to an array and sort by country name
                const sortedCountries = Object.entries(groupedAirports).sort(
                  ([a], [b]) => a.localeCompare(b)
                );

                return sortedCountries.map(([countryName, airports], index) => (
                  <AccordionItem key={`country-${index}`} value={`country-${index}`}>
                    <AccordionTrigger>
                      <div className="flex items-center gap-2 text-lg">
                        {/* TODO: change with the icons from util service */}
                        {/* <Icon name={countryName as IconName} size={6} />{' '} */}
                        {countryName}
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      {airports.map(airport => (
                        <a
                          key={airport.id}
                          href={`#airport-${airport.id}`}
                          className="text-base py-2 block hover:underline text-primaryText"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {airport.name || 'Unnamed Airport'}
                        </a>
                      ))}
                    </AccordionContent>
                  </AccordionItem>
                ));
              })()}
            </Accordion>
          </>
        )}

        {/* Parking Types View */}
        {currentView === 'parking' && (
          <>
            <div
              className="flex rounded-xl my-5 items-center gap-2 p-3 cursor-pointer text-primaryText bg-gray-100"
              onClick={() => setCurrentView('main')}
            >
              <Icon
                name="KeyboardArrowLeftRoundedIcon"
                size={7}
                iconColor="default"
              />
              <span className="text-lg font-medium">Return</span>
            </div>
            <div className="mt-4">
              {navigationLinks
                .filter(
                  (
                    nav
                  ): nav is {
                    label: 'Type of Parking';
                    items: Array<ParkingItem>;
                  } => nav.label === t('type_of_parking') && 'items' in nav
                )
                .flatMap(nav => nav.items)
                .map((type, index) => (
                  <a
                    key={index}
                    href={type.link} // Use the link from the ParkingItem
                    className="flex items-center gap-3 py-3 text-primaryText hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon name={type.icon} size={8} iconColor="default" />
                    <span className="text-lg">{type.label}</span>
                  </a>
                ))}
            </div>
          </>
        )}

        {/* Parking Types View */}
        {currentView === 'country' && (
          <>
            <div
              className="flex rounded-xl my-5 items-center gap-2 p-3 cursor-pointer text-primaryText bg-gray-100"
              onClick={() => setCurrentView('main')}
            >
              <Icon
                name="KeyboardArrowLeftRoundedIcon"
                size={7}
                iconColor="default"
              />
              <span className="text-lg font-medium">Return</span>
            </div>

            <div className="mt-4 space-y-6">
              <div className="grid flex-1 gap-2">
                <div className="bg-subtleWarning text-primaryWarning text-sm p-5 rounded-lg flex items-center">
                  <span className="mr-2">
                    <Icon name="WarningIcon" />
                  </span>
                  <p className="text-sm font-regular">
                    Be aware that by changing the country you will get different
                    airport choices for parking.
                  </p>
                </div>
              </div>
              {/* Country Selection */}
              <ModalDropdownCountry
                title="Country"
                selectedItem={selectedCountry!}
                items={countries}
                onItemSelect={setSelectedCountry}
              />

              {/* Currency Selection */}
              <ModalDropdownCountry
                title="Currency"
                selectedItem={selectedCurrency!}
                items={currencies}
                onItemSelect={setSelectedCurrency}
                isDisabled={currencies.length === 0}
              />

              {/* Language Selection */}
              <ModalDropdownCountry
                title="Language"
                selectedItem={selectedLanguage!}
                items={languages}
                onItemSelect={setSelectedLanguage}
              />
            </div>

            <div className="mt-6 flex justify-center">
              <Button
                size="input"
                fullWidth
                radius="fullRounded"
                variant="primary"
                onClick={handleContinue}
              >
                Save Preferences
              </Button>
            </div>
          </>
        )}
      </SheetContent>
    </Sheet>
  );
}
