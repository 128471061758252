'use client';
import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import getSpacingClassNames, {
  SpacingPropsType,
} from '../helpers/getSpacingClassNames';
import stripSpacingProps from '../helpers/stripSpacingProps';
import { cn } from '../../lib/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border-2 border-input hover:border-primary hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        ghostNH: 'hover:none',
        link: 'text-primary underline-offset-4 hover:underline',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-7 rounded-md px-4',
        lg: 'h-11 rounded-md px-8',
        xl: 'py-4 px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    SpacingPropsType,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}
const Button = React.forwardRef<
  HTMLButtonElement,
  ButtonProps &
    Omit<
      React.HTMLAttributes<HTMLAnchorElement | HTMLButtonElement>,
      'className' | 'css'
    >
>(({ className, variant, size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : 'button';
  const spacingClassNames = getSpacingClassNames(props);
  const htmlElementProps = stripSpacingProps(props);
  return (
    <Comp
      {...htmlElementProps}
      className={cn(spacingClassNames, buttonVariants({ variant, size, className }))}
      ref={ref}
    />
  );
});
Button.displayName = 'Button';

export { Button, buttonVariants };
