import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  Decimal: { input: any; output: any };
  JSON: { input: any; output: any };
};

export type AcceptConsentPayload = {
  __typename?: 'AcceptConsentPayload';
  redirect_to: Scalars['String']['output'];
};

export type Airport = {
  __typename?: 'Airport';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  iata: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  name: Scalars['String']['output'];
  sites?: Maybe<Array<Site>>;
  terminals?: Maybe<Array<Terminal>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type AirportCreateInput = {
  iata: Scalars['String']['input'];
  locationIdConnect?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  siteIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  terminalIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AirportListRelationFilter = {
  every?: InputMaybe<AirportWhereInput>;
  none?: InputMaybe<AirportWhereInput>;
  some?: InputMaybe<AirportWhereInput>;
};

export type AirportOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  iata?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationOrderByInput>;
  locationId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AirportPaginatedResponse = {
  __typename?: 'AirportPaginatedResponse';
  items: Array<Maybe<Airport>>;
  pageInfo: PageInfo;
};

export type AirportUpdateInput = {
  iata?: InputMaybe<Scalars['String']['input']>;
  locationIdConnect?: InputMaybe<Scalars['ID']['input']>;
  locationIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  siteIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  terminalIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  terminalIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  terminalIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AirportWhereInput = {
  AND?: InputMaybe<Array<AirportWhereInput>>;
  NOT?: InputMaybe<Array<AirportWhereInput>>;
  OR?: InputMaybe<Array<AirportWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  iata?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  location?: InputMaybe<LocationWhereInput>;
  locationId?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  sites?: InputMaybe<SiteListRelationFilter>;
  terminals?: InputMaybe<TerminalListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type AirportWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
};

export type BooleanListFilterInput = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  has?: InputMaybe<Scalars['Boolean']['input']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BusinessEntity = {
  __typename?: 'BusinessEntity';
  bankAccount: Scalars['String']['output'];
  bankCountry: Scalars['String']['output'];
  businessStructure: Scalars['String']['output'];
  businessType: Scalars['String']['output'];
  cocNumber: Scalars['String']['output'];
  commission: Scalars['Int']['output'];
  createdAt: Scalars['Date']['output'];
  currency: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  documents?: Maybe<Array<Document>>;
  email: Scalars['String']['output'];
  hipayAccountId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  insuranceAvailable: Scalars['Boolean']['output'];
  invoices?: Maybe<Array<Invoice>>;
  language?: Maybe<Language>;
  legalEntitySolo: Scalars['Boolean']['output'];
  legalEntityType: Scalars['String']['output'];
  legalName: Scalars['String']['output'];
  location?: Maybe<Location>;
  logo?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['String']['output']>;
  paymentProvider?: Maybe<Scalars['String']['output']>;
  paymentProviderId?: Maybe<Scalars['String']['output']>;
  paymentProviderSecretKey?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  priceStrategyId?: Maybe<Scalars['String']['output']>;
  providers?: Maybe<Array<Provider>>;
  rooshAdminId?: Maybe<Scalars['String']['output']>;
  swiftBIC: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  vatNumber: Scalars['String']['output'];
};

export type BusinessEntityCreateInput = {
  bankAccount: Scalars['String']['input'];
  bankCountry: Scalars['String']['input'];
  businessStructure: Scalars['String']['input'];
  businessType: Scalars['String']['input'];
  cocNumber: Scalars['String']['input'];
  commission: Scalars['Int']['input'];
  currency: Scalars['String']['input'];
  documentIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  email: Scalars['String']['input'];
  hipayAccountId?: InputMaybe<Scalars['String']['input']>;
  insuranceAvailable: Scalars['Boolean']['input'];
  invoiceIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  languageIdConnect: Scalars['ID']['input'];
  legalEntitySolo: Scalars['Boolean']['input'];
  legalEntityType: Scalars['String']['input'];
  legalName: Scalars['String']['input'];
  locationIdConnect: Scalars['ID']['input'];
  logo?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  paymentProvider?: InputMaybe<Scalars['String']['input']>;
  paymentProviderId?: InputMaybe<Scalars['String']['input']>;
  paymentProviderSecretKey?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  priceStrategyId?: InputMaybe<Scalars['String']['input']>;
  providerIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  rooshAdminId?: InputMaybe<Scalars['String']['input']>;
  swiftBIC: Scalars['String']['input'];
  vatNumber: Scalars['String']['input'];
};

export type BusinessEntityListRelationFilter = {
  every?: InputMaybe<BusinessEntityWhereInput>;
  none?: InputMaybe<BusinessEntityWhereInput>;
  some?: InputMaybe<BusinessEntityWhereInput>;
};

export type BusinessEntityOrderByInput = {
  bankAccount?: InputMaybe<SortOrder>;
  bankCountry?: InputMaybe<SortOrder>;
  businessStructure?: InputMaybe<SortOrder>;
  businessType?: InputMaybe<SortOrder>;
  cocNumber?: InputMaybe<SortOrder>;
  commission?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  hipayAccountId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  insuranceAvailable?: InputMaybe<SortOrder>;
  language?: InputMaybe<LanguageOrderByInput>;
  languageId?: InputMaybe<SortOrder>;
  legalEntitySolo?: InputMaybe<SortOrder>;
  legalEntityType?: InputMaybe<SortOrder>;
  legalName?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationOrderByInput>;
  locationId?: InputMaybe<SortOrder>;
  logo?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  paymentProvider?: InputMaybe<SortOrder>;
  paymentProviderId?: InputMaybe<SortOrder>;
  paymentProviderSecretKey?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  priceStrategyId?: InputMaybe<SortOrder>;
  rooshAdminId?: InputMaybe<SortOrder>;
  swiftBIC?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  vatNumber?: InputMaybe<SortOrder>;
};

export type BusinessEntityPaginatedResponse = {
  __typename?: 'BusinessEntityPaginatedResponse';
  items: Array<Maybe<BusinessEntity>>;
  pageInfo: PageInfo;
};

export type BusinessEntityUpdateInput = {
  bankAccount?: InputMaybe<Scalars['String']['input']>;
  bankCountry?: InputMaybe<Scalars['String']['input']>;
  businessStructure?: InputMaybe<Scalars['String']['input']>;
  businessType?: InputMaybe<Scalars['String']['input']>;
  cocNumber?: InputMaybe<Scalars['String']['input']>;
  commission?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  documentIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  documentIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  documentIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  email?: InputMaybe<Scalars['String']['input']>;
  hipayAccountId?: InputMaybe<Scalars['String']['input']>;
  insuranceAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  invoiceIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  invoiceIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  invoiceIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  languageIdConnect?: InputMaybe<Scalars['ID']['input']>;
  legalEntitySolo?: InputMaybe<Scalars['Boolean']['input']>;
  legalEntityType?: InputMaybe<Scalars['String']['input']>;
  legalName?: InputMaybe<Scalars['String']['input']>;
  locationIdConnect?: InputMaybe<Scalars['ID']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  paymentProvider?: InputMaybe<Scalars['String']['input']>;
  paymentProviderId?: InputMaybe<Scalars['String']['input']>;
  paymentProviderSecretKey?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  priceStrategyId?: InputMaybe<Scalars['String']['input']>;
  providerIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  providerIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  providerIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  rooshAdminId?: InputMaybe<Scalars['String']['input']>;
  swiftBIC?: InputMaybe<Scalars['String']['input']>;
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};

export type BusinessEntityWhereInput = {
  AND?: InputMaybe<Array<BusinessEntityWhereInput>>;
  NOT?: InputMaybe<Array<BusinessEntityWhereInput>>;
  OR?: InputMaybe<Array<BusinessEntityWhereInput>>;
  bankAccount?: InputMaybe<StringFilterInput>;
  bankCountry?: InputMaybe<StringFilterInput>;
  businessStructure?: InputMaybe<StringFilterInput>;
  businessType?: InputMaybe<StringFilterInput>;
  cocNumber?: InputMaybe<StringFilterInput>;
  commission?: InputMaybe<IntFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  currency?: InputMaybe<StringFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  documents?: InputMaybe<DocumentListRelationFilter>;
  email?: InputMaybe<StringFilterInput>;
  hipayAccountId?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  insuranceAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  invoices?: InputMaybe<InvoiceListRelationFilter>;
  language?: InputMaybe<LanguageWhereInput>;
  languageId?: InputMaybe<StringFilterInput>;
  legalEntitySolo?: InputMaybe<Scalars['Boolean']['input']>;
  legalEntityType?: InputMaybe<StringFilterInput>;
  legalName?: InputMaybe<StringFilterInput>;
  location?: InputMaybe<LocationWhereInput>;
  locationId?: InputMaybe<StringFilterInput>;
  logo?: InputMaybe<StringFilterInput>;
  ownerId?: InputMaybe<StringFilterInput>;
  paymentProvider?: InputMaybe<StringFilterInput>;
  paymentProviderId?: InputMaybe<StringFilterInput>;
  paymentProviderSecretKey?: InputMaybe<StringFilterInput>;
  phone?: InputMaybe<StringFilterInput>;
  priceStrategyId?: InputMaybe<StringFilterInput>;
  providers?: InputMaybe<ProviderListRelationFilter>;
  rooshAdminId?: InputMaybe<StringFilterInput>;
  swiftBIC?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
  vatNumber?: InputMaybe<StringFilterInput>;
};

export type BusinessEntityWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ConsentInput = {
  consentChallenge: Scalars['String']['input'];
};

export type ContactPoint = {
  __typename?: 'ContactPoint';
  areaServed: Scalars['String']['output'];
  availableLanguage?: Maybe<Scalars['String']['output']>;
  contactType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  email: Scalars['String']['output'];
  faxNumber?: Maybe<Scalars['String']['output']>;
  hoursAvailable?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sites?: Maybe<Array<Site>>;
  telephone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ContactPointCreateInput = {
  areaServed: Scalars['String']['input'];
  availableLanguage?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  hoursAvailable?: InputMaybe<Scalars['String']['input']>;
  siteIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  telephone?: InputMaybe<Scalars['String']['input']>;
};

export type ContactPointListRelationFilter = {
  every?: InputMaybe<ContactPointWhereInput>;
  none?: InputMaybe<ContactPointWhereInput>;
  some?: InputMaybe<ContactPointWhereInput>;
};

export type ContactPointOrderByInput = {
  areaServed?: InputMaybe<SortOrder>;
  availableLanguage?: InputMaybe<SortOrder>;
  contactType?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  faxNumber?: InputMaybe<SortOrder>;
  hoursAvailable?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  telephone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ContactPointPaginatedResponse = {
  __typename?: 'ContactPointPaginatedResponse';
  items: Array<Maybe<ContactPoint>>;
  pageInfo: PageInfo;
};

export type ContactPointUpdateInput = {
  areaServed?: InputMaybe<Scalars['String']['input']>;
  availableLanguage?: InputMaybe<Scalars['String']['input']>;
  contactType?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  faxNumber?: InputMaybe<Scalars['String']['input']>;
  hoursAvailable?: InputMaybe<Scalars['String']['input']>;
  siteIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  telephone?: InputMaybe<Scalars['String']['input']>;
};

export type ContactPointWhereInput = {
  AND?: InputMaybe<Array<ContactPointWhereInput>>;
  NOT?: InputMaybe<Array<ContactPointWhereInput>>;
  OR?: InputMaybe<Array<ContactPointWhereInput>>;
  areaServed?: InputMaybe<StringFilterInput>;
  availableLanguage?: InputMaybe<StringFilterInput>;
  contactType?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  faxNumber?: InputMaybe<StringFilterInput>;
  hoursAvailable?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  sites?: InputMaybe<SiteListRelationFilter>;
  telephone?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ContactPointWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Country = {
  __typename?: 'Country';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  flagIconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isInsideEu: Scalars['Boolean']['output'];
  iso: Scalars['String']['output'];
  locations?: Maybe<Array<Location>>;
  name: Scalars['String']['output'];
  siteCountries?: Maybe<Array<CountrySiteCountry>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type CountryCreateInput = {
  flagIconUrl?: InputMaybe<Scalars['String']['input']>;
  isInsideEu: Scalars['Boolean']['input'];
  iso: Scalars['String']['input'];
  locationIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  name: Scalars['String']['input'];
};

export type CountryListRelationFilter = {
  every?: InputMaybe<CountryWhereInput>;
  none?: InputMaybe<CountryWhereInput>;
  some?: InputMaybe<CountryWhereInput>;
};

export type CountryOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  flagIconUrl?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isInsideEu?: InputMaybe<SortOrder>;
  iso?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CountryPaginatedResponse = {
  __typename?: 'CountryPaginatedResponse';
  items: Array<Maybe<Country>>;
  pageInfo: PageInfo;
};

export type CountrySiteCountry = {
  __typename?: 'CountrySiteCountry';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  site?: Maybe<Site>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type CountrySiteCountryCreateInput = {
  isDefault: Scalars['Boolean']['input'];
  siteId: Scalars['ID']['input'];
};

export type CountrySiteCountryDeleteInput = {
  siteId: Scalars['ID']['input'];
};

export type CountryUpdateInput = {
  flagIconUrl?: InputMaybe<Scalars['String']['input']>;
  isInsideEu?: InputMaybe<Scalars['Boolean']['input']>;
  iso?: InputMaybe<Scalars['String']['input']>;
  locationIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CountryWhereInput = {
  AND?: InputMaybe<Array<CountryWhereInput>>;
  NOT?: InputMaybe<Array<CountryWhereInput>>;
  OR?: InputMaybe<Array<CountryWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  flagIconUrl?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  isInsideEu?: InputMaybe<Scalars['Boolean']['input']>;
  iso?: InputMaybe<StringFilterInput>;
  locations?: InputMaybe<LocationListRelationFilter>;
  name?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type CountryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  iso?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Currency = {
  __typename?: 'Currency';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  iso: Scalars['String']['output'];
  name: Scalars['String']['output'];
  siteCurrencies?: Maybe<Array<CurrencySiteCurrency>>;
  symbol: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type CurrencyCreateInput = {
  iso: Scalars['String']['input'];
  name: Scalars['String']['input'];
  siteCurrenciesCreate?: InputMaybe<Array<CurrencySiteCurrencyCreateInput>>;
  symbol: Scalars['String']['input'];
};

export type CurrencyListRelationFilter = {
  every?: InputMaybe<CurrencyWhereInput>;
  none?: InputMaybe<CurrencyWhereInput>;
  some?: InputMaybe<CurrencyWhereInput>;
};

export type CurrencyOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iso?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  symbol?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CurrencyPaginatedResponse = {
  __typename?: 'CurrencyPaginatedResponse';
  items: Array<Maybe<Currency>>;
  pageInfo: PageInfo;
};

export type CurrencySiteCurrency = {
  __typename?: 'CurrencySiteCurrency';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  site?: Maybe<Site>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type CurrencySiteCurrencyCreateInput = {
  isDefault: Scalars['Boolean']['input'];
  siteId: Scalars['ID']['input'];
};

export type CurrencySiteCurrencyDeleteInput = {
  siteId: Scalars['ID']['input'];
};

export type CurrencyUpdateInput = {
  iso?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  siteCurrenciesDelete?: InputMaybe<Array<CurrencySiteCurrencyDeleteInput>>;
  siteCurrenciesReplace?: InputMaybe<Array<CurrencySiteCurrencyCreateInput>>;
  siteCurrenciesUpsert?: InputMaybe<Array<CurrencySiteCurrencyCreateInput>>;
  symbol?: InputMaybe<Scalars['String']['input']>;
};

export type CurrencyWhereInput = {
  AND?: InputMaybe<Array<CurrencyWhereInput>>;
  NOT?: InputMaybe<Array<CurrencyWhereInput>>;
  OR?: InputMaybe<Array<CurrencyWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  iso?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  siteCurrencies?: InputMaybe<SiteCurrencyListRelationFilter>;
  symbol?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type CurrencyWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  iso?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type DateFilterInput = {
  equals?: InputMaybe<Scalars['Date']['input']>;
  gt?: InputMaybe<Scalars['Date']['input']>;
  gte?: InputMaybe<Scalars['Date']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  lt?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
  not?: InputMaybe<DateFilterInput>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
};

export type DateListFilterInput = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  has?: InputMaybe<Scalars['Date']['input']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DecimalFilterInput = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<DecimalFilterInput>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
};

export type DecimalListFilterInput = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  has?: InputMaybe<Scalars['Decimal']['input']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum DirectiveRole {
  CountryManager = 'CountryManager',
  Customer = 'Customer',
  CustomerSupport = 'CustomerSupport',
  Development = 'Development',
  ExternalVendor = 'ExternalVendor',
  Hr = 'HR',
  Management = 'Management',
  Marketing = 'Marketing',
  Pms = 'PMS',
  Provider = 'Provider',
  Resellers = 'Resellers',
  Sale = 'Sale',
  Subdomains = 'Subdomains',
}

export type Document = {
  __typename?: 'Document';
  businessEntity?: Maybe<BusinessEntity>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description: Scalars['String']['output'];
  fileId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type DocumentCreateInput = {
  businessEntityIdConnect?: InputMaybe<Scalars['ID']['input']>;
  description: Scalars['String']['input'];
  fileId: Scalars['String']['input'];
  type: Scalars['Int']['input'];
};

export type DocumentListRelationFilter = {
  every?: InputMaybe<DocumentWhereInput>;
  none?: InputMaybe<DocumentWhereInput>;
  some?: InputMaybe<DocumentWhereInput>;
};

export type DocumentOrderByInput = {
  businessEntity?: InputMaybe<BusinessEntityOrderByInput>;
  businessEntityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  fileId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DocumentPaginatedResponse = {
  __typename?: 'DocumentPaginatedResponse';
  items: Array<Maybe<Document>>;
  pageInfo: PageInfo;
};

export type DocumentUpdateInput = {
  businessEntityIdConnect?: InputMaybe<Scalars['ID']['input']>;
  businessEntityIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fileId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type DocumentWhereInput = {
  AND?: InputMaybe<Array<DocumentWhereInput>>;
  NOT?: InputMaybe<Array<DocumentWhereInput>>;
  OR?: InputMaybe<Array<DocumentWhereInput>>;
  businessEntity?: InputMaybe<BusinessEntityWhereInput>;
  businessEntityId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  fileId?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type DocumentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ExchangeTokenInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type ExchangeTokenPayload = {
  __typename?: 'ExchangeTokenPayload';
  accessToken?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  user: SafeUser;
};

export type FetchConsentPayload = {
  __typename?: 'FetchConsentPayload';
  requestedScopes: Array<Scalars['String']['output']>;
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  private: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type FileCreateInput = {
  name: Scalars['String']['input'];
  private: Scalars['Boolean']['input'];
};

export type FilePaginatedResponse = {
  __typename?: 'FilePaginatedResponse';
  items: Array<Maybe<File>>;
  pageInfo: PageInfo;
};

export type FileUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  private?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FileWhereInput = {
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  deletedAt?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  private?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
};

export type FileWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type FloatFilterInput = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<FloatFilterInput>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type FloatListFilterInput = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  has?: InputMaybe<Scalars['Float']['input']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IntFilterInput = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<IntFilterInput>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type IntListFilterInput = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  has?: InputMaybe<Scalars['Int']['input']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  businessEntity?: Maybe<BusinessEntity>;
  commissionAmount: Scalars['Float']['output'];
  commissionPercentage: Scalars['Int']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  fileId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invoiceNumber: Scalars['String']['output'];
  mode: Scalars['String']['output'];
  receiverEmail: Scalars['String']['output'];
  totalAmount: Scalars['Float']['output'];
  transferAmount: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type InvoiceCreateInput = {
  businessEntityIdConnect?: InputMaybe<Scalars['ID']['input']>;
  commissionAmount: Scalars['Float']['input'];
  commissionPercentage: Scalars['Int']['input'];
  fileId: Scalars['String']['input'];
  invoiceNumber: Scalars['String']['input'];
  mode: Scalars['String']['input'];
  receiverEmail: Scalars['String']['input'];
  totalAmount: Scalars['Float']['input'];
  transferAmount: Scalars['Float']['input'];
};

export type InvoiceListRelationFilter = {
  every?: InputMaybe<InvoiceWhereInput>;
  none?: InputMaybe<InvoiceWhereInput>;
  some?: InputMaybe<InvoiceWhereInput>;
};

export type InvoiceOrderByInput = {
  businessEntity?: InputMaybe<BusinessEntityOrderByInput>;
  businessEntityId?: InputMaybe<SortOrder>;
  commissionAmount?: InputMaybe<SortOrder>;
  commissionPercentage?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  fileId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoiceNumber?: InputMaybe<SortOrder>;
  mode?: InputMaybe<SortOrder>;
  receiverEmail?: InputMaybe<SortOrder>;
  totalAmount?: InputMaybe<SortOrder>;
  transferAmount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type InvoicePaginatedResponse = {
  __typename?: 'InvoicePaginatedResponse';
  items: Array<Maybe<Invoice>>;
  pageInfo: PageInfo;
};

export type InvoiceUpdateInput = {
  businessEntityIdConnect?: InputMaybe<Scalars['ID']['input']>;
  businessEntityIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  commissionAmount?: InputMaybe<Scalars['Float']['input']>;
  commissionPercentage?: InputMaybe<Scalars['Int']['input']>;
  fileId?: InputMaybe<Scalars['String']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  receiverEmail?: InputMaybe<Scalars['String']['input']>;
  totalAmount?: InputMaybe<Scalars['Float']['input']>;
  transferAmount?: InputMaybe<Scalars['Float']['input']>;
};

export type InvoiceWhereInput = {
  AND?: InputMaybe<Array<InvoiceWhereInput>>;
  NOT?: InputMaybe<Array<InvoiceWhereInput>>;
  OR?: InputMaybe<Array<InvoiceWhereInput>>;
  businessEntity?: InputMaybe<BusinessEntityWhereInput>;
  businessEntityId?: InputMaybe<StringFilterInput>;
  commissionAmount?: InputMaybe<FloatFilterInput>;
  commissionPercentage?: InputMaybe<IntFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  fileId?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  invoiceNumber?: InputMaybe<StringFilterInput>;
  mode?: InputMaybe<StringFilterInput>;
  receiverEmail?: InputMaybe<StringFilterInput>;
  totalAmount?: InputMaybe<FloatFilterInput>;
  transferAmount?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type InvoiceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type JsonFilterInput = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type Language = {
  __typename?: 'Language';
  businessEntities?: Maybe<Array<BusinessEntity>>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  iso: Scalars['String']['output'];
  name: Scalars['String']['output'];
  siteLanguages?: Maybe<Array<LanguageSiteLanguage>>;
  tag: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type LanguageCreateInput = {
  iso: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tag: Scalars['String']['input'];
};

export type LanguageListRelationFilter = {
  every?: InputMaybe<LanguageWhereInput>;
  none?: InputMaybe<LanguageWhereInput>;
  some?: InputMaybe<LanguageWhereInput>;
};

export type LanguageOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iso?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  tag?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LanguagePaginatedResponse = {
  __typename?: 'LanguagePaginatedResponse';
  items: Array<Maybe<Language>>;
  pageInfo: PageInfo;
};

export type LanguageSiteLanguage = {
  __typename?: 'LanguageSiteLanguage';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  site?: Maybe<Site>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type LanguageSiteLanguageCreateInput = {
  isDefault: Scalars['Boolean']['input'];
  siteId: Scalars['ID']['input'];
};

export type LanguageSiteLanguageDeleteInput = {
  siteId: Scalars['ID']['input'];
};

export type LanguageUpdateInput = {
  iso?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type LanguageWhereInput = {
  AND?: InputMaybe<Array<LanguageWhereInput>>;
  NOT?: InputMaybe<Array<LanguageWhereInput>>;
  OR?: InputMaybe<Array<LanguageWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  iso?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  tag?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type LanguageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type Location = {
  __typename?: 'Location';
  airport?: Maybe<Airport>;
  altitude?: Maybe<Scalars['Float']['output']>;
  businessEntities?: Maybe<Array<BusinessEntity>>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  lat: Scalars['Float']['output'];
  long: Scalars['Float']['output'];
  parking?: Maybe<Parking>;
  postalCode?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars['String']['output']>;
  stateOrProvince?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  terminal?: Maybe<Terminal>;
  timeZone?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type LocationCreateInput = {
  altitude?: InputMaybe<Scalars['Float']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryIdConnect?: InputMaybe<Scalars['ID']['input']>;
  lat: Scalars['Float']['input'];
  long: Scalars['Float']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  stateOrProvince?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type LocationListRelationFilter = {
  every?: InputMaybe<LocationWhereInput>;
  none?: InputMaybe<LocationWhereInput>;
  some?: InputMaybe<LocationWhereInput>;
};

export type LocationOrderByInput = {
  altitude?: InputMaybe<SortOrder>;
  city?: InputMaybe<SortOrder>;
  country?: InputMaybe<CountryOrderByInput>;
  countryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lat?: InputMaybe<SortOrder>;
  long?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  stateOrProvince?: InputMaybe<SortOrder>;
  street?: InputMaybe<SortOrder>;
  streetNumber?: InputMaybe<SortOrder>;
  timeZone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LocationPaginatedResponse = {
  __typename?: 'LocationPaginatedResponse';
  items: Array<Maybe<Location>>;
  pageInfo: PageInfo;
};

export type LocationUpdateInput = {
  altitude?: InputMaybe<Scalars['Float']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryIdConnect?: InputMaybe<Scalars['ID']['input']>;
  countryIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  long?: InputMaybe<Scalars['Float']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  stateOrProvince?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type LocationWhereInput = {
  AND?: InputMaybe<Array<LocationWhereInput>>;
  NOT?: InputMaybe<Array<LocationWhereInput>>;
  OR?: InputMaybe<Array<LocationWhereInput>>;
  altitude?: InputMaybe<FloatFilterInput>;
  city?: InputMaybe<StringFilterInput>;
  country?: InputMaybe<CountryWhereInput>;
  countryId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  lat?: InputMaybe<FloatFilterInput>;
  long?: InputMaybe<FloatFilterInput>;
  postalCode?: InputMaybe<StringFilterInput>;
  stateOrProvince?: InputMaybe<StringFilterInput>;
  street?: InputMaybe<StringFilterInput>;
  streetNumber?: InputMaybe<StringFilterInput>;
  timeZone?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type LocationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type MobianPayment = {
  __typename?: 'MobianPayment';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  transactionId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type MobianPaymentCreateInput = {
  transactionId: Scalars['String']['input'];
};

export type MobianPaymentListRelationFilter = {
  every?: InputMaybe<MobianPaymentWhereInput>;
  none?: InputMaybe<MobianPaymentWhereInput>;
  some?: InputMaybe<MobianPaymentWhereInput>;
};

export type MobianPaymentOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MobianPaymentPaginatedResponse = {
  __typename?: 'MobianPaymentPaginatedResponse';
  items: Array<Maybe<MobianPayment>>;
  pageInfo: PageInfo;
};

export type MobianPaymentUpdateInput = {
  transactionId?: InputMaybe<Scalars['String']['input']>;
};

export type MobianPaymentWhereInput = {
  AND?: InputMaybe<Array<MobianPaymentWhereInput>>;
  NOT?: InputMaybe<Array<MobianPaymentWhereInput>>;
  OR?: InputMaybe<Array<MobianPaymentWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  transactionId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type MobianPaymentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptConsent: AcceptConsentPayload;
  addSiteUserRole: SiteUserRole;
  createAirport: Airport;
  createBusinessEntity: BusinessEntity;
  createContactPoint: ContactPoint;
  createCountry: Country;
  createCurrency: Currency;
  createDocument: Document;
  createFile: File;
  createInvoice: Invoice;
  createLanguage: Language;
  createLocation: Location;
  createMobianPayment: MobianPayment;
  createOptionCategory: OptionCategory;
  createOrder: Order;
  createParking: Parking;
  createParkingOption: ParkingOption;
  createParkingParkingOption: ParkingParkingOption;
  createPaymentMethod: PaymentMethod;
  createPmsProvider: PmsProvider;
  createPriceModel: PriceModel;
  createPriceStrategy: PriceStrategy;
  createPriceType: PriceType;
  createProduct: Product;
  createProvider: Provider;
  createRedirect: Redirect;
  createRole: Role;
  createSafeAndSecureLogo: SafeAndSecureLogo;
  createSection: Section;
  createSectionOption: SectionOption;
  createSectionSectionOption: SectionSectionOption;
  createSectionType: SectionType;
  createService: Service;
  createServiceSection: ServiceSection;
  createServiceType: ServiceType;
  createSite: Site;
  createSiteLocalisation: SiteLocalisation;
  createSocialMedia: SocialMedia;
  createSubdomain: Subdomain;
  createTerminal: Terminal;
  createTestLog: TestLog;
  createTrustMarkLogo: TrustMarkLogo;
  createUser: User;
  destroyAirport: Airport;
  destroyBusinessEntity: BusinessEntity;
  destroyContactPoint: ContactPoint;
  destroyCountry: Country;
  destroyCurrency: Currency;
  destroyDocument: Document;
  destroyFile: File;
  destroyInvoice: Invoice;
  destroyLanguage: Language;
  destroyLocation: Location;
  destroyMobianPayment: MobianPayment;
  destroyOptionCategory: OptionCategory;
  destroyOrder: Order;
  destroyParking: Parking;
  destroyParkingOption: ParkingOption;
  destroyParkingParkingOption: ParkingParkingOption;
  destroyPaymentMethod: PaymentMethod;
  destroyPmsProvider: PmsProvider;
  destroyPriceModel: PriceModel;
  destroyPriceStrategy: PriceStrategy;
  destroyPriceType: PriceType;
  destroyProduct: Product;
  destroyProvider: Provider;
  destroyRedirect: Redirect;
  destroyRole: Role;
  destroySafeAndSecureLogo: SafeAndSecureLogo;
  destroySection: Section;
  destroySectionOption: SectionOption;
  destroySectionSectionOption: SectionSectionOption;
  destroySectionType: SectionType;
  destroyService: Service;
  destroyServiceSection: ServiceSection;
  destroyServiceType: ServiceType;
  destroySite: Site;
  destroySiteLocalisation: SiteLocalisation;
  destroySocialMedia: SocialMedia;
  destroySubdomain: Subdomain;
  destroyTerminal: Terminal;
  destroyTestLog: TestLog;
  destroyTrustMarkLogo: TrustMarkLogo;
  destroyUser: User;
  exchangeToken: ExchangeTokenPayload;
  fetchConsent: FetchConsentPayload;
  refreshToken: RefreshTokenPayload;
  rejectConsent: RejectConsentPayload;
  runTest: TestLog;
  signIn: SignInPayload;
  signOut: SignOutPayload;
  updateAirport: Airport;
  updateBusinessEntity: BusinessEntity;
  updateContactPoint: ContactPoint;
  updateCountry: Country;
  updateCurrency: Currency;
  updateDocument: Document;
  updateFile: File;
  updateInvoice: Invoice;
  updateLanguage: Language;
  updateLocation: Location;
  updateMobianPayment: MobianPayment;
  updateOptionCategory: OptionCategory;
  updateOrder: Order;
  updateParking: Parking;
  updateParkingOption: ParkingOption;
  updateParkingParkingOption: ParkingParkingOption;
  updatePaymentMethod: PaymentMethod;
  updatePmsProvider: PmsProvider;
  updatePriceModel: PriceModel;
  updatePriceStrategy: PriceStrategy;
  updatePriceType: PriceType;
  updateProduct: Product;
  updateProvider: Provider;
  updateRedirect: Redirect;
  updateRole: Role;
  updateSafeAndSecureLogo: SafeAndSecureLogo;
  updateSection: Section;
  updateSectionOption: SectionOption;
  updateSectionSectionOption: SectionSectionOption;
  updateSectionType: SectionType;
  updateService: Service;
  updateServiceSection: ServiceSection;
  updateServiceType: ServiceType;
  updateSite: Site;
  updateSiteLocalisation: SiteLocalisation;
  updateSocialMedia: SocialMedia;
  updateSubdomain: Subdomain;
  updateTerminal: Terminal;
  updateTestLog: TestLog;
  updateTrustMarkLogo: TrustMarkLogo;
  updateUser: User;
};

export type MutationAcceptConsentArgs = {
  consentToAccept: ConsentInput;
};

export type MutationAddSiteUserRoleArgs = {
  roleId: Scalars['ID']['input'];
  siteId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationCreateAirportArgs = {
  input: AirportCreateInput;
};

export type MutationCreateBusinessEntityArgs = {
  input: BusinessEntityCreateInput;
};

export type MutationCreateContactPointArgs = {
  input: ContactPointCreateInput;
};

export type MutationCreateCountryArgs = {
  input: CountryCreateInput;
};

export type MutationCreateCurrencyArgs = {
  input: CurrencyCreateInput;
};

export type MutationCreateDocumentArgs = {
  input: DocumentCreateInput;
};

export type MutationCreateFileArgs = {
  input: FileCreateInput;
};

export type MutationCreateInvoiceArgs = {
  input: InvoiceCreateInput;
};

export type MutationCreateLanguageArgs = {
  input: LanguageCreateInput;
};

export type MutationCreateLocationArgs = {
  input: LocationCreateInput;
};

export type MutationCreateMobianPaymentArgs = {
  input: MobianPaymentCreateInput;
};

export type MutationCreateOptionCategoryArgs = {
  input: OptionCategoryCreateInput;
};

export type MutationCreateOrderArgs = {
  input: OrderCreateInput;
};

export type MutationCreateParkingArgs = {
  input: ParkingCreateInput;
};

export type MutationCreateParkingOptionArgs = {
  input: ParkingOptionCreateInput;
};

export type MutationCreateParkingParkingOptionArgs = {
  input: ParkingParkingOptionCreateInput;
};

export type MutationCreatePaymentMethodArgs = {
  input: PaymentMethodCreateInput;
};

export type MutationCreatePmsProviderArgs = {
  input: PmsProviderCreateInput;
};

export type MutationCreatePriceModelArgs = {
  input: PriceModelCreateInput;
};

export type MutationCreatePriceStrategyArgs = {
  input: PriceStrategyCreateInput;
};

export type MutationCreatePriceTypeArgs = {
  input: PriceTypeCreateInput;
};

export type MutationCreateProductArgs = {
  input: ProductCreateInput;
};

export type MutationCreateProviderArgs = {
  input: ProviderCreateInput;
};

export type MutationCreateRedirectArgs = {
  input: RedirectCreateInput;
};

export type MutationCreateRoleArgs = {
  input: RoleCreateInput;
};

export type MutationCreateSafeAndSecureLogoArgs = {
  input: SafeAndSecureLogoCreateInput;
};

export type MutationCreateSectionArgs = {
  input: SectionCreateInput;
};

export type MutationCreateSectionOptionArgs = {
  input: SectionOptionCreateInput;
};

export type MutationCreateSectionSectionOptionArgs = {
  input: SectionSectionOptionCreateInput;
};

export type MutationCreateSectionTypeArgs = {
  input: SectionTypeCreateInput;
};

export type MutationCreateServiceArgs = {
  input: ServiceCreateInput;
};

export type MutationCreateServiceSectionArgs = {
  input: ServiceSectionCreateInput;
};

export type MutationCreateServiceTypeArgs = {
  input: ServiceTypeCreateInput;
};

export type MutationCreateSiteArgs = {
  input: SiteCreateInput;
};

export type MutationCreateSiteLocalisationArgs = {
  input: SiteLocalisationCreateInput;
};

export type MutationCreateSocialMediaArgs = {
  input: SocialMediaCreateInput;
};

export type MutationCreateSubdomainArgs = {
  input: SubdomainCreateInput;
};

export type MutationCreateTerminalArgs = {
  input: TerminalCreateInput;
};

export type MutationCreateTestLogArgs = {
  input: TestLogCreateInput;
};

export type MutationCreateTrustMarkLogoArgs = {
  input: TrustMarkLogoCreateInput;
};

export type MutationCreateUserArgs = {
  input: UserCreateInput;
};

export type MutationDestroyAirportArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyBusinessEntityArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyContactPointArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyCountryArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyCurrencyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyDocumentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyInvoiceArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyLanguageArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyLocationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyMobianPaymentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyOptionCategoryArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyOrderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyParkingArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyParkingOptionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyParkingParkingOptionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyPaymentMethodArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyPmsProviderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyPriceModelArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyPriceStrategyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyPriceTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyProductArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyProviderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyRedirectArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyRoleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroySafeAndSecureLogoArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroySectionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroySectionOptionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroySectionSectionOptionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroySectionTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyServiceArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyServiceSectionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyServiceTypeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroySiteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroySiteLocalisationArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroySocialMediaArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroySubdomainArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyTerminalArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyTestLogArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyTrustMarkLogoArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDestroyUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationExchangeTokenArgs = {
  tokenToExchange: ExchangeTokenInput;
};

export type MutationFetchConsentArgs = {
  consentToFetch: ConsentInput;
};

export type MutationRefreshTokenArgs = {
  currentRefreshToken: Scalars['String']['input'];
};

export type MutationRejectConsentArgs = {
  consentToReject: ConsentInput;
};

export type MutationRunTestArgs = {
  testCaseName: Scalars['String']['input'];
};

export type MutationSignInArgs = {
  userToSign: SignInInput;
};

export type MutationSignOutArgs = {
  signOutParams: SignOutInput;
};

export type MutationUpdateAirportArgs = {
  id: Scalars['ID']['input'];
  input: AirportUpdateInput;
};

export type MutationUpdateBusinessEntityArgs = {
  id: Scalars['ID']['input'];
  input: BusinessEntityUpdateInput;
};

export type MutationUpdateContactPointArgs = {
  id: Scalars['ID']['input'];
  input: ContactPointUpdateInput;
};

export type MutationUpdateCountryArgs = {
  id: Scalars['ID']['input'];
  input: CountryUpdateInput;
};

export type MutationUpdateCurrencyArgs = {
  id: Scalars['ID']['input'];
  input: CurrencyUpdateInput;
};

export type MutationUpdateDocumentArgs = {
  id: Scalars['ID']['input'];
  input: DocumentUpdateInput;
};

export type MutationUpdateFileArgs = {
  id: Scalars['ID']['input'];
  input: FileUpdateInput;
};

export type MutationUpdateInvoiceArgs = {
  id: Scalars['ID']['input'];
  input: InvoiceUpdateInput;
};

export type MutationUpdateLanguageArgs = {
  id: Scalars['ID']['input'];
  input: LanguageUpdateInput;
};

export type MutationUpdateLocationArgs = {
  id: Scalars['ID']['input'];
  input: LocationUpdateInput;
};

export type MutationUpdateMobianPaymentArgs = {
  id: Scalars['ID']['input'];
  input: MobianPaymentUpdateInput;
};

export type MutationUpdateOptionCategoryArgs = {
  id: Scalars['ID']['input'];
  input: OptionCategoryUpdateInput;
};

export type MutationUpdateOrderArgs = {
  id: Scalars['ID']['input'];
  input: OrderUpdateInput;
};

export type MutationUpdateParkingArgs = {
  id: Scalars['ID']['input'];
  input: ParkingUpdateInput;
};

export type MutationUpdateParkingOptionArgs = {
  id: Scalars['ID']['input'];
  input: ParkingOptionUpdateInput;
};

export type MutationUpdateParkingParkingOptionArgs = {
  id: Scalars['ID']['input'];
  input: ParkingParkingOptionUpdateInput;
};

export type MutationUpdatePaymentMethodArgs = {
  id: Scalars['ID']['input'];
  input: PaymentMethodUpdateInput;
};

export type MutationUpdatePmsProviderArgs = {
  id: Scalars['ID']['input'];
  input: PmsProviderUpdateInput;
};

export type MutationUpdatePriceModelArgs = {
  id: Scalars['ID']['input'];
  input: PriceModelUpdateInput;
};

export type MutationUpdatePriceStrategyArgs = {
  id: Scalars['ID']['input'];
  input: PriceStrategyUpdateInput;
};

export type MutationUpdatePriceTypeArgs = {
  id: Scalars['ID']['input'];
  input: PriceTypeUpdateInput;
};

export type MutationUpdateProductArgs = {
  id: Scalars['ID']['input'];
  input: ProductUpdateInput;
};

export type MutationUpdateProviderArgs = {
  id: Scalars['ID']['input'];
  input: ProviderUpdateInput;
};

export type MutationUpdateRedirectArgs = {
  id: Scalars['ID']['input'];
  input: RedirectUpdateInput;
};

export type MutationUpdateRoleArgs = {
  id: Scalars['ID']['input'];
  input: RoleUpdateInput;
};

export type MutationUpdateSafeAndSecureLogoArgs = {
  id: Scalars['ID']['input'];
  input: SafeAndSecureLogoUpdateInput;
};

export type MutationUpdateSectionArgs = {
  id: Scalars['ID']['input'];
  input: SectionUpdateInput;
};

export type MutationUpdateSectionOptionArgs = {
  id: Scalars['ID']['input'];
  input: SectionOptionUpdateInput;
};

export type MutationUpdateSectionSectionOptionArgs = {
  id: Scalars['ID']['input'];
  input: SectionSectionOptionUpdateInput;
};

export type MutationUpdateSectionTypeArgs = {
  id: Scalars['ID']['input'];
  input: SectionTypeUpdateInput;
};

export type MutationUpdateServiceArgs = {
  id: Scalars['ID']['input'];
  input: ServiceUpdateInput;
};

export type MutationUpdateServiceSectionArgs = {
  id: Scalars['ID']['input'];
  input: ServiceSectionUpdateInput;
};

export type MutationUpdateServiceTypeArgs = {
  id: Scalars['ID']['input'];
  input: ServiceTypeUpdateInput;
};

export type MutationUpdateSiteArgs = {
  id: Scalars['ID']['input'];
  input: SiteUpdateInput;
};

export type MutationUpdateSiteLocalisationArgs = {
  id: Scalars['ID']['input'];
  input: SiteLocalisationUpdateInput;
};

export type MutationUpdateSocialMediaArgs = {
  id: Scalars['ID']['input'];
  input: SocialMediaUpdateInput;
};

export type MutationUpdateSubdomainArgs = {
  id: Scalars['ID']['input'];
  input: SubdomainUpdateInput;
};

export type MutationUpdateTerminalArgs = {
  id: Scalars['ID']['input'];
  input: TerminalUpdateInput;
};

export type MutationUpdateTestLogArgs = {
  id: Scalars['ID']['input'];
  input: TestLogUpdateInput;
};

export type MutationUpdateTrustMarkLogoArgs = {
  id: Scalars['ID']['input'];
  input: TrustMarkLogoUpdateInput;
};

export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  input: UserUpdateInput;
};

export type OptionCategory = {
  __typename?: 'OptionCategory';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parkingOptions?: Maybe<Array<ParkingOption>>;
  sectionOptions?: Maybe<Array<SectionOption>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type OptionCategoryCreateInput = {
  icon: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parkingOptionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  sectionOptionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type OptionCategoryListRelationFilter = {
  every?: InputMaybe<OptionCategoryWhereInput>;
  none?: InputMaybe<OptionCategoryWhereInput>;
  some?: InputMaybe<OptionCategoryWhereInput>;
};

export type OptionCategoryOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  icon?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OptionCategoryPaginatedResponse = {
  __typename?: 'OptionCategoryPaginatedResponse';
  items: Array<Maybe<OptionCategory>>;
  pageInfo: PageInfo;
};

export type OptionCategoryUpdateInput = {
  icon?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parkingOptionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  parkingOptionIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  parkingOptionIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  sectionOptionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  sectionOptionIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  sectionOptionIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type OptionCategoryWhereInput = {
  AND?: InputMaybe<Array<OptionCategoryWhereInput>>;
  NOT?: InputMaybe<Array<OptionCategoryWhereInput>>;
  OR?: InputMaybe<Array<OptionCategoryWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  icon?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  parkingOptions?: InputMaybe<ParkingOptionListRelationFilter>;
  sectionOptions?: InputMaybe<SectionOptionListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type OptionCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Order = {
  __typename?: 'Order';
  company?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  customer: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  endDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  journey: Scalars['String']['output'];
  languageTag?: Maybe<Scalars['String']['output']>;
  mobianId?: Maybe<Scalars['String']['output']>;
  parkingId?: Maybe<Scalars['String']['output']>;
  payedOut: Scalars['Boolean']['output'];
  price: Scalars['Float']['output'];
  productId: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  siteId: Scalars['String']['output'];
  startDate: Scalars['Date']['output'];
  status: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  voucherFileId?: Maybe<Scalars['String']['output']>;
};

export type OrderCreateInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  customer: Scalars['String']['input'];
  endDate: Scalars['Date']['input'];
  journey: Scalars['String']['input'];
  languageTag?: InputMaybe<Scalars['String']['input']>;
  mobianId?: InputMaybe<Scalars['String']['input']>;
  parkingId?: InputMaybe<Scalars['String']['input']>;
  payedOut: Scalars['Boolean']['input'];
  price: Scalars['Float']['input'];
  productId: Scalars['String']['input'];
  reference: Scalars['String']['input'];
  siteId: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
  status: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
  voucherFileId?: InputMaybe<Scalars['String']['input']>;
};

export type OrderListRelationFilter = {
  every?: InputMaybe<OrderWhereInput>;
  none?: InputMaybe<OrderWhereInput>;
  some?: InputMaybe<OrderWhereInput>;
};

export type OrderOrderByInput = {
  company?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  journey?: InputMaybe<SortOrder>;
  languageTag?: InputMaybe<SortOrder>;
  mobianId?: InputMaybe<SortOrder>;
  parkingId?: InputMaybe<SortOrder>;
  payedOut?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  reference?: InputMaybe<SortOrder>;
  siteId?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  voucherFileId?: InputMaybe<SortOrder>;
};

export type OrderPaginatedResponse = {
  __typename?: 'OrderPaginatedResponse';
  items: Array<Maybe<Order>>;
  pageInfo: PageInfo;
};

export type OrderUpdateInput = {
  company?: InputMaybe<Scalars['String']['input']>;
  customer?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  journey?: InputMaybe<Scalars['String']['input']>;
  languageTag?: InputMaybe<Scalars['String']['input']>;
  mobianId?: InputMaybe<Scalars['String']['input']>;
  parkingId?: InputMaybe<Scalars['String']['input']>;
  payedOut?: InputMaybe<Scalars['Boolean']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  voucherFileId?: InputMaybe<Scalars['String']['input']>;
};

export type OrderWhereInput = {
  AND?: InputMaybe<Array<OrderWhereInput>>;
  NOT?: InputMaybe<Array<OrderWhereInput>>;
  OR?: InputMaybe<Array<OrderWhereInput>>;
  company?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  customer?: InputMaybe<StringFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  endDate?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  journey?: InputMaybe<StringFilterInput>;
  languageTag?: InputMaybe<StringFilterInput>;
  mobianId?: InputMaybe<StringFilterInput>;
  parkingId?: InputMaybe<StringFilterInput>;
  payedOut?: InputMaybe<Scalars['Boolean']['input']>;
  price?: InputMaybe<FloatFilterInput>;
  productId?: InputMaybe<StringFilterInput>;
  reference?: InputMaybe<StringFilterInput>;
  siteId?: InputMaybe<StringFilterInput>;
  startDate?: InputMaybe<DateFilterInput>;
  status?: InputMaybe<StringFilterInput>;
  timezone?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
  userId?: InputMaybe<StringFilterInput>;
  voucherFileId?: InputMaybe<StringFilterInput>;
};

export type OrderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  currentPage: Scalars['Int']['output'];
  hasMore: Scalars['Boolean']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type Parking = {
  __typename?: 'Parking';
  additionalInformation: Scalars['String']['output'];
  addressInstructions: Array<Scalars['String']['output']>;
  businessEntityId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  locations?: Maybe<Array<Location>>;
  mainLocation: Scalars['String']['output'];
  parkingParkingOptions?: Maybe<Array<ParkingParkingOption>>;
  photos: Array<Scalars['String']['output']>;
  procedure: Array<Scalars['String']['output']>;
  remarks: Array<Scalars['String']['output']>;
  travelDistanceToAirport: Scalars['Int']['output'];
  travelTimeToAirport: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ParkingCreateInput = {
  additionalInformation: Scalars['String']['input'];
  addressInstructions: Array<Scalars['String']['input']>;
  businessEntityId: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  locationIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  mainLocation: Scalars['String']['input'];
  parkingParkingOptionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  photos: Array<Scalars['String']['input']>;
  procedure: Array<Scalars['String']['input']>;
  remarks: Array<Scalars['String']['input']>;
  travelDistanceToAirport: Scalars['Int']['input'];
  travelTimeToAirport: Scalars['Int']['input'];
};

export type ParkingListRelationFilter = {
  every?: InputMaybe<ParkingWhereInput>;
  none?: InputMaybe<ParkingWhereInput>;
  some?: InputMaybe<ParkingWhereInput>;
};

export type ParkingOption = {
  __typename?: 'ParkingOption';
  cancelable: Scalars['String']['output'];
  category?: Maybe<OptionCategory>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parkingOptions?: Maybe<Array<ParkingParkingOption>>;
  remark: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ParkingOptionCreateInput = {
  cancelable: Scalars['String']['input'];
  categoryIdConnect: Scalars['ID']['input'];
  icon: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parkingOptionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  remark: Scalars['String']['input'];
};

export type ParkingOptionListRelationFilter = {
  every?: InputMaybe<ParkingOptionWhereInput>;
  none?: InputMaybe<ParkingOptionWhereInput>;
  some?: InputMaybe<ParkingOptionWhereInput>;
};

export type ParkingOptionOrderByInput = {
  cancelable?: InputMaybe<SortOrder>;
  category?: InputMaybe<OptionCategoryOrderByInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  icon?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  optionCategoryId?: InputMaybe<SortOrder>;
  remark?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ParkingOptionPaginatedResponse = {
  __typename?: 'ParkingOptionPaginatedResponse';
  items: Array<Maybe<ParkingOption>>;
  pageInfo: PageInfo;
};

export type ParkingOptionUpdateInput = {
  cancelable?: InputMaybe<Scalars['String']['input']>;
  categoryIdConnect?: InputMaybe<Scalars['ID']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parkingOptionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  parkingOptionIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  parkingOptionIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  remark?: InputMaybe<Scalars['String']['input']>;
};

export type ParkingOptionWhereInput = {
  AND?: InputMaybe<Array<ParkingOptionWhereInput>>;
  NOT?: InputMaybe<Array<ParkingOptionWhereInput>>;
  OR?: InputMaybe<Array<ParkingOptionWhereInput>>;
  cancelable?: InputMaybe<StringFilterInput>;
  category?: InputMaybe<OptionCategoryWhereInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  icon?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  optionCategoryId?: InputMaybe<StringFilterInput>;
  parkingOptions?: InputMaybe<ParkingParkingOptionListRelationFilter>;
  remark?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ParkingOptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ParkingOrderByInput = {
  additionalInformation?: InputMaybe<SortOrder>;
  addressInstructions?: InputMaybe<SortOrder>;
  businessEntityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  enabled?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainLocation?: InputMaybe<SortOrder>;
  photos?: InputMaybe<SortOrder>;
  procedure?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
  travelDistanceToAirport?: InputMaybe<SortOrder>;
  travelTimeToAirport?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ParkingPaginatedResponse = {
  __typename?: 'ParkingPaginatedResponse';
  items: Array<Maybe<Parking>>;
  pageInfo: PageInfo;
};

export type ParkingParkingOption = {
  __typename?: 'ParkingParkingOption';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isFree: Scalars['Boolean']['output'];
  option?: Maybe<ParkingOption>;
  parking?: Maybe<Parking>;
  price: Scalars['Float']['output'];
  remark: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ParkingParkingOptionCreateInput = {
  isFree: Scalars['Boolean']['input'];
  optionIdConnect: Scalars['ID']['input'];
  parkingIdConnect: Scalars['ID']['input'];
  price: Scalars['Float']['input'];
  remark: Scalars['String']['input'];
};

export type ParkingParkingOptionListRelationFilter = {
  every?: InputMaybe<ParkingParkingOptionWhereInput>;
  none?: InputMaybe<ParkingParkingOptionWhereInput>;
  some?: InputMaybe<ParkingParkingOptionWhereInput>;
};

export type ParkingParkingOptionOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isFree?: InputMaybe<SortOrder>;
  option?: InputMaybe<ParkingOptionOrderByInput>;
  parking?: InputMaybe<ParkingOrderByInput>;
  parkingId?: InputMaybe<SortOrder>;
  parkingOptionId?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  remark?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ParkingParkingOptionPaginatedResponse = {
  __typename?: 'ParkingParkingOptionPaginatedResponse';
  items: Array<Maybe<ParkingParkingOption>>;
  pageInfo: PageInfo;
};

export type ParkingParkingOptionUpdateInput = {
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  optionIdConnect?: InputMaybe<Scalars['ID']['input']>;
  parkingIdConnect?: InputMaybe<Scalars['ID']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
};

export type ParkingParkingOptionWhereInput = {
  AND?: InputMaybe<Array<ParkingParkingOptionWhereInput>>;
  NOT?: InputMaybe<Array<ParkingParkingOptionWhereInput>>;
  OR?: InputMaybe<Array<ParkingParkingOptionWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  option?: InputMaybe<ParkingOptionWhereInput>;
  parking?: InputMaybe<ParkingWhereInput>;
  parkingId?: InputMaybe<StringFilterInput>;
  parkingOptionId?: InputMaybe<StringFilterInput>;
  price?: InputMaybe<FloatFilterInput>;
  remark?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ParkingParkingOptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ParkingUpdateInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  addressInstructions?: InputMaybe<Array<Scalars['String']['input']>>;
  businessEntityId?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  locationIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  locationIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  mainLocation?: InputMaybe<Scalars['String']['input']>;
  parkingParkingOptionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  parkingParkingOptionIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  parkingParkingOptionIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  photos?: InputMaybe<Array<Scalars['String']['input']>>;
  procedure?: InputMaybe<Array<Scalars['String']['input']>>;
  remarks?: InputMaybe<Array<Scalars['String']['input']>>;
  travelDistanceToAirport?: InputMaybe<Scalars['Int']['input']>;
  travelTimeToAirport?: InputMaybe<Scalars['Int']['input']>;
};

export type ParkingWhereInput = {
  AND?: InputMaybe<Array<ParkingWhereInput>>;
  NOT?: InputMaybe<Array<ParkingWhereInput>>;
  OR?: InputMaybe<Array<ParkingWhereInput>>;
  additionalInformation?: InputMaybe<StringFilterInput>;
  addressInstructions?: InputMaybe<StringListFilterInput>;
  businessEntityId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<StringFilterInput>;
  locations?: InputMaybe<LocationListRelationFilter>;
  mainLocation?: InputMaybe<StringFilterInput>;
  parkingParkingOptions?: InputMaybe<ParkingParkingOptionListRelationFilter>;
  photos?: InputMaybe<StringListFilterInput>;
  procedure?: InputMaybe<StringListFilterInput>;
  remarks?: InputMaybe<StringListFilterInput>;
  travelDistanceToAirport?: InputMaybe<IntFilterInput>;
  travelTimeToAirport?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ParkingWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sites?: Maybe<Array<Site>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PaymentMethodCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  siteIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PaymentMethodListRelationFilter = {
  every?: InputMaybe<PaymentMethodWhereInput>;
  none?: InputMaybe<PaymentMethodWhereInput>;
  some?: InputMaybe<PaymentMethodWhereInput>;
};

export type PaymentMethodOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PaymentMethodPaginatedResponse = {
  __typename?: 'PaymentMethodPaginatedResponse';
  items: Array<Maybe<PaymentMethod>>;
  pageInfo: PageInfo;
};

export type PaymentMethodUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  siteIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PaymentMethodWhereInput = {
  AND?: InputMaybe<Array<PaymentMethodWhereInput>>;
  NOT?: InputMaybe<Array<PaymentMethodWhereInput>>;
  OR?: InputMaybe<Array<PaymentMethodWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  sites?: InputMaybe<SiteListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type PaymentMethodWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type PmsProvider = {
  __typename?: 'PmsProvider';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  productionApiKey?: Maybe<Scalars['String']['output']>;
  testApiKey?: Maybe<Scalars['String']['output']>;
  testProviderId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PmsProviderCreateInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  productionApiKey?: InputMaybe<Scalars['String']['input']>;
  testApiKey?: InputMaybe<Scalars['String']['input']>;
  testProviderId?: InputMaybe<Scalars['String']['input']>;
};

export type PmsProviderListRelationFilter = {
  every?: InputMaybe<PmsProviderWhereInput>;
  none?: InputMaybe<PmsProviderWhereInput>;
  some?: InputMaybe<PmsProviderWhereInput>;
};

export type PmsProviderOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  productionApiKey?: InputMaybe<SortOrder>;
  testApiKey?: InputMaybe<SortOrder>;
  testProviderId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PmsProviderPaginatedResponse = {
  __typename?: 'PmsProviderPaginatedResponse';
  items: Array<Maybe<PmsProvider>>;
  pageInfo: PageInfo;
};

export type PmsProviderUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  productionApiKey?: InputMaybe<Scalars['String']['input']>;
  testApiKey?: InputMaybe<Scalars['String']['input']>;
  testProviderId?: InputMaybe<Scalars['String']['input']>;
};

export type PmsProviderWhereInput = {
  AND?: InputMaybe<Array<PmsProviderWhereInput>>;
  NOT?: InputMaybe<Array<PmsProviderWhereInput>>;
  OR?: InputMaybe<Array<PmsProviderWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  phone?: InputMaybe<StringFilterInput>;
  productionApiKey?: InputMaybe<StringFilterInput>;
  testApiKey?: InputMaybe<StringFilterInput>;
  testProviderId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type PmsProviderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type PriceModel = {
  __typename?: 'PriceModel';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  priceStrategies?: Maybe<Array<PriceStrategy>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PriceModelCreateInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  priceStrategyIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PriceModelListRelationFilter = {
  every?: InputMaybe<PriceModelWhereInput>;
  none?: InputMaybe<PriceModelWhereInput>;
  some?: InputMaybe<PriceModelWhereInput>;
};

export type PriceModelOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PriceModelPaginatedResponse = {
  __typename?: 'PriceModelPaginatedResponse';
  items: Array<Maybe<PriceModel>>;
  pageInfo: PageInfo;
};

export type PriceModelUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priceStrategyIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  priceStrategyIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  priceStrategyIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PriceModelWhereInput = {
  AND?: InputMaybe<Array<PriceModelWhereInput>>;
  NOT?: InputMaybe<Array<PriceModelWhereInput>>;
  OR?: InputMaybe<Array<PriceModelWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  priceStrategies?: InputMaybe<PriceStrategyListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type PriceModelWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type PriceStrategy = {
  __typename?: 'PriceStrategy';
  businessEntityId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  priceModel?: Maybe<PriceModel>;
  priceType?: Maybe<PriceType>;
  remark: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PriceStrategyCreateInput = {
  businessEntityId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  priceModelIdConnect: Scalars['ID']['input'];
  priceTypeIdConnect: Scalars['ID']['input'];
  remark: Scalars['String']['input'];
};

export type PriceStrategyListRelationFilter = {
  every?: InputMaybe<PriceStrategyWhereInput>;
  none?: InputMaybe<PriceStrategyWhereInput>;
  some?: InputMaybe<PriceStrategyWhereInput>;
};

export type PriceStrategyOrderByInput = {
  businessEntityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  priceModel?: InputMaybe<PriceModelOrderByInput>;
  priceModelId?: InputMaybe<SortOrder>;
  priceType?: InputMaybe<PriceTypeOrderByInput>;
  priceTypeId?: InputMaybe<SortOrder>;
  remark?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PriceStrategyPaginatedResponse = {
  __typename?: 'PriceStrategyPaginatedResponse';
  items: Array<Maybe<PriceStrategy>>;
  pageInfo: PageInfo;
};

export type PriceStrategyUpdateInput = {
  businessEntityId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priceModelIdConnect?: InputMaybe<Scalars['ID']['input']>;
  priceTypeIdConnect?: InputMaybe<Scalars['ID']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
};

export type PriceStrategyWhereInput = {
  AND?: InputMaybe<Array<PriceStrategyWhereInput>>;
  NOT?: InputMaybe<Array<PriceStrategyWhereInput>>;
  OR?: InputMaybe<Array<PriceStrategyWhereInput>>;
  businessEntityId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  priceModel?: InputMaybe<PriceModelWhereInput>;
  priceModelId?: InputMaybe<StringFilterInput>;
  priceType?: InputMaybe<PriceTypeWhereInput>;
  priceTypeId?: InputMaybe<StringFilterInput>;
  remark?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type PriceStrategyWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type PriceType = {
  __typename?: 'PriceType';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  priceStrategies?: Maybe<Array<PriceStrategy>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type PriceTypeCreateInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  priceStrategyIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PriceTypeListRelationFilter = {
  every?: InputMaybe<PriceTypeWhereInput>;
  none?: InputMaybe<PriceTypeWhereInput>;
  some?: InputMaybe<PriceTypeWhereInput>;
};

export type PriceTypeOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PriceTypePaginatedResponse = {
  __typename?: 'PriceTypePaginatedResponse';
  items: Array<Maybe<PriceType>>;
  pageInfo: PageInfo;
};

export type PriceTypeUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priceStrategyIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  priceStrategyIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  priceStrategyIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PriceTypeWhereInput = {
  AND?: InputMaybe<Array<PriceTypeWhereInput>>;
  NOT?: InputMaybe<Array<PriceTypeWhereInput>>;
  OR?: InputMaybe<Array<PriceTypeWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  priceStrategies?: InputMaybe<PriceStrategyListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type PriceTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Product = {
  __typename?: 'Product';
  businessEntityId: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  parkingId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ProductCreateInput = {
  businessEntityId: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  parkingId: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
};

export type ProductListRelationFilter = {
  every?: InputMaybe<ProductWhereInput>;
  none?: InputMaybe<ProductWhereInput>;
  some?: InputMaybe<ProductWhereInput>;
};

export type ProductOrderByInput = {
  businessEntityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  enabled?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  parkingId?: InputMaybe<SortOrder>;
  providerId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ProductPaginatedResponse = {
  __typename?: 'ProductPaginatedResponse';
  items: Array<Maybe<Product>>;
  pageInfo: PageInfo;
};

export type ProductUpdateInput = {
  businessEntityId?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  parkingId?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
};

export type ProductWhereInput = {
  AND?: InputMaybe<Array<ProductWhereInput>>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  businessEntityId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<StringFilterInput>;
  parkingId?: InputMaybe<StringFilterInput>;
  providerId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Provider = {
  __typename?: 'Provider';
  airportId: Scalars['String']['output'];
  businessEntity?: Maybe<BusinessEntity>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  languageId: Scalars['String']['output'];
  languages: Array<Scalars['String']['output']>;
  location?: Maybe<Location>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  openingHours: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  pmsId?: Maybe<Scalars['String']['output']>;
  travelDistanceToAirport: Scalars['Int']['output'];
  travelTimeToAirport: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  users: Array<Scalars['String']['output']>;
};

export type ProviderCreateInput = {
  airportId: Scalars['String']['input'];
  businessEntityIdConnect: Scalars['ID']['input'];
  enabled: Scalars['Boolean']['input'];
  languageId: Scalars['String']['input'];
  languages: Array<Scalars['String']['input']>;
  locationIdConnect: Scalars['ID']['input'];
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  openingHours: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  pmsId?: InputMaybe<Scalars['String']['input']>;
  travelDistanceToAirport: Scalars['Int']['input'];
  travelTimeToAirport: Scalars['Int']['input'];
  users: Array<Scalars['String']['input']>;
};

export type ProviderListRelationFilter = {
  every?: InputMaybe<ProviderWhereInput>;
  none?: InputMaybe<ProviderWhereInput>;
  some?: InputMaybe<ProviderWhereInput>;
};

export type ProviderOrderByInput = {
  airportId?: InputMaybe<SortOrder>;
  businessEntity?: InputMaybe<BusinessEntityOrderByInput>;
  businessEntityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  enabled?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  languageId?: InputMaybe<SortOrder>;
  languages?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationOrderByInput>;
  locationId?: InputMaybe<SortOrder>;
  logoUrl?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  openingHours?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  pmsId?: InputMaybe<SortOrder>;
  travelDistanceToAirport?: InputMaybe<SortOrder>;
  travelTimeToAirport?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  users?: InputMaybe<SortOrder>;
};

export type ProviderPaginatedResponse = {
  __typename?: 'ProviderPaginatedResponse';
  items: Array<Maybe<Provider>>;
  pageInfo: PageInfo;
};

export type ProviderUpdateInput = {
  airportId?: InputMaybe<Scalars['String']['input']>;
  businessEntityIdConnect?: InputMaybe<Scalars['ID']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  languageId?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  locationIdConnect?: InputMaybe<Scalars['ID']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  openingHours?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pmsId?: InputMaybe<Scalars['String']['input']>;
  travelDistanceToAirport?: InputMaybe<Scalars['Int']['input']>;
  travelTimeToAirport?: InputMaybe<Scalars['Int']['input']>;
  users?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ProviderWhereInput = {
  AND?: InputMaybe<Array<ProviderWhereInput>>;
  NOT?: InputMaybe<Array<ProviderWhereInput>>;
  OR?: InputMaybe<Array<ProviderWhereInput>>;
  airportId?: InputMaybe<StringFilterInput>;
  businessEntity?: InputMaybe<BusinessEntityWhereInput>;
  businessEntityId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<StringFilterInput>;
  languageId?: InputMaybe<StringFilterInput>;
  languages?: InputMaybe<StringListFilterInput>;
  location?: InputMaybe<LocationWhereInput>;
  locationId?: InputMaybe<StringFilterInput>;
  logoUrl?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  openingHours?: InputMaybe<StringFilterInput>;
  phone?: InputMaybe<StringFilterInput>;
  pmsId?: InputMaybe<StringFilterInput>;
  travelDistanceToAirport?: InputMaybe<IntFilterInput>;
  travelTimeToAirport?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
  users?: InputMaybe<StringListFilterInput>;
};

export type ProviderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
};

export type Query = {
  __typename?: 'Query';
  getAirportBy?: Maybe<Airport>;
  getAirportById?: Maybe<Airport>;
  getAllAirports: AirportPaginatedResponse;
  getAllBusinessEntities: BusinessEntityPaginatedResponse;
  getAllContactPoints: ContactPointPaginatedResponse;
  getAllCountries: CountryPaginatedResponse;
  getAllCurrencies: CurrencyPaginatedResponse;
  getAllDocuments: DocumentPaginatedResponse;
  getAllFiles: FilePaginatedResponse;
  getAllInvoices: InvoicePaginatedResponse;
  getAllLanguages: LanguagePaginatedResponse;
  getAllLocations: LocationPaginatedResponse;
  getAllMobianPayments: MobianPaymentPaginatedResponse;
  getAllOptionCategories: OptionCategoryPaginatedResponse;
  getAllOrders: OrderPaginatedResponse;
  getAllParkingOptions: ParkingOptionPaginatedResponse;
  getAllParkingParkingOptions: ParkingParkingOptionPaginatedResponse;
  getAllParkings: ParkingPaginatedResponse;
  getAllPaymentMethods: PaymentMethodPaginatedResponse;
  getAllPmsProviders: PmsProviderPaginatedResponse;
  getAllPriceModels: PriceModelPaginatedResponse;
  getAllPriceStrategies: PriceStrategyPaginatedResponse;
  getAllPriceTypes: PriceTypePaginatedResponse;
  getAllProducts: ProductPaginatedResponse;
  getAllProviders: ProviderPaginatedResponse;
  getAllRedirects: RedirectPaginatedResponse;
  getAllRoles: RolePaginatedResponse;
  getAllSafeAndSecureLogos: SafeAndSecureLogoPaginatedResponse;
  getAllSectionOptions: SectionOptionPaginatedResponse;
  getAllSectionSectionOptions: SectionSectionOptionPaginatedResponse;
  getAllSectionTypes: SectionTypePaginatedResponse;
  getAllSections: SectionPaginatedResponse;
  getAllServiceSections: ServiceSectionPaginatedResponse;
  getAllServiceTypes: ServiceTypePaginatedResponse;
  getAllServices: ServicePaginatedResponse;
  getAllSiteLocalisations: SiteLocalisationPaginatedResponse;
  getAllSites: SitePaginatedResponse;
  getAllSocialMedias: SocialMediaPaginatedResponse;
  getAllSubdomains: SubdomainPaginatedResponse;
  getAllTerminals: TerminalPaginatedResponse;
  getAllTestLogs: TestLogPaginatedResponse;
  getAllTrustMarkLogos: TrustMarkLogoPaginatedResponse;
  getAllUsers: UserPaginatedResponse;
  getBusinessEntityBy?: Maybe<BusinessEntity>;
  getBusinessEntityById?: Maybe<BusinessEntity>;
  getContactPointBy?: Maybe<ContactPoint>;
  getContactPointById?: Maybe<ContactPoint>;
  getCountryBy?: Maybe<Country>;
  getCountryById?: Maybe<Country>;
  getCurrencyBy?: Maybe<Currency>;
  getCurrencyById?: Maybe<Currency>;
  getDocumentBy?: Maybe<Document>;
  getDocumentById?: Maybe<Document>;
  getFileBy?: Maybe<File>;
  getFileById?: Maybe<File>;
  getInvoiceBy?: Maybe<Invoice>;
  getInvoiceById?: Maybe<Invoice>;
  getLanguageBy?: Maybe<Language>;
  getLanguageById?: Maybe<Language>;
  getLocationBy?: Maybe<Location>;
  getLocationById?: Maybe<Location>;
  getMobianPaymentBy?: Maybe<MobianPayment>;
  getMobianPaymentById?: Maybe<MobianPayment>;
  getOptionCategoryBy?: Maybe<OptionCategory>;
  getOptionCategoryById?: Maybe<OptionCategory>;
  getOrderBy?: Maybe<Order>;
  getOrderById?: Maybe<Order>;
  getParkingBy?: Maybe<Parking>;
  getParkingById?: Maybe<Parking>;
  getParkingOptionBy?: Maybe<ParkingOption>;
  getParkingOptionById?: Maybe<ParkingOption>;
  getParkingParkingOptionBy?: Maybe<ParkingParkingOption>;
  getParkingParkingOptionById?: Maybe<ParkingParkingOption>;
  getPaymentMethodBy?: Maybe<PaymentMethod>;
  getPaymentMethodById?: Maybe<PaymentMethod>;
  getPmsProviderBy?: Maybe<PmsProvider>;
  getPmsProviderById?: Maybe<PmsProvider>;
  getPriceModelBy?: Maybe<PriceModel>;
  getPriceModelById?: Maybe<PriceModel>;
  getPriceStrategyBy?: Maybe<PriceStrategy>;
  getPriceStrategyById?: Maybe<PriceStrategy>;
  getPriceTypeBy?: Maybe<PriceType>;
  getPriceTypeById?: Maybe<PriceType>;
  getProductBy?: Maybe<Product>;
  getProductById?: Maybe<Product>;
  getProviderBy?: Maybe<Provider>;
  getProviderById?: Maybe<Provider>;
  getRedirectBy?: Maybe<Redirect>;
  getRedirectById?: Maybe<Redirect>;
  getRoleById?: Maybe<Role>;
  getSafeAndSecureLogoBy?: Maybe<SafeAndSecureLogo>;
  getSafeAndSecureLogoById?: Maybe<SafeAndSecureLogo>;
  getSectionBy?: Maybe<Section>;
  getSectionById?: Maybe<Section>;
  getSectionOptionBy?: Maybe<SectionOption>;
  getSectionOptionById?: Maybe<SectionOption>;
  getSectionSectionOptionBy?: Maybe<SectionSectionOption>;
  getSectionSectionOptionById?: Maybe<SectionSectionOption>;
  getSectionTypeBy?: Maybe<SectionType>;
  getSectionTypeById?: Maybe<SectionType>;
  getServiceBy?: Maybe<Service>;
  getServiceById?: Maybe<Service>;
  getServiceSectionBy?: Maybe<ServiceSection>;
  getServiceSectionById?: Maybe<ServiceSection>;
  getServiceTypeBy?: Maybe<ServiceType>;
  getServiceTypeById?: Maybe<ServiceType>;
  getSiteBy?: Maybe<Site>;
  getSiteById?: Maybe<Site>;
  getSiteLocalisationBy?: Maybe<SiteLocalisation>;
  getSiteLocalisationById?: Maybe<SiteLocalisation>;
  getSitePopularAirportsBy: AirportPaginatedResponse;
  getSocialMediaBy?: Maybe<SocialMedia>;
  getSocialMediaById?: Maybe<SocialMedia>;
  getSubdomainBy?: Maybe<Subdomain>;
  getSubdomainById?: Maybe<Subdomain>;
  getTerminalBy?: Maybe<Terminal>;
  getTerminalById?: Maybe<Terminal>;
  getTestLogBy?: Maybe<TestLog>;
  getTestLogById?: Maybe<TestLog>;
  getTrustMarkLogoBy?: Maybe<TrustMarkLogo>;
  getTrustMarkLogoById?: Maybe<TrustMarkLogo>;
  getUserById?: Maybe<User>;
};

export type QueryGetAirportByArgs = {
  where: AirportWhereUniqueInput;
};

export type QueryGetAirportByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetAllAirportsArgs = {
  orderBy?: InputMaybe<Array<AirportOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AirportWhereInput>;
};

export type QueryGetAllBusinessEntitiesArgs = {
  orderBy?: InputMaybe<Array<BusinessEntityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BusinessEntityWhereInput>;
};

export type QueryGetAllContactPointsArgs = {
  orderBy?: InputMaybe<Array<ContactPointOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ContactPointWhereInput>;
};

export type QueryGetAllCountriesArgs = {
  orderBy?: InputMaybe<Array<CountryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CountryWhereInput>;
};

export type QueryGetAllCurrenciesArgs = {
  orderBy?: InputMaybe<Array<CurrencyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CurrencyWhereInput>;
};

export type QueryGetAllDocumentsArgs = {
  orderBy?: InputMaybe<Array<DocumentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DocumentWhereInput>;
};

export type QueryGetAllFilesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FileWhereInput>;
};

export type QueryGetAllInvoicesArgs = {
  orderBy?: InputMaybe<Array<InvoiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvoiceWhereInput>;
};

export type QueryGetAllLanguagesArgs = {
  orderBy?: InputMaybe<Array<LanguageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LanguageWhereInput>;
};

export type QueryGetAllLocationsArgs = {
  orderBy?: InputMaybe<Array<LocationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LocationWhereInput>;
};

export type QueryGetAllMobianPaymentsArgs = {
  orderBy?: InputMaybe<Array<MobianPaymentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MobianPaymentWhereInput>;
};

export type QueryGetAllOptionCategoriesArgs = {
  orderBy?: InputMaybe<Array<OptionCategoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OptionCategoryWhereInput>;
};

export type QueryGetAllOrdersArgs = {
  orderBy?: InputMaybe<Array<OrderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrderWhereInput>;
};

export type QueryGetAllParkingOptionsArgs = {
  orderBy?: InputMaybe<Array<ParkingOptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParkingOptionWhereInput>;
};

export type QueryGetAllParkingParkingOptionsArgs = {
  orderBy?: InputMaybe<Array<ParkingParkingOptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParkingParkingOptionWhereInput>;
};

export type QueryGetAllParkingsArgs = {
  orderBy?: InputMaybe<Array<ParkingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParkingWhereInput>;
};

export type QueryGetAllPaymentMethodsArgs = {
  orderBy?: InputMaybe<Array<PaymentMethodOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PaymentMethodWhereInput>;
};

export type QueryGetAllPmsProvidersArgs = {
  orderBy?: InputMaybe<Array<PmsProviderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PmsProviderWhereInput>;
};

export type QueryGetAllPriceModelsArgs = {
  orderBy?: InputMaybe<Array<PriceModelOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PriceModelWhereInput>;
};

export type QueryGetAllPriceStrategiesArgs = {
  orderBy?: InputMaybe<Array<PriceStrategyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PriceStrategyWhereInput>;
};

export type QueryGetAllPriceTypesArgs = {
  orderBy?: InputMaybe<Array<PriceTypeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PriceTypeWhereInput>;
};

export type QueryGetAllProductsArgs = {
  orderBy?: InputMaybe<Array<ProductOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductWhereInput>;
};

export type QueryGetAllProvidersArgs = {
  orderBy?: InputMaybe<Array<ProviderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProviderWhereInput>;
};

export type QueryGetAllRedirectsArgs = {
  orderBy?: InputMaybe<Array<RedirectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RedirectWhereInput>;
};

export type QueryGetAllRolesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RoleWhereInput>;
};

export type QueryGetAllSafeAndSecureLogosArgs = {
  orderBy?: InputMaybe<Array<SafeAndSecureLogoOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SafeAndSecureLogoWhereInput>;
};

export type QueryGetAllSectionOptionsArgs = {
  orderBy?: InputMaybe<Array<SectionOptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SectionOptionWhereInput>;
};

export type QueryGetAllSectionSectionOptionsArgs = {
  orderBy?: InputMaybe<Array<SectionSectionOptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SectionSectionOptionWhereInput>;
};

export type QueryGetAllSectionTypesArgs = {
  orderBy?: InputMaybe<Array<SectionTypeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SectionTypeWhereInput>;
};

export type QueryGetAllSectionsArgs = {
  orderBy?: InputMaybe<Array<SectionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SectionWhereInput>;
};

export type QueryGetAllServiceSectionsArgs = {
  orderBy?: InputMaybe<Array<ServiceSectionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ServiceSectionWhereInput>;
};

export type QueryGetAllServiceTypesArgs = {
  orderBy?: InputMaybe<Array<ServiceTypeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ServiceTypeWhereInput>;
};

export type QueryGetAllServicesArgs = {
  orderBy?: InputMaybe<Array<ServiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ServiceWhereInput>;
};

export type QueryGetAllSiteLocalisationsArgs = {
  orderBy?: InputMaybe<Array<SiteLocalisationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SiteLocalisationWhereInput>;
};

export type QueryGetAllSitesArgs = {
  orderBy?: InputMaybe<Array<SiteOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SiteWhereInput>;
};

export type QueryGetAllSocialMediasArgs = {
  orderBy?: InputMaybe<Array<SocialMediaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SocialMediaWhereInput>;
};

export type QueryGetAllSubdomainsArgs = {
  orderBy?: InputMaybe<Array<SubdomainOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SubdomainWhereInput>;
};

export type QueryGetAllTerminalsArgs = {
  orderBy?: InputMaybe<Array<TerminalOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TerminalWhereInput>;
};

export type QueryGetAllTestLogsArgs = {
  orderBy?: InputMaybe<Array<TestLogOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TestLogWhereInput>;
};

export type QueryGetAllTrustMarkLogosArgs = {
  orderBy?: InputMaybe<Array<TrustMarkLogoOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TrustMarkLogoWhereInput>;
};

export type QueryGetAllUsersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryGetBusinessEntityByArgs = {
  where: BusinessEntityWhereUniqueInput;
};

export type QueryGetBusinessEntityByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetContactPointByArgs = {
  where: ContactPointWhereUniqueInput;
};

export type QueryGetContactPointByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetCountryByArgs = {
  where: CountryWhereUniqueInput;
};

export type QueryGetCountryByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetCurrencyByArgs = {
  where: CurrencyWhereUniqueInput;
};

export type QueryGetCurrencyByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetDocumentByArgs = {
  where: DocumentWhereUniqueInput;
};

export type QueryGetDocumentByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetFileByArgs = {
  where: FileWhereUniqueInput;
};

export type QueryGetFileByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetInvoiceByArgs = {
  where: InvoiceWhereUniqueInput;
};

export type QueryGetInvoiceByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetLanguageByArgs = {
  where: LanguageWhereUniqueInput;
};

export type QueryGetLanguageByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetLocationByArgs = {
  where: LocationWhereUniqueInput;
};

export type QueryGetLocationByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetMobianPaymentByArgs = {
  where: MobianPaymentWhereUniqueInput;
};

export type QueryGetMobianPaymentByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetOptionCategoryByArgs = {
  where: OptionCategoryWhereUniqueInput;
};

export type QueryGetOptionCategoryByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetOrderByArgs = {
  where: OrderWhereUniqueInput;
};

export type QueryGetOrderByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetParkingByArgs = {
  where: ParkingWhereUniqueInput;
};

export type QueryGetParkingByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetParkingOptionByArgs = {
  where: ParkingOptionWhereUniqueInput;
};

export type QueryGetParkingOptionByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetParkingParkingOptionByArgs = {
  where: ParkingParkingOptionWhereUniqueInput;
};

export type QueryGetParkingParkingOptionByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetPaymentMethodByArgs = {
  where: PaymentMethodWhereUniqueInput;
};

export type QueryGetPaymentMethodByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetPmsProviderByArgs = {
  where: PmsProviderWhereUniqueInput;
};

export type QueryGetPmsProviderByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetPriceModelByArgs = {
  where: PriceModelWhereUniqueInput;
};

export type QueryGetPriceModelByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetPriceStrategyByArgs = {
  where: PriceStrategyWhereUniqueInput;
};

export type QueryGetPriceStrategyByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetPriceTypeByArgs = {
  where: PriceTypeWhereUniqueInput;
};

export type QueryGetPriceTypeByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetProductByArgs = {
  where: ProductWhereUniqueInput;
};

export type QueryGetProductByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetProviderByArgs = {
  where: ProviderWhereUniqueInput;
};

export type QueryGetProviderByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetRedirectByArgs = {
  where: RedirectWhereUniqueInput;
};

export type QueryGetRedirectByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetRoleByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetSafeAndSecureLogoByArgs = {
  where: SafeAndSecureLogoWhereUniqueInput;
};

export type QueryGetSafeAndSecureLogoByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetSectionByArgs = {
  where: SectionWhereUniqueInput;
};

export type QueryGetSectionByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetSectionOptionByArgs = {
  where: SectionOptionWhereUniqueInput;
};

export type QueryGetSectionOptionByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetSectionSectionOptionByArgs = {
  where: SectionSectionOptionWhereUniqueInput;
};

export type QueryGetSectionSectionOptionByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetSectionTypeByArgs = {
  where: SectionTypeWhereUniqueInput;
};

export type QueryGetSectionTypeByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetServiceByArgs = {
  where: ServiceWhereUniqueInput;
};

export type QueryGetServiceByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetServiceSectionByArgs = {
  where: ServiceSectionWhereUniqueInput;
};

export type QueryGetServiceSectionByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetServiceTypeByArgs = {
  where: ServiceTypeWhereUniqueInput;
};

export type QueryGetServiceTypeByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetSiteByArgs = {
  where: SiteWhereUniqueInput;
};

export type QueryGetSiteByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetSiteLocalisationByArgs = {
  where: SiteLocalisationWhereUniqueInput;
};

export type QueryGetSiteLocalisationByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetSitePopularAirportsByArgs = {
  orderBy?: InputMaybe<Array<AirportOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where: SiteWhereUniqueInput;
};

export type QueryGetSocialMediaByArgs = {
  where: SocialMediaWhereUniqueInput;
};

export type QueryGetSocialMediaByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetSubdomainByArgs = {
  where: SubdomainWhereUniqueInput;
};

export type QueryGetSubdomainByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetTerminalByArgs = {
  where: TerminalWhereUniqueInput;
};

export type QueryGetTerminalByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetTestLogByArgs = {
  where: TestLogWhereUniqueInput;
};

export type QueryGetTestLogByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetTrustMarkLogoByArgs = {
  where: TrustMarkLogoWhereUniqueInput;
};

export type QueryGetTrustMarkLogoByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetUserByIdArgs = {
  id: Scalars['ID']['input'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive',
}

export type Redirect = {
  __typename?: 'Redirect';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  fromSite?: Maybe<Site>;
  fromSitePath: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  redirectCode: Scalars['Int']['output'];
  toSite?: Maybe<Site>;
  toSitePath: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type RedirectCreateInput = {
  fromSiteIdConnect: Scalars['ID']['input'];
  fromSitePath: Scalars['String']['input'];
  redirectCode: Scalars['Int']['input'];
  toSiteIdConnect: Scalars['ID']['input'];
  toSitePath: Scalars['String']['input'];
};

export type RedirectListRelationFilter = {
  every?: InputMaybe<RedirectWhereInput>;
  none?: InputMaybe<RedirectWhereInput>;
  some?: InputMaybe<RedirectWhereInput>;
};

export type RedirectOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  fromSite?: InputMaybe<SiteOrderByInput>;
  fromSiteId?: InputMaybe<SortOrder>;
  fromSitePath?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  redirectCode?: InputMaybe<SortOrder>;
  toSite?: InputMaybe<SiteOrderByInput>;
  toSiteId?: InputMaybe<SortOrder>;
  toSitePath?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type RedirectPaginatedResponse = {
  __typename?: 'RedirectPaginatedResponse';
  items: Array<Maybe<Redirect>>;
  pageInfo: PageInfo;
};

export type RedirectUpdateInput = {
  fromSiteIdConnect?: InputMaybe<Scalars['ID']['input']>;
  fromSitePath?: InputMaybe<Scalars['String']['input']>;
  redirectCode?: InputMaybe<Scalars['Int']['input']>;
  toSiteIdConnect?: InputMaybe<Scalars['ID']['input']>;
  toSitePath?: InputMaybe<Scalars['String']['input']>;
};

export type RedirectWhereInput = {
  AND?: InputMaybe<Array<RedirectWhereInput>>;
  NOT?: InputMaybe<Array<RedirectWhereInput>>;
  OR?: InputMaybe<Array<RedirectWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  fromSite?: InputMaybe<SiteWhereInput>;
  fromSiteId?: InputMaybe<StringFilterInput>;
  fromSitePath?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  redirectCode?: InputMaybe<IntFilterInput>;
  toSite?: InputMaybe<SiteWhereInput>;
  toSiteId?: InputMaybe<StringFilterInput>;
  toSitePath?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type RedirectWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type RefreshTokenPayload = {
  __typename?: 'RefreshTokenPayload';
  newAccessToken: Scalars['String']['output'];
  newRefreshToken: Scalars['String']['output'];
};

export type RejectConsentPayload = {
  __typename?: 'RejectConsentPayload';
  redirect_to: Scalars['String']['output'];
};

export type Role = {
  __typename?: 'Role';
  User?: Maybe<Array<User>>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type RoleCreateInput = {
  UserIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type RolePaginatedResponse = {
  __typename?: 'RolePaginatedResponse';
  items: Array<Maybe<Role>>;
  pageInfo: PageInfo;
};

export type RoleUpdateInput = {
  UserIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  UserIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  UserIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type RoleWhereInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SafeAndSecureLogo = {
  __typename?: 'SafeAndSecureLogo';
  alt: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sites?: Maybe<Array<Site>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  url: Scalars['String']['output'];
};

export type SafeAndSecureLogoCreateInput = {
  alt: Scalars['String']['input'];
  name: Scalars['String']['input'];
  siteIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  url: Scalars['String']['input'];
};

export type SafeAndSecureLogoListRelationFilter = {
  every?: InputMaybe<SafeAndSecureLogoWhereInput>;
  none?: InputMaybe<SafeAndSecureLogoWhereInput>;
  some?: InputMaybe<SafeAndSecureLogoWhereInput>;
};

export type SafeAndSecureLogoOrderByInput = {
  alt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type SafeAndSecureLogoPaginatedResponse = {
  __typename?: 'SafeAndSecureLogoPaginatedResponse';
  items: Array<Maybe<SafeAndSecureLogo>>;
  pageInfo: PageInfo;
};

export type SafeAndSecureLogoUpdateInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  siteIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type SafeAndSecureLogoWhereInput = {
  AND?: InputMaybe<Array<SafeAndSecureLogoWhereInput>>;
  NOT?: InputMaybe<Array<SafeAndSecureLogoWhereInput>>;
  OR?: InputMaybe<Array<SafeAndSecureLogoWhereInput>>;
  alt?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  sites?: InputMaybe<SiteListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
  url?: InputMaybe<StringFilterInput>;
};

export type SafeAndSecureLogoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SafeUser = {
  __typename?: 'SafeUser';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  language: Scalars['String']['output'];
  roles?: Maybe<Array<Role>>;
};

export type Section = {
  __typename?: 'Section';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nrOfSpots: Scalars['Int']['output'];
  parkingId: Scalars['String']['output'];
  remarks: Scalars['String']['output'];
  sectionSectionOptions?: Maybe<Array<SectionSectionOption>>;
  typeId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SectionCreateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nrOfSpots: Scalars['Int']['input'];
  parkingId: Scalars['String']['input'];
  remarks: Scalars['String']['input'];
  sectionSectionOptionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  typeId: Scalars['String']['input'];
};

export type SectionListRelationFilter = {
  every?: InputMaybe<SectionWhereInput>;
  none?: InputMaybe<SectionWhereInput>;
  some?: InputMaybe<SectionWhereInput>;
};

export type SectionOption = {
  __typename?: 'SectionOption';
  cancelable: Scalars['String']['output'];
  category?: Maybe<OptionCategory>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  remark: Scalars['String']['output'];
  sectionSectionOptions?: Maybe<Array<SectionSectionOption>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SectionOptionCreateInput = {
  cancelable: Scalars['String']['input'];
  categoryIdConnect: Scalars['ID']['input'];
  icon: Scalars['String']['input'];
  name: Scalars['String']['input'];
  remark: Scalars['String']['input'];
  sectionSectionOptionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SectionOptionListRelationFilter = {
  every?: InputMaybe<SectionOptionWhereInput>;
  none?: InputMaybe<SectionOptionWhereInput>;
  some?: InputMaybe<SectionOptionWhereInput>;
};

export type SectionOptionOrderByInput = {
  cancelable?: InputMaybe<SortOrder>;
  category?: InputMaybe<OptionCategoryOrderByInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  icon?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  optionCategoryId?: InputMaybe<SortOrder>;
  remark?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SectionOptionPaginatedResponse = {
  __typename?: 'SectionOptionPaginatedResponse';
  items: Array<Maybe<SectionOption>>;
  pageInfo: PageInfo;
};

export type SectionOptionUpdateInput = {
  cancelable?: InputMaybe<Scalars['String']['input']>;
  categoryIdConnect?: InputMaybe<Scalars['ID']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  sectionSectionOptionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  sectionSectionOptionIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  sectionSectionOptionIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SectionOptionWhereInput = {
  AND?: InputMaybe<Array<SectionOptionWhereInput>>;
  NOT?: InputMaybe<Array<SectionOptionWhereInput>>;
  OR?: InputMaybe<Array<SectionOptionWhereInput>>;
  cancelable?: InputMaybe<StringFilterInput>;
  category?: InputMaybe<OptionCategoryWhereInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  icon?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  optionCategoryId?: InputMaybe<StringFilterInput>;
  remark?: InputMaybe<StringFilterInput>;
  sectionSectionOptions?: InputMaybe<SectionSectionOptionListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SectionOptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SectionOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  nrOfSpots?: InputMaybe<SortOrder>;
  parkingId?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
  typeId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SectionPaginatedResponse = {
  __typename?: 'SectionPaginatedResponse';
  items: Array<Maybe<Section>>;
  pageInfo: PageInfo;
};

export type SectionSectionOption = {
  __typename?: 'SectionSectionOption';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isFree: Scalars['Boolean']['output'];
  option?: Maybe<SectionOption>;
  price: Scalars['Float']['output'];
  remark: Scalars['String']['output'];
  section?: Maybe<Section>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SectionSectionOptionCreateInput = {
  isFree: Scalars['Boolean']['input'];
  optionIdConnect: Scalars['ID']['input'];
  price: Scalars['Float']['input'];
  remark: Scalars['String']['input'];
  sectionIdConnect: Scalars['ID']['input'];
};

export type SectionSectionOptionListRelationFilter = {
  every?: InputMaybe<SectionSectionOptionWhereInput>;
  none?: InputMaybe<SectionSectionOptionWhereInput>;
  some?: InputMaybe<SectionSectionOptionWhereInput>;
};

export type SectionSectionOptionOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isFree?: InputMaybe<SortOrder>;
  option?: InputMaybe<SectionOptionOrderByInput>;
  price?: InputMaybe<SortOrder>;
  remark?: InputMaybe<SortOrder>;
  section?: InputMaybe<SectionOrderByInput>;
  sectionId?: InputMaybe<SortOrder>;
  sectionOptionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SectionSectionOptionPaginatedResponse = {
  __typename?: 'SectionSectionOptionPaginatedResponse';
  items: Array<Maybe<SectionSectionOption>>;
  pageInfo: PageInfo;
};

export type SectionSectionOptionUpdateInput = {
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  optionIdConnect?: InputMaybe<Scalars['ID']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  remark?: InputMaybe<Scalars['String']['input']>;
  sectionIdConnect?: InputMaybe<Scalars['ID']['input']>;
};

export type SectionSectionOptionWhereInput = {
  AND?: InputMaybe<Array<SectionSectionOptionWhereInput>>;
  NOT?: InputMaybe<Array<SectionSectionOptionWhereInput>>;
  OR?: InputMaybe<Array<SectionSectionOptionWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  option?: InputMaybe<SectionOptionWhereInput>;
  price?: InputMaybe<FloatFilterInput>;
  remark?: InputMaybe<StringFilterInput>;
  section?: InputMaybe<SectionWhereInput>;
  sectionId?: InputMaybe<StringFilterInput>;
  sectionOptionId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SectionSectionOptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SectionType = {
  __typename?: 'SectionType';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SectionTypeCreateInput = {
  icon: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SectionTypeListRelationFilter = {
  every?: InputMaybe<SectionTypeWhereInput>;
  none?: InputMaybe<SectionTypeWhereInput>;
  some?: InputMaybe<SectionTypeWhereInput>;
};

export type SectionTypeOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  icon?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SectionTypePaginatedResponse = {
  __typename?: 'SectionTypePaginatedResponse';
  items: Array<Maybe<SectionType>>;
  pageInfo: PageInfo;
};

export type SectionTypeUpdateInput = {
  icon?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SectionTypeWhereInput = {
  AND?: InputMaybe<Array<SectionTypeWhereInput>>;
  NOT?: InputMaybe<Array<SectionTypeWhereInput>>;
  OR?: InputMaybe<Array<SectionTypeWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  icon?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SectionTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SectionUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  nrOfSpots?: InputMaybe<Scalars['Int']['input']>;
  parkingId?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  sectionSectionOptionIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  sectionSectionOptionIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  sectionSectionOptionIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  typeId?: InputMaybe<Scalars['String']['input']>;
};

export type SectionWhereInput = {
  AND?: InputMaybe<Array<SectionWhereInput>>;
  NOT?: InputMaybe<Array<SectionWhereInput>>;
  OR?: InputMaybe<Array<SectionWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  nrOfSpots?: InputMaybe<IntFilterInput>;
  parkingId?: InputMaybe<StringFilterInput>;
  remarks?: InputMaybe<StringFilterInput>;
  sectionSectionOptions?: InputMaybe<SectionSectionOptionListRelationFilter>;
  typeId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SectionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Service = {
  __typename?: 'Service';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isExternal: Scalars['Boolean']['output'];
  parkingId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
  remarks: Scalars['String']['output'];
  typeId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  vendorId: Scalars['String']['output'];
};

export type ServiceCreateInput = {
  isExternal: Scalars['Boolean']['input'];
  parkingId: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
  remarks: Scalars['String']['input'];
  typeId: Scalars['String']['input'];
  vendorId: Scalars['String']['input'];
};

export type ServiceListRelationFilter = {
  every?: InputMaybe<ServiceWhereInput>;
  none?: InputMaybe<ServiceWhereInput>;
  some?: InputMaybe<ServiceWhereInput>;
};

export type ServiceOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isExternal?: InputMaybe<SortOrder>;
  parkingId?: InputMaybe<SortOrder>;
  providerId?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
  typeId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  vendorId?: InputMaybe<SortOrder>;
};

export type ServicePaginatedResponse = {
  __typename?: 'ServicePaginatedResponse';
  items: Array<Maybe<Service>>;
  pageInfo: PageInfo;
};

export type ServiceSection = {
  __typename?: 'ServiceSection';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  nrOfAvailableSpots: Scalars['Int']['output'];
  sectionId: Scalars['String']['output'];
  serviceId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ServiceSectionCreateInput = {
  nrOfAvailableSpots: Scalars['Int']['input'];
  sectionId: Scalars['String']['input'];
  serviceId: Scalars['String']['input'];
};

export type ServiceSectionListRelationFilter = {
  every?: InputMaybe<ServiceSectionWhereInput>;
  none?: InputMaybe<ServiceSectionWhereInput>;
  some?: InputMaybe<ServiceSectionWhereInput>;
};

export type ServiceSectionOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  nrOfAvailableSpots?: InputMaybe<SortOrder>;
  sectionId?: InputMaybe<SortOrder>;
  serviceId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ServiceSectionPaginatedResponse = {
  __typename?: 'ServiceSectionPaginatedResponse';
  items: Array<Maybe<ServiceSection>>;
  pageInfo: PageInfo;
};

export type ServiceSectionUpdateInput = {
  nrOfAvailableSpots?: InputMaybe<Scalars['Int']['input']>;
  sectionId?: InputMaybe<Scalars['String']['input']>;
  serviceId?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceSectionWhereInput = {
  AND?: InputMaybe<Array<ServiceSectionWhereInput>>;
  NOT?: InputMaybe<Array<ServiceSectionWhereInput>>;
  OR?: InputMaybe<Array<ServiceSectionWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  nrOfAvailableSpots?: InputMaybe<IntFilterInput>;
  sectionId?: InputMaybe<StringFilterInput>;
  serviceId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ServiceSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ServiceType = {
  __typename?: 'ServiceType';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type ServiceTypeCreateInput = {
  icon: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceTypeListRelationFilter = {
  every?: InputMaybe<ServiceTypeWhereInput>;
  none?: InputMaybe<ServiceTypeWhereInput>;
  some?: InputMaybe<ServiceTypeWhereInput>;
};

export type ServiceTypeOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  icon?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ServiceTypePaginatedResponse = {
  __typename?: 'ServiceTypePaginatedResponse';
  items: Array<Maybe<ServiceType>>;
  pageInfo: PageInfo;
};

export type ServiceTypeUpdateInput = {
  icon?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceTypeWhereInput = {
  AND?: InputMaybe<Array<ServiceTypeWhereInput>>;
  NOT?: InputMaybe<Array<ServiceTypeWhereInput>>;
  OR?: InputMaybe<Array<ServiceTypeWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  icon?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type ServiceTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ServiceUpdateInput = {
  isExternal?: InputMaybe<Scalars['Boolean']['input']>;
  parkingId?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['String']['input']>;
};

export type ServiceWhereInput = {
  AND?: InputMaybe<Array<ServiceWhereInput>>;
  NOT?: InputMaybe<Array<ServiceWhereInput>>;
  OR?: InputMaybe<Array<ServiceWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  isExternal?: InputMaybe<Scalars['Boolean']['input']>;
  parkingId?: InputMaybe<StringFilterInput>;
  providerId?: InputMaybe<StringFilterInput>;
  remarks?: InputMaybe<StringFilterInput>;
  typeId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
  vendorId?: InputMaybe<StringFilterInput>;
};

export type ServiceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SignInInput = {
  challenge: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type SignInPayload = {
  __typename?: 'SignInPayload';
  redirect_to?: Maybe<Scalars['String']['output']>;
};

export type SignOutInput = {
  logoutChallenge: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
};

export type SignOutPayload = {
  __typename?: 'SignOutPayload';
  message: Scalars['String']['output'];
  redirect_to: Scalars['String']['output'];
};

export type Site = {
  __typename?: 'Site';
  airports?: Maybe<Array<Airport>>;
  contactPoints?: Maybe<Array<ContactPoint>>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  domain: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  feedbackCompanyClientId?: Maybe<Scalars['String']['output']>;
  feedbackCompanyClientSecret?: Maybe<Scalars['String']['output']>;
  feedbackCompanyForm?: Maybe<Scalars['String']['output']>;
  feedbackCompanyUrl?: Maybe<Scalars['String']['output']>;
  freshSalesUrl?: Maybe<Scalars['String']['output']>;
  googleMeasurementId?: Maybe<Scalars['String']['output']>;
  googleSiteVerification?: Maybe<Scalars['String']['output']>;
  googleTagManagerContainerId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  incomingRedirects?: Maybe<Array<Redirect>>;
  localisation?: Maybe<SiteLocalisation>;
  mascotName?: Maybe<Scalars['String']['output']>;
  mobianFunnel?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  outgoingRedirects?: Maybe<Array<Redirect>>;
  paymentMethods?: Maybe<Array<PaymentMethod>>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneHref?: Maybe<Scalars['String']['output']>;
  safeAndSecureLogos?: Maybe<Array<SafeAndSecureLogo>>;
  siteCountries?: Maybe<Array<SiteSiteCountry>>;
  siteCurrencies?: Maybe<Array<SiteSiteCurrency>>;
  siteLanguages?: Maybe<Array<SiteSiteLanguage>>;
  siteSocialMedias?: Maybe<Array<SiteSiteSocialMedia>>;
  siteSubdomains?: Maybe<Array<SiteSiteSubdomain>>;
  slug: Scalars['String']['output'];
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  termsAndConditionsMobian?: Maybe<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
  trustMarkLogos?: Maybe<Array<TrustMarkLogo>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  whatsAppHref?: Maybe<Scalars['String']['output']>;
  whatsAppNumber?: Maybe<Scalars['String']['output']>;
};

export type SiteCountry = {
  __typename?: 'SiteCountry';
  country?: Maybe<Country>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  site?: Maybe<Site>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteCountryListRelationFilter = {
  every?: InputMaybe<SiteCountryWhereInput>;
  none?: InputMaybe<SiteCountryWhereInput>;
  some?: InputMaybe<SiteCountryWhereInput>;
};

export type SiteCountryWhereInput = {
  AND?: InputMaybe<Array<SiteCountryWhereInput>>;
  NOT?: InputMaybe<Array<SiteCountryWhereInput>>;
  OR?: InputMaybe<Array<SiteCountryWhereInput>>;
  country?: InputMaybe<CountryWhereInput>;
  countryId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  site?: InputMaybe<SiteWhereInput>;
  siteId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SiteCountryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SiteCreateInput = {
  airportIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  contactPointIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  domain: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  feedbackCompanyClientId?: InputMaybe<Scalars['String']['input']>;
  feedbackCompanyClientSecret?: InputMaybe<Scalars['String']['input']>;
  feedbackCompanyForm?: InputMaybe<Scalars['String']['input']>;
  feedbackCompanyUrl?: InputMaybe<Scalars['String']['input']>;
  freshSalesUrl?: InputMaybe<Scalars['String']['input']>;
  googleMeasurementId?: InputMaybe<Scalars['String']['input']>;
  googleSiteVerification?: InputMaybe<Scalars['String']['input']>;
  googleTagManagerContainerId?: InputMaybe<Scalars['String']['input']>;
  incomingRedirectIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  localisationIdConnect?: InputMaybe<Scalars['ID']['input']>;
  mascotName?: InputMaybe<Scalars['String']['input']>;
  mobianFunnel?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  outgoingRedirectIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  paymentMethodIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneHref?: InputMaybe<Scalars['String']['input']>;
  safeAndSecureLogoIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteCountriesCreate?: InputMaybe<Array<SiteSiteCountryCreateInput>>;
  siteCurrenciesCreate?: InputMaybe<Array<SiteSiteCurrencyCreateInput>>;
  siteLanguagesCreate?: InputMaybe<Array<SiteSiteLanguageCreateInput>>;
  siteSocialMediasCreate?: InputMaybe<Array<SiteSiteSocialMediaCreateInput>>;
  siteSubdomainsCreate?: InputMaybe<Array<SiteSiteSubdomainCreateInput>>;
  slug: Scalars['String']['input'];
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  termsAndConditionsMobian?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
  trustMarkLogoIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  whatsAppHref?: InputMaybe<Scalars['String']['input']>;
  whatsAppNumber?: InputMaybe<Scalars['String']['input']>;
};

export type SiteCurrency = {
  __typename?: 'SiteCurrency';
  createdAt: Scalars['Date']['output'];
  currency?: Maybe<Currency>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  site?: Maybe<Site>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteCurrencyListRelationFilter = {
  every?: InputMaybe<SiteCurrencyWhereInput>;
  none?: InputMaybe<SiteCurrencyWhereInput>;
  some?: InputMaybe<SiteCurrencyWhereInput>;
};

export type SiteCurrencyWhereInput = {
  AND?: InputMaybe<Array<SiteCurrencyWhereInput>>;
  NOT?: InputMaybe<Array<SiteCurrencyWhereInput>>;
  OR?: InputMaybe<Array<SiteCurrencyWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  currency?: InputMaybe<CurrencyWhereInput>;
  currencyId?: InputMaybe<StringFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  site?: InputMaybe<SiteWhereInput>;
  siteId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SiteCurrencyWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SiteLanguage = {
  __typename?: 'SiteLanguage';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  language?: Maybe<Language>;
  site?: Maybe<Site>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteLanguageListRelationFilter = {
  every?: InputMaybe<SiteLanguageWhereInput>;
  none?: InputMaybe<SiteLanguageWhereInput>;
  some?: InputMaybe<SiteLanguageWhereInput>;
};

export type SiteLanguageWhereInput = {
  AND?: InputMaybe<Array<SiteLanguageWhereInput>>;
  NOT?: InputMaybe<Array<SiteLanguageWhereInput>>;
  OR?: InputMaybe<Array<SiteLanguageWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<LanguageWhereInput>;
  languageId?: InputMaybe<StringFilterInput>;
  site?: InputMaybe<SiteWhereInput>;
  siteId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SiteLanguageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SiteListRelationFilter = {
  every?: InputMaybe<SiteWhereInput>;
  none?: InputMaybe<SiteWhereInput>;
  some?: InputMaybe<SiteWhereInput>;
};

export type SiteLocalisation = {
  __typename?: 'SiteLocalisation';
  createdAt: Scalars['Date']['output'];
  decimalDelimiter: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  site?: Maybe<Site>;
  symbolBeforePrice: Scalars['Boolean']['output'];
  thousandSeparator: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteLocalisationCreateInput = {
  decimalDelimiter: Scalars['String']['input'];
  siteIdConnect: Scalars['ID']['input'];
  symbolBeforePrice: Scalars['Boolean']['input'];
  thousandSeparator: Scalars['String']['input'];
};

export type SiteLocalisationListRelationFilter = {
  every?: InputMaybe<SiteLocalisationWhereInput>;
  none?: InputMaybe<SiteLocalisationWhereInput>;
  some?: InputMaybe<SiteLocalisationWhereInput>;
};

export type SiteLocalisationOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  decimalDelimiter?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  site?: InputMaybe<SiteOrderByInput>;
  siteId?: InputMaybe<SortOrder>;
  symbolBeforePrice?: InputMaybe<SortOrder>;
  thousandSeparator?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SiteLocalisationPaginatedResponse = {
  __typename?: 'SiteLocalisationPaginatedResponse';
  items: Array<Maybe<SiteLocalisation>>;
  pageInfo: PageInfo;
};

export type SiteLocalisationUpdateInput = {
  decimalDelimiter?: InputMaybe<Scalars['String']['input']>;
  siteIdConnect?: InputMaybe<Scalars['ID']['input']>;
  symbolBeforePrice?: InputMaybe<Scalars['Boolean']['input']>;
  thousandSeparator?: InputMaybe<Scalars['String']['input']>;
};

export type SiteLocalisationWhereInput = {
  AND?: InputMaybe<Array<SiteLocalisationWhereInput>>;
  NOT?: InputMaybe<Array<SiteLocalisationWhereInput>>;
  OR?: InputMaybe<Array<SiteLocalisationWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  decimalDelimiter?: InputMaybe<StringFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  site?: InputMaybe<SiteWhereInput>;
  siteId?: InputMaybe<StringFilterInput>;
  symbolBeforePrice?: InputMaybe<Scalars['Boolean']['input']>;
  thousandSeparator?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SiteLocalisationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type SiteOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  domain?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  feedbackCompanyClientId?: InputMaybe<SortOrder>;
  feedbackCompanyClientSecret?: InputMaybe<SortOrder>;
  feedbackCompanyForm?: InputMaybe<SortOrder>;
  feedbackCompanyUrl?: InputMaybe<SortOrder>;
  freshSalesUrl?: InputMaybe<SortOrder>;
  googleMeasurementId?: InputMaybe<SortOrder>;
  googleSiteVerification?: InputMaybe<SortOrder>;
  googleTagManagerContainerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  localisation?: InputMaybe<SiteLocalisationOrderByInput>;
  mascotName?: InputMaybe<SortOrder>;
  mobianFunnel?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  phoneHref?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  termsAndConditions?: InputMaybe<SortOrder>;
  termsAndConditionsMobian?: InputMaybe<SortOrder>;
  timezone?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  whatsAppHref?: InputMaybe<SortOrder>;
  whatsAppNumber?: InputMaybe<SortOrder>;
};

export type SitePaginatedResponse = {
  __typename?: 'SitePaginatedResponse';
  items: Array<Maybe<Site>>;
  pageInfo: PageInfo;
};

export type SiteSiteCountry = {
  __typename?: 'SiteSiteCountry';
  country?: Maybe<Country>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteSiteCountryCreateInput = {
  countryId: Scalars['ID']['input'];
  isDefault: Scalars['Boolean']['input'];
};

export type SiteSiteCountryDeleteInput = {
  countryId: Scalars['ID']['input'];
};

export type SiteSiteCurrency = {
  __typename?: 'SiteSiteCurrency';
  createdAt: Scalars['Date']['output'];
  currency?: Maybe<Currency>;
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteSiteCurrencyCreateInput = {
  currencyId: Scalars['ID']['input'];
  isDefault: Scalars['Boolean']['input'];
};

export type SiteSiteCurrencyDeleteInput = {
  currencyId: Scalars['ID']['input'];
};

export type SiteSiteLanguage = {
  __typename?: 'SiteSiteLanguage';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  language?: Maybe<Language>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteSiteLanguageCreateInput = {
  isDefault: Scalars['Boolean']['input'];
  languageId: Scalars['ID']['input'];
};

export type SiteSiteLanguageDeleteInput = {
  languageId: Scalars['ID']['input'];
};

export type SiteSiteSocialMedia = {
  __typename?: 'SiteSiteSocialMedia';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  socialMedia?: Maybe<SocialMedia>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  url: Scalars['String']['output'];
};

export type SiteSiteSocialMediaCreateInput = {
  socialMediaId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

export type SiteSiteSocialMediaDeleteInput = {
  socialMediaId: Scalars['ID']['input'];
};

export type SiteSiteSubdomain = {
  __typename?: 'SiteSiteSubdomain';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  logoUrl: Scalars['String']['output'];
  subdomain?: Maybe<Subdomain>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteSiteSubdomainCreateInput = {
  logoUrl: Scalars['String']['input'];
  subdomainId: Scalars['ID']['input'];
};

export type SiteSiteSubdomainDeleteInput = {
  subdomainId: Scalars['ID']['input'];
};

export type SiteSocialMedia = {
  __typename?: 'SiteSocialMedia';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  site?: Maybe<Site>;
  socialMedia?: Maybe<SocialMedia>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  url: Scalars['String']['output'];
};

export type SiteSocialMediaListRelationFilter = {
  every?: InputMaybe<SiteSocialMediaWhereInput>;
  none?: InputMaybe<SiteSocialMediaWhereInput>;
  some?: InputMaybe<SiteSocialMediaWhereInput>;
};

export type SiteSocialMediaWhereInput = {
  AND?: InputMaybe<Array<SiteSocialMediaWhereInput>>;
  NOT?: InputMaybe<Array<SiteSocialMediaWhereInput>>;
  OR?: InputMaybe<Array<SiteSocialMediaWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  site?: InputMaybe<SiteWhereInput>;
  siteId?: InputMaybe<StringFilterInput>;
  socialMedia?: InputMaybe<SocialMediaWhereInput>;
  socialMediaId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
  url?: InputMaybe<StringFilterInput>;
};

export type SiteSocialMediaWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SiteSubdomain = {
  __typename?: 'SiteSubdomain';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  logoUrl: Scalars['String']['output'];
  site?: Maybe<Site>;
  subdomain?: Maybe<Subdomain>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SiteSubdomainListRelationFilter = {
  every?: InputMaybe<SiteSubdomainWhereInput>;
  none?: InputMaybe<SiteSubdomainWhereInput>;
  some?: InputMaybe<SiteSubdomainWhereInput>;
};

export type SiteSubdomainWhereInput = {
  AND?: InputMaybe<Array<SiteSubdomainWhereInput>>;
  NOT?: InputMaybe<Array<SiteSubdomainWhereInput>>;
  OR?: InputMaybe<Array<SiteSubdomainWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  logoUrl?: InputMaybe<StringFilterInput>;
  site?: InputMaybe<SiteWhereInput>;
  siteId?: InputMaybe<StringFilterInput>;
  subdomain?: InputMaybe<SubdomainWhereInput>;
  subdomainId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SiteSubdomainWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SiteUpdateInput = {
  airportIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  airportIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  airportIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  contactPointIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  contactPointIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  contactPointIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  domain?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  feedbackCompanyClientId?: InputMaybe<Scalars['String']['input']>;
  feedbackCompanyClientSecret?: InputMaybe<Scalars['String']['input']>;
  feedbackCompanyForm?: InputMaybe<Scalars['String']['input']>;
  feedbackCompanyUrl?: InputMaybe<Scalars['String']['input']>;
  freshSalesUrl?: InputMaybe<Scalars['String']['input']>;
  googleMeasurementId?: InputMaybe<Scalars['String']['input']>;
  googleSiteVerification?: InputMaybe<Scalars['String']['input']>;
  googleTagManagerContainerId?: InputMaybe<Scalars['String']['input']>;
  incomingRedirectIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  incomingRedirectIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  incomingRedirectIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  localisationIdConnect?: InputMaybe<Scalars['ID']['input']>;
  localisationIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  mascotName?: InputMaybe<Scalars['String']['input']>;
  mobianFunnel?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  outgoingRedirectIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  outgoingRedirectIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  outgoingRedirectIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  paymentMethodIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  paymentMethodIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  paymentMethodIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneHref?: InputMaybe<Scalars['String']['input']>;
  safeAndSecureLogoIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  safeAndSecureLogoIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  safeAndSecureLogoIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteCountriesDelete?: InputMaybe<Array<SiteSiteCountryDeleteInput>>;
  siteCountriesReplace?: InputMaybe<Array<SiteSiteCountryCreateInput>>;
  siteCountriesUpsert?: InputMaybe<Array<SiteSiteCountryCreateInput>>;
  siteCurrenciesDelete?: InputMaybe<Array<SiteSiteCurrencyDeleteInput>>;
  siteCurrenciesReplace?: InputMaybe<Array<SiteSiteCurrencyCreateInput>>;
  siteCurrenciesUpsert?: InputMaybe<Array<SiteSiteCurrencyCreateInput>>;
  siteLanguagesDelete?: InputMaybe<Array<SiteSiteLanguageDeleteInput>>;
  siteLanguagesReplace?: InputMaybe<Array<SiteSiteLanguageCreateInput>>;
  siteLanguagesUpsert?: InputMaybe<Array<SiteSiteLanguageCreateInput>>;
  siteSocialMediasDelete?: InputMaybe<Array<SiteSiteSocialMediaDeleteInput>>;
  siteSocialMediasReplace?: InputMaybe<Array<SiteSiteSocialMediaCreateInput>>;
  siteSocialMediasUpsert?: InputMaybe<Array<SiteSiteSocialMediaCreateInput>>;
  siteSubdomainsDelete?: InputMaybe<Array<SiteSiteSubdomainDeleteInput>>;
  siteSubdomainsReplace?: InputMaybe<Array<SiteSiteSubdomainCreateInput>>;
  siteSubdomainsUpsert?: InputMaybe<Array<SiteSiteSubdomainCreateInput>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  termsAndConditionsMobian?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  trustMarkLogoIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  trustMarkLogoIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  trustMarkLogoIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  whatsAppHref?: InputMaybe<Scalars['String']['input']>;
  whatsAppNumber?: InputMaybe<Scalars['String']['input']>;
};

export type SiteUserRole = {
  __typename?: 'SiteUserRole';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  roleId: Scalars['ID']['output'];
  siteId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  userId: Scalars['ID']['output'];
};

export type SiteWhereInput = {
  AND?: InputMaybe<Array<SiteWhereInput>>;
  NOT?: InputMaybe<Array<SiteWhereInput>>;
  OR?: InputMaybe<Array<SiteWhereInput>>;
  airports?: InputMaybe<AirportListRelationFilter>;
  contactPoints?: InputMaybe<ContactPointListRelationFilter>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  domain?: InputMaybe<StringFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  feedbackCompanyClientId?: InputMaybe<StringFilterInput>;
  feedbackCompanyClientSecret?: InputMaybe<StringFilterInput>;
  feedbackCompanyForm?: InputMaybe<StringFilterInput>;
  feedbackCompanyUrl?: InputMaybe<StringFilterInput>;
  freshSalesUrl?: InputMaybe<StringFilterInput>;
  googleMeasurementId?: InputMaybe<StringFilterInput>;
  googleSiteVerification?: InputMaybe<StringFilterInput>;
  googleTagManagerContainerId?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  incomingRedirects?: InputMaybe<RedirectListRelationFilter>;
  localisation?: InputMaybe<SiteLocalisationWhereInput>;
  mascotName?: InputMaybe<StringFilterInput>;
  mobianFunnel?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  outgoingRedirects?: InputMaybe<RedirectListRelationFilter>;
  paymentMethods?: InputMaybe<PaymentMethodListRelationFilter>;
  phone?: InputMaybe<StringFilterInput>;
  phoneHref?: InputMaybe<StringFilterInput>;
  safeAndSecureLogos?: InputMaybe<SafeAndSecureLogoListRelationFilter>;
  siteCountries?: InputMaybe<SiteCountryListRelationFilter>;
  siteCurrencies?: InputMaybe<SiteCurrencyListRelationFilter>;
  siteLanguages?: InputMaybe<SiteLanguageListRelationFilter>;
  siteSocialMedias?: InputMaybe<SiteSocialMediaListRelationFilter>;
  siteSubdomains?: InputMaybe<SiteSubdomainListRelationFilter>;
  slug?: InputMaybe<StringFilterInput>;
  termsAndConditions?: InputMaybe<StringFilterInput>;
  termsAndConditionsMobian?: InputMaybe<StringFilterInput>;
  timezone?: InputMaybe<StringFilterInput>;
  trustMarkLogos?: InputMaybe<TrustMarkLogoListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
  whatsAppHref?: InputMaybe<StringFilterInput>;
  whatsAppNumber?: InputMaybe<StringFilterInput>;
};

export type SiteWhereUniqueInput = {
  domain?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type SocialMedia = {
  __typename?: 'SocialMedia';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  siteSocialMedias?: Maybe<Array<SocialMediaSiteSocialMedia>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SocialMediaCreateInput = {
  name: Scalars['String']['input'];
  siteSocialMediasCreate?: InputMaybe<Array<SocialMediaSiteSocialMediaCreateInput>>;
};

export type SocialMediaListRelationFilter = {
  every?: InputMaybe<SocialMediaWhereInput>;
  none?: InputMaybe<SocialMediaWhereInput>;
  some?: InputMaybe<SocialMediaWhereInput>;
};

export type SocialMediaOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SocialMediaPaginatedResponse = {
  __typename?: 'SocialMediaPaginatedResponse';
  items: Array<Maybe<SocialMedia>>;
  pageInfo: PageInfo;
};

export type SocialMediaSiteSocialMedia = {
  __typename?: 'SocialMediaSiteSocialMedia';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  site?: Maybe<Site>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  url: Scalars['String']['output'];
};

export type SocialMediaSiteSocialMediaCreateInput = {
  siteId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

export type SocialMediaSiteSocialMediaDeleteInput = {
  siteId: Scalars['ID']['input'];
};

export type SocialMediaUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  siteSocialMediasDelete?: InputMaybe<Array<SocialMediaSiteSocialMediaDeleteInput>>;
  siteSocialMediasReplace?: InputMaybe<Array<SocialMediaSiteSocialMediaCreateInput>>;
  siteSocialMediasUpsert?: InputMaybe<Array<SocialMediaSiteSocialMediaCreateInput>>;
};

export type SocialMediaWhereInput = {
  AND?: InputMaybe<Array<SocialMediaWhereInput>>;
  NOT?: InputMaybe<Array<SocialMediaWhereInput>>;
  OR?: InputMaybe<Array<SocialMediaWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  siteSocialMedias?: InputMaybe<SiteSocialMediaListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SocialMediaWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type StringFilterInput = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<StringFilterInput>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringListFilterInput = {
  equals?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  has?: InputMaybe<Scalars['String']['input']>;
  hasEvery?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Subdomain = {
  __typename?: 'Subdomain';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  siteSubdomains?: Maybe<Array<SubdomainSiteSubdomain>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SubdomainCreateInput = {
  name: Scalars['String']['input'];
  siteSubdomainsCreate?: InputMaybe<Array<SubdomainSiteSubdomainCreateInput>>;
};

export type SubdomainListRelationFilter = {
  every?: InputMaybe<SubdomainWhereInput>;
  none?: InputMaybe<SubdomainWhereInput>;
  some?: InputMaybe<SubdomainWhereInput>;
};

export type SubdomainOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SubdomainPaginatedResponse = {
  __typename?: 'SubdomainPaginatedResponse';
  items: Array<Maybe<Subdomain>>;
  pageInfo: PageInfo;
};

export type SubdomainSiteSubdomain = {
  __typename?: 'SubdomainSiteSubdomain';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  logoUrl: Scalars['String']['output'];
  site?: Maybe<Site>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type SubdomainSiteSubdomainCreateInput = {
  logoUrl: Scalars['String']['input'];
  siteId: Scalars['ID']['input'];
};

export type SubdomainSiteSubdomainDeleteInput = {
  siteId: Scalars['ID']['input'];
};

export type SubdomainUpdateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  siteSubdomainsDelete?: InputMaybe<Array<SubdomainSiteSubdomainDeleteInput>>;
  siteSubdomainsReplace?: InputMaybe<Array<SubdomainSiteSubdomainCreateInput>>;
  siteSubdomainsUpsert?: InputMaybe<Array<SubdomainSiteSubdomainCreateInput>>;
};

export type SubdomainWhereInput = {
  AND?: InputMaybe<Array<SubdomainWhereInput>>;
  NOT?: InputMaybe<Array<SubdomainWhereInput>>;
  OR?: InputMaybe<Array<SubdomainWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  siteSubdomains?: InputMaybe<SiteSubdomainListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type SubdomainWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Terminal = {
  __typename?: 'Terminal';
  airport?: Maybe<Airport>;
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type TerminalCreateInput = {
  airportIdConnect: Scalars['ID']['input'];
  locationIdConnect?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type TerminalListRelationFilter = {
  every?: InputMaybe<TerminalWhereInput>;
  none?: InputMaybe<TerminalWhereInput>;
  some?: InputMaybe<TerminalWhereInput>;
};

export type TerminalOrderByInput = {
  airport?: InputMaybe<AirportOrderByInput>;
  airportId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location?: InputMaybe<LocationOrderByInput>;
  locationId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TerminalPaginatedResponse = {
  __typename?: 'TerminalPaginatedResponse';
  items: Array<Maybe<Terminal>>;
  pageInfo: PageInfo;
};

export type TerminalUpdateInput = {
  airportIdConnect?: InputMaybe<Scalars['ID']['input']>;
  locationIdConnect?: InputMaybe<Scalars['ID']['input']>;
  locationIdDisconnect?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type TerminalWhereInput = {
  AND?: InputMaybe<Array<TerminalWhereInput>>;
  NOT?: InputMaybe<Array<TerminalWhereInput>>;
  OR?: InputMaybe<Array<TerminalWhereInput>>;
  airport?: InputMaybe<AirportWhereInput>;
  airportId?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  location?: InputMaybe<LocationWhereInput>;
  locationId?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateFilterInput>;
};

export type TerminalWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
};

export type TestLog = {
  __typename?: 'TestLog';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  duration: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  output?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  updatedAt: Scalars['Date']['output'];
  userId: Scalars['String']['output'];
};

export type TestLogCreateInput = {
  duration: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  output?: InputMaybe<Scalars['String']['input']>;
  success: Scalars['Boolean']['input'];
  userId: Scalars['String']['input'];
};

export type TestLogListRelationFilter = {
  every?: InputMaybe<TestLogWhereInput>;
  none?: InputMaybe<TestLogWhereInput>;
  some?: InputMaybe<TestLogWhereInput>;
};

export type TestLogOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  output?: InputMaybe<SortOrder>;
  success?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TestLogPaginatedResponse = {
  __typename?: 'TestLogPaginatedResponse';
  items: Array<Maybe<TestLog>>;
  pageInfo: PageInfo;
};

export type TestLogUpdateInput = {
  duration?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  output?: InputMaybe<Scalars['String']['input']>;
  success?: InputMaybe<Scalars['Boolean']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type TestLogWhereInput = {
  AND?: InputMaybe<Array<TestLogWhereInput>>;
  NOT?: InputMaybe<Array<TestLogWhereInput>>;
  OR?: InputMaybe<Array<TestLogWhereInput>>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  duration?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  output?: InputMaybe<StringFilterInput>;
  success?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<DateFilterInput>;
  userId?: InputMaybe<StringFilterInput>;
};

export type TestLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type TrustMarkLogo = {
  __typename?: 'TrustMarkLogo';
  alt: Scalars['String']['output'];
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sites?: Maybe<Array<Site>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  url: Scalars['String']['output'];
};

export type TrustMarkLogoCreateInput = {
  alt: Scalars['String']['input'];
  name: Scalars['String']['input'];
  siteIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  url: Scalars['String']['input'];
};

export type TrustMarkLogoListRelationFilter = {
  every?: InputMaybe<TrustMarkLogoWhereInput>;
  none?: InputMaybe<TrustMarkLogoWhereInput>;
  some?: InputMaybe<TrustMarkLogoWhereInput>;
};

export type TrustMarkLogoOrderByInput = {
  alt?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type TrustMarkLogoPaginatedResponse = {
  __typename?: 'TrustMarkLogoPaginatedResponse';
  items: Array<Maybe<TrustMarkLogo>>;
  pageInfo: PageInfo;
};

export type TrustMarkLogoUpdateInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  siteIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  siteIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type TrustMarkLogoWhereInput = {
  AND?: InputMaybe<Array<TrustMarkLogoWhereInput>>;
  NOT?: InputMaybe<Array<TrustMarkLogoWhereInput>>;
  OR?: InputMaybe<Array<TrustMarkLogoWhereInput>>;
  alt?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateFilterInput>;
  deletedAt?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  sites?: InputMaybe<SiteListRelationFilter>;
  updatedAt?: InputMaybe<DateFilterInput>;
  url?: InputMaybe<StringFilterInput>;
};

export type TrustMarkLogoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['Date']['output'];
  deletedAt?: Maybe<Scalars['Date']['output']>;
  email: Scalars['String']['output'];
  emailChanged: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  language: Scalars['String']['output'];
  password: Scalars['String']['output'];
  roles?: Maybe<Array<Role>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type UserCreateInput = {
  email: Scalars['String']['input'];
  language: Scalars['String']['input'];
  password: Scalars['String']['input'];
  roleIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UserPaginatedResponse = {
  __typename?: 'UserPaginatedResponse';
  items: Array<Maybe<User>>;
  pageInfo: PageInfo;
};

export type UserUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  emailChanged?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  roleIdsConnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  roleIdsDisconnect?: InputMaybe<Array<Scalars['ID']['input']>>;
  roleIdsReplace?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UserWhereInput = {
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  deletedAt?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailChanged?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
};

export type SearchAirportFieldsFragment = {
  __typename?: 'Airport';
  id: string;
  name: string;
  iata: string;
  terminals?: Array<{ __typename?: 'Terminal'; id: string; name: string }> | null;
  location?: {
    __typename?: 'Location';
    id: string;
    lat: number;
    long: number;
    country?: { __typename?: 'Country'; id: string; name: string } | null;
  } | null;
};

export type SearchAirportQueryVariables = Exact<{
  where: SiteWhereUniqueInput;
}>;

export type SearchAirportQuery = {
  __typename?: 'Query';
  getSiteBy?: {
    __typename?: 'Site';
    id: string;
    airports?: Array<{
      __typename?: 'Airport';
      id: string;
      name: string;
      iata: string;
      terminals?: Array<{
        __typename?: 'Terminal';
        id: string;
        name: string;
      }> | null;
      location?: {
        __typename?: 'Location';
        id: string;
        lat: number;
        long: number;
        country?: { __typename?: 'Country'; id: string; name: string } | null;
      } | null;
    }> | null;
  } | null;
};

export type AirportLocationsFieldsFragment = {
  __typename?: 'Airport';
  id: string;
  name: string;
  iata: string;
  location?: {
    __typename?: 'Location';
    id: string;
    lat: number;
    long: number;
    country?: { __typename?: 'Country'; id: string; name: string } | null;
  } | null;
};

export type AirportsLocationQueryVariables = Exact<{
  where: SiteWhereUniqueInput;
}>;

export type AirportsLocationQuery = {
  __typename?: 'Query';
  getSiteBy?: {
    __typename?: 'Site';
    id: string;
    airports?: Array<{
      __typename?: 'Airport';
      id: string;
      name: string;
      iata: string;
      location?: {
        __typename?: 'Location';
        id: string;
        lat: number;
        long: number;
        country?: { __typename?: 'Country'; id: string; name: string } | null;
      } | null;
    }> | null;
  } | null;
};

export type AirportFieldsFragment = {
  __typename?: 'Airport';
  id: string;
  name: string;
  location?: {
    __typename?: 'Location';
    id: string;
    country?: { __typename?: 'Country'; id: string; name: string } | null;
  } | null;
};

export type Top3PopularQueryVariables = Exact<{
  where: SiteWhereUniqueInput;
}>;

export type Top3PopularQuery = {
  __typename?: 'Query';
  getSitePopularAirportsBy: {
    __typename?: 'AirportPaginatedResponse';
    items: Array<{
      __typename?: 'Airport';
      id: string;
      name: string;
      location?: {
        __typename?: 'Location';
        id: string;
        country?: { __typename?: 'Country'; id: string; name: string } | null;
      } | null;
    } | null>;
  };
};

export type ExchangeTokenMutationVariables = Exact<{
  tokenToExchange: ExchangeTokenInput;
}>;

export type ExchangeTokenMutation = {
  __typename?: 'Mutation';
  exchangeToken: {
    __typename?: 'ExchangeTokenPayload';
    refreshToken?: string | null;
    accessToken?: string | null;
    user: {
      __typename?: 'SafeUser';
      id: string;
      email: string;
      language: string;
      roles?: Array<{
        __typename?: 'Role';
        name: string;
        id: string;
        description?: string | null;
      }> | null;
    };
  };
};

export type FetchConsentMutationVariables = Exact<{
  consentToFetch: ConsentInput;
}>;

export type FetchConsentMutation = {
  __typename?: 'Mutation';
  fetchConsent: {
    __typename?: 'FetchConsentPayload';
    requestedScopes: Array<string>;
  };
};

export type RejectConsentMutationVariables = Exact<{
  consentToReject: ConsentInput;
}>;

export type RejectConsentMutation = {
  __typename?: 'Mutation';
  rejectConsent: { __typename?: 'RejectConsentPayload'; redirect_to: string };
};

export type AcceptConsentMutationVariables = Exact<{
  consentToAccept: ConsentInput;
}>;

export type AcceptConsentMutation = {
  __typename?: 'Mutation';
  acceptConsent: { __typename?: 'AcceptConsentPayload'; redirect_to: string };
};

export type SignInMutationVariables = Exact<{
  userToSign: SignInInput;
}>;

export type SignInMutation = {
  __typename?: 'Mutation';
  signIn: { __typename?: 'SignInPayload'; redirect_to?: string | null };
};

export type HeaderAirportsQueryVariables = Exact<{
  where: SiteWhereUniqueInput;
}>;

export type HeaderAirportsQuery = {
  __typename?: 'Query';
  getSiteBy?: {
    __typename?: 'Site';
    id: string;
    airports?: Array<{
      __typename?: 'Airport';
      id: string;
      name: string;
      location?: {
        __typename?: 'Location';
        id: string;
        country?: { __typename?: 'Country'; id: string; name: string } | null;
      } | null;
    }> | null;
  } | null;
};

export type ModalCountryQueryVariables = Exact<{
  where: SiteWhereUniqueInput;
}>;

export type ModalCountryQuery = {
  __typename?: 'Query';
  getSiteBy?: {
    __typename?: 'Site';
    id: string;
    name: string;
    domain: string;
    siteCountries?: Array<{
      __typename?: 'SiteSiteCountry';
      id: string;
      isDefault: boolean;
      country?: {
        __typename?: 'Country';
        id: string;
        name: string;
        iso: string;
      } | null;
    }> | null;
    siteCurrencies?: Array<{
      __typename?: 'SiteSiteCurrency';
      id: string;
      isDefault: boolean;
      currency?: {
        __typename?: 'Currency';
        id: string;
        name: string;
        iso: string;
      } | null;
    }> | null;
    siteLanguages?: Array<{
      __typename?: 'SiteSiteLanguage';
      id: string;
      isDefault: boolean;
      language?: {
        __typename?: 'Language';
        id: string;
        iso: string;
        tag: string;
        name: string;
      } | null;
    }> | null;
  } | null;
};

export type RefreshTokenMutationVariables = Exact<{
  currentRefreshToken: Scalars['String']['input'];
}>;

export type RefreshTokenMutation = {
  __typename?: 'Mutation';
  refreshToken: {
    __typename?: 'RefreshTokenPayload';
    newAccessToken: string;
    newRefreshToken: string;
  };
};

export type SignOutMutationVariables = Exact<{
  signOutParams: SignOutInput;
}>;

export type SignOutMutation = {
  __typename?: 'Mutation';
  signOut: { __typename?: 'SignOutPayload'; redirect_to: string; message: string };
};

export const SearchAirportFieldsFragmentDoc = gql`
  fragment SearchAirportFields on Airport {
    id
    name
    iata
    terminals {
      id
      name
    }
    location {
      id
      lat
      long
      country {
        id
        name
      }
    }
  }
`;
export const AirportLocationsFieldsFragmentDoc = gql`
  fragment AirportLocationsFields on Airport {
    id
    name
    iata
    location {
      id
      lat
      long
      country {
        id
        name
      }
    }
  }
`;
export const AirportFieldsFragmentDoc = gql`
  fragment AirportFields on Airport {
    id
    name
    location {
      id
      country {
        id
        name
      }
    }
  }
`;
export const SearchAirportDocument = gql`
  query SearchAirport($where: SiteWhereUniqueInput!) {
    getSiteBy(where: $where) {
      id
      airports {
        ...SearchAirportFields
      }
    }
  }
  ${SearchAirportFieldsFragmentDoc}
`;

export function useSearchAirportQuery(
  options: Omit<Urql.UseQueryArgs<SearchAirportQueryVariables>, 'query'>
) {
  return Urql.useQuery<SearchAirportQuery, SearchAirportQueryVariables>({
    query: SearchAirportDocument,
    ...options,
  });
}
export const AirportsLocationDocument = gql`
  query AirportsLocation($where: SiteWhereUniqueInput!) {
    getSiteBy(where: $where) {
      id
      airports {
        ...AirportLocationsFields
      }
    }
  }
  ${AirportLocationsFieldsFragmentDoc}
`;

export function useAirportsLocationQuery(
  options: Omit<Urql.UseQueryArgs<AirportsLocationQueryVariables>, 'query'>
) {
  return Urql.useQuery<AirportsLocationQuery, AirportsLocationQueryVariables>({
    query: AirportsLocationDocument,
    ...options,
  });
}
export const Top3PopularDocument = gql`
  query Top3Popular($where: SiteWhereUniqueInput!) {
    getSitePopularAirportsBy(where: $where) {
      items {
        ...AirportFields
      }
    }
  }
  ${AirportFieldsFragmentDoc}
`;

export function useTop3PopularQuery(
  options: Omit<Urql.UseQueryArgs<Top3PopularQueryVariables>, 'query'>
) {
  return Urql.useQuery<Top3PopularQuery, Top3PopularQueryVariables>({
    query: Top3PopularDocument,
    ...options,
  });
}
export const ExchangeTokenDocument = gql`
  mutation ExchangeToken($tokenToExchange: ExchangeTokenInput!) {
    exchangeToken(tokenToExchange: $tokenToExchange) {
      refreshToken
      accessToken
      user {
        id
        email
        language
        roles {
          name
          id
          description
        }
      }
    }
  }
`;

export function useExchangeTokenMutation() {
  return Urql.useMutation<ExchangeTokenMutation, ExchangeTokenMutationVariables>(
    ExchangeTokenDocument
  );
}
export const FetchConsentDocument = gql`
  mutation FetchConsent($consentToFetch: ConsentInput!) {
    fetchConsent(consentToFetch: $consentToFetch) {
      requestedScopes
    }
  }
`;

export function useFetchConsentMutation() {
  return Urql.useMutation<FetchConsentMutation, FetchConsentMutationVariables>(
    FetchConsentDocument
  );
}
export const RejectConsentDocument = gql`
  mutation RejectConsent($consentToReject: ConsentInput!) {
    rejectConsent(consentToReject: $consentToReject) {
      redirect_to
    }
  }
`;

export function useRejectConsentMutation() {
  return Urql.useMutation<RejectConsentMutation, RejectConsentMutationVariables>(
    RejectConsentDocument
  );
}
export const AcceptConsentDocument = gql`
  mutation AcceptConsent($consentToAccept: ConsentInput!) {
    acceptConsent(consentToAccept: $consentToAccept) {
      redirect_to
    }
  }
`;

export function useAcceptConsentMutation() {
  return Urql.useMutation<AcceptConsentMutation, AcceptConsentMutationVariables>(
    AcceptConsentDocument
  );
}
export const SignInDocument = gql`
  mutation SignIn($userToSign: SignInInput!) {
    signIn(userToSign: $userToSign) {
      redirect_to
    }
  }
`;

export function useSignInMutation() {
  return Urql.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument);
}
export const HeaderAirportsDocument = gql`
  query HeaderAirports($where: SiteWhereUniqueInput!) {
    getSiteBy(where: $where) {
      id
      airports {
        ...AirportFields
      }
    }
  }
  ${AirportFieldsFragmentDoc}
`;

export function useHeaderAirportsQuery(
  options: Omit<Urql.UseQueryArgs<HeaderAirportsQueryVariables>, 'query'>
) {
  return Urql.useQuery<HeaderAirportsQuery, HeaderAirportsQueryVariables>({
    query: HeaderAirportsDocument,
    ...options,
  });
}
export const ModalCountryDocument = gql`
  query ModalCountry($where: SiteWhereUniqueInput!) {
    getSiteBy(where: $where) {
      id
      name
      domain
      siteCountries {
        id
        isDefault
        country {
          id
          name
          iso
        }
      }
      siteCurrencies {
        id
        isDefault
        currency {
          id
          name
          iso
        }
      }
      siteLanguages {
        id
        isDefault
        language {
          id
          iso
          tag
          name
        }
      }
    }
  }
`;

export function useModalCountryQuery(
  options: Omit<Urql.UseQueryArgs<ModalCountryQueryVariables>, 'query'>
) {
  return Urql.useQuery<ModalCountryQuery, ModalCountryQueryVariables>({
    query: ModalCountryDocument,
    ...options,
  });
}
export const RefreshTokenDocument = gql`
  mutation RefreshToken($currentRefreshToken: String!) {
    refreshToken(currentRefreshToken: $currentRefreshToken) {
      newAccessToken
      newRefreshToken
    }
  }
`;

export function useRefreshTokenMutation() {
  return Urql.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(
    RefreshTokenDocument
  );
}
export const SignOutDocument = gql`
  mutation SignOut($signOutParams: SignOutInput!) {
    signOut(signOutParams: $signOutParams) {
      redirect_to
      message
    }
  }
`;

export function useSignOutMutation() {
  return Urql.useMutation<SignOutMutation, SignOutMutationVariables>(
    SignOutDocument
  );
}
