export const CompareIcon = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    {...props}
    width="82"
    height="82"
    viewBox="0 0 82 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_12590)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.9121 7C31.3014 7.00039 26.7577 8.10335 22.6599 10.2169C18.5622 12.3304 15.0293 15.3931 12.3561 19.1496C9.68279 22.9061 7.94665 27.2474 7.2925 31.8113C6.63834 36.3752 7.08513 41.0294 8.59559 45.3855C10.1061 49.7416 12.6364 53.6734 15.9755 56.8528C19.3146 60.0321 23.3656 62.3669 27.7906 63.6623C32.2156 64.9577 36.8862 65.1762 41.4128 64.2995C45.9394 63.4228 50.1907 61.4763 53.812 58.6225L66.2351 71.0451C66.8766 71.6648 67.7359 72.0076 68.6278 71.9999C69.5198 71.9921 70.373 71.6344 71.0037 71.0037C71.6344 70.373 71.9921 69.5199 71.9999 68.628C72.0076 67.7361 71.6647 66.8768 71.0451 66.2353L58.622 53.8127C61.9829 49.5492 64.0755 44.4257 64.6604 39.0284C65.2453 33.6312 64.2988 28.1784 61.9293 23.2939C59.5597 18.4095 55.8628 14.2908 51.2617 11.4092C46.6606 8.52768 41.3412 6.99964 35.9121 7ZM13.801 35.9135C13.801 30.0495 16.1305 24.4256 20.2772 20.2791C24.4238 16.1327 30.0479 13.8032 35.9121 13.8032C41.7764 13.8032 47.4005 16.1327 51.5471 20.2791C55.6938 24.4256 58.0233 30.0495 58.0233 35.9135C58.0233 41.7775 55.6938 47.4014 51.5471 51.5479C47.4005 55.6944 41.7764 58.0238 35.9121 58.0238C30.0479 58.0238 24.4238 55.6944 20.2772 51.5479C16.1305 47.4014 13.801 41.7775 13.801 35.9135Z"
        fill="#08AFF5"
      />
      <path
        d="M29 26H37.0769C38.913 26 40.674 26.7726 41.9723 28.1479C43.2706 29.5232 44 31.3884 44 33.3333C44 35.2783 43.2706 37.1435 41.9723 38.5188C40.674 39.894 38.913 40.6667 37.0769 40.6667H31.3077V48H29V26ZM31.3077 28.4444V38.2222H37.0769C38.301 38.2222 39.4749 37.7071 40.3405 36.7903C41.206 35.8735 41.6923 34.6299 41.6923 33.3333C41.6923 32.0367 41.206 30.7932 40.3405 29.8764C39.4749 28.9595 38.301 28.4444 37.0769 28.4444H31.3077Z"
        fill="#0283D1"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_12590">
        <rect width="82" height="82" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
