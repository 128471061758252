'use client';

import React, { useEffect, useState } from 'react';
import { Alert, Modal } from '@ui/components';
import Button from '@ui/components/ui/button/Button';
import { ModalDropdownCountry, type DropdownItem } from './modal-dropdown-country';
import { Icon } from '@icons/icons';
import { setCookie } from 'cookies-next';
import { useTranslations } from 'next-intl';

type ModalCountryProps = {
  isOpen: boolean;
  onClose: () => void;
  countries: Array<DropdownItem>;
  currencies: Array<DropdownItem>;
  languages: Array<DropdownItem>;
  error?: Error | null;
};

export const ModalCountry = ({
  isOpen,
  onClose,
  countries,
  currencies,
  languages,
  error,
}: ModalCountryProps) => {
  const [selectedCountry, setSelectedCountry] = useState<DropdownItem | null>();
  const [selectedCurrency, setSelectedCurrency] = useState<DropdownItem | null>();
  const [selectedLanguage, setSelectedLanguage] = useState<DropdownItem | null>();
  const t = useTranslations('header');

  useEffect(() => {
    if (!selectedCountry && countries.length > 0) {
      setSelectedCountry(
        countries.find(country => country.isDefault) || countries[0]
      );
    }
    if (!selectedCurrency && currencies.length > 0) {
      setSelectedCurrency(
        currencies.find(currency => currency.isDefault) || currencies[0]
      );
    }
    if (!selectedLanguage && languages.length > 0) {
      setSelectedLanguage(
        languages.find(language => language.isDefault) || languages[0]
      );
    }
  }, [
    countries,
    currencies,
    languages,
    selectedCountry,
    selectedCurrency,
    selectedLanguage,
  ]);

  const handleContinue = () => {
    if (selectedLanguage) {
      setCookie('lang', selectedLanguage.tag, {
        path: '/',
        sameSite: 'lax',
      });
    }

    // Reload the page to apply the new locale
    location.reload();
  };

  return (
    <Modal
      title={t('modal_dropdown.title')}
      isOpen={isOpen}
      onClose={onClose}
      rounded="xl3"
      zIndexVariant="high"
    >
      <div className="grid flex-1 gap-2">
        <div className="bg-subtleWarning text-primaryWarning text-sm p-5 rounded-lg flex items-center">
          <span className="mr-2">
            <Icon name="WarningIcon" />
          </span>

          <p className="text-sm font-regular">{t('modal_dropdown.warning')}</p>
        </div>
      </div>

      {error && (
        <Alert variant="error" title="Error">
          <p>{error.message}</p>
        </Alert>
      )}

      {/* Country Dropdown */}
      <div className="space-y-4">
        {/* Country Dropdown */}
        <ModalDropdownCountry
          title={t('modal_dropdown.country')}
          selectedItem={selectedCountry!}
          items={countries}
          onItemSelect={setSelectedCountry}
        />
        {/* Currency Dropdown */}
        <ModalDropdownCountry
          title={t('modal_dropdown.currency')}
          selectedItem={selectedCurrency!}
          items={currencies}
          onItemSelect={setSelectedCurrency}
          isDisabled={currencies.length === 0}
        />
        {/* Language Dropdown */}
        <ModalDropdownCountry
          title={t('modal_dropdown.language')}
          selectedItem={selectedLanguage!}
          items={languages}
          onItemSelect={setSelectedLanguage}
        />
        <div>
          <div className="flex justify-center">
            <Button
              size="large"
              radius="fullRounded"
              type="submit"
              onClick={handleContinue}
            >
              {t('modal_dropdown.continue_button')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
