export const KeyIcon = (props: Omit<React.ComponentProps<'svg'>, 'children'>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83301 11.6667C5.37467 11.6667 4.98231 11.5035 4.65592 11.1771C4.32954 10.8507 4.16634 10.4583 4.16634 10C4.16634 9.54167 4.32954 9.14931 4.65592 8.82292C4.98231 8.49653 5.37467 8.33333 5.83301 8.33333C6.29134 8.33333 6.6837 8.49653 7.01009 8.82292C7.33648 9.14931 7.49967 9.54167 7.49967 10C7.49967 10.4583 7.33648 10.8507 7.01009 11.1771C6.6837 11.5035 6.29134 11.6667 5.83301 11.6667ZM5.83301 15C4.44412 15 3.26356 14.5139 2.29134 13.5417C1.31912 12.5694 0.833008 11.3889 0.833008 10C0.833008 8.61111 1.31912 7.43056 2.29134 6.45833C3.26356 5.48611 4.44412 5 5.83301 5C6.76356 5 7.60731 5.22917 8.36426 5.6875C9.1212 6.14583 9.7219 6.75 10.1663 7.5H17.1455C17.2566 7.5 17.3643 7.52083 17.4684 7.5625C17.5726 7.60417 17.6663 7.66667 17.7497 7.75L19.4163 9.41667C19.4997 9.5 19.5587 9.59028 19.5934 9.6875C19.6281 9.78472 19.6455 9.88889 19.6455 10C19.6455 10.1111 19.6281 10.2153 19.5934 10.3125C19.5587 10.4097 19.4997 10.5 19.4163 10.5833L16.7705 13.2292C16.7011 13.2986 16.6177 13.3542 16.5205 13.3958C16.4233 13.4375 16.3261 13.4653 16.2288 13.4792C16.1316 13.4931 16.0344 13.4861 15.9372 13.4583C15.84 13.4306 15.7497 13.3819 15.6663 13.3125L14.583 12.5L13.3955 13.3958C13.3261 13.4514 13.2497 13.4931 13.1663 13.5208C13.083 13.5486 12.9997 13.5625 12.9163 13.5625C12.833 13.5625 12.7462 13.5486 12.6559 13.5208C12.5656 13.4931 12.4858 13.4514 12.4163 13.3958L11.1455 12.5H10.1663C9.7219 13.25 9.1212 13.8542 8.36426 14.3125C7.60731 14.7708 6.76356 15 5.83301 15ZM5.83301 13.3333C6.61079 13.3333 7.29481 13.0972 7.88509 12.625C8.47537 12.1528 8.86773 11.5556 9.06217 10.8333H11.6663L12.8747 11.6875L14.583 10.4167L16.0622 11.5625L17.6247 10L16.7913 9.16667H9.06217C8.86773 8.44444 8.47537 7.84722 7.88509 7.375C7.29481 6.90278 6.61079 6.66667 5.83301 6.66667C4.91634 6.66667 4.13162 6.99306 3.47884 7.64583C2.82606 8.29861 2.49967 9.08333 2.49967 10C2.49967 10.9167 2.82606 11.7014 3.47884 12.3542C4.13162 13.0069 4.91634 13.3333 5.83301 13.3333Z"
      fill="#333333"
    />
  </svg>
);
