'use client';

import React, { useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@ui/components';
import { Icon } from '@icons/icons';

export type DropdownItem = {
  label: string;
  img: JSX.Element;
  tag: string;
  isDefault: boolean;
};

type CustomDropdownProps = {
  title: string;
  selectedItem?: DropdownItem;
  items: Array<DropdownItem>;
  onItemSelect: (item: DropdownItem) => void;
  isDisabled?: boolean;
};

export const ModalDropdownCountry: React.FC<CustomDropdownProps> = ({
  title,
  selectedItem,
  items,
  onItemSelect,
  isDisabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownMenu onOpenChange={open => setIsOpen(open)}>
      <DropdownMenuTrigger asChild disabled={isDisabled}>
        <div
          className={`relative flex items-center justify-between p-2 pr-5 rounded-full cursor-pointer w-full
            ${isDisabled ? 'bg-inputFieldDefaultBackground text-disabledText cursor-not-allowed' : 'bg-inputFieldDefaultBackground'}`}
        >
          <div className="flex items-center px-3 gap-2">
            <span
              className="min-w-[32px]"
              role="img"
              aria-label={`Icon of ${selectedItem?.label}`}
            >
              {selectedItem?.img}
            </span>
            <div>
              <div
                className={`text-base-desktop font-normal ${isDisabled ? 'text-disabledText' : 'text-eyebrowText'}`}
              >
                {title}
              </div>
              <div
                className={`text-lg-desktop font-normal ${isDisabled ? 'text-disabledText' : 'text-primaryText'}`}
              >
                {selectedItem?.label || 'Select an option'}
              </div>
            </div>
          </div>
          {isDisabled ? (
            <div className="w-6">
              <Icon name="KeyboardArrowDownRoundedIcon" />
            </div>
          ) : (
            <div className="w-6">
              {isOpen ? (
                <Icon name="KeyboardArrowUpRoundedIcon" />
              ) : (
                <Icon name="KeyboardArrowDownRoundedIcon" />
              )}
            </div>
          )}
        </div>
      </DropdownMenuTrigger>
      {!isDisabled && (
        // eslint-disable-next-line react/forbid-component-props
        <DropdownMenuContent className="w-screen lg:w-auto lg:min-w-[var(--radix-dropdown-menu-trigger-width)] bg-inputFieldDefaultBackground rounded-radius400 shadow-shadowM3Two p-5 mt-1">
          <div className="grid grid-cols-2 gap-1">
            {items.map(item => (
              <div key={item.label}>
                <DropdownMenuItem
                  // eslint-disable-next-line react/forbid-component-props
                  className="flex items-center gap-2 hover:bg-gray-200 rounded-md cursor-pointer w-full"
                  onSelect={() => onItemSelect(item)}
                >
                  <span className="min-w-[32px]">{item.img}</span>
                  <div className="text-base-desktop font-regular">{item.label}</div>
                </DropdownMenuItem>
              </div>
            ))}
          </div>
        </DropdownMenuContent>
      )}
    </DropdownMenu>
  );
};
