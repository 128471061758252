import React, { createContext, useContext, useState, useEffect } from 'react';
import { type User } from '../../graphql/generated/graphql';

import {
  getItemFromCookies,
  removeItemFromCookies,
  setItemInCookies,
} from '../helpers/cookies-helper';

// I have to define this type because of the following error:
// Type 'User' is missing the following properties from type 'safeUser': createdAt, emailChanged because they are required
// on the other hand, in the token exchnage mutation, the user is returned as a safeUser type
type safeUser = {
  id: string;
  email: string;
  language: string;
};

interface AuthContextType {
  isLoggedIn: boolean;
  user: safeUser | undefined;
  loading: boolean;
  refreshToken: string | undefined;
  accessToken: string | undefined;
  login: (userData: safeUser, accessToken: string, refreshToken: string) => void;
  finalizeLogout: (logoutChallenge: string) => void;
}

const defaultAuthContext: AuthContextType = {
  isLoggedIn: false,
  user: undefined,
  loading: true,
  login: () => {},
  finalizeLogout: () => {},
  refreshToken: undefined,
  accessToken: undefined,
};

const AuthContext = createContext<AuthContextType>(defaultAuthContext);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<safeUser | undefined>();
  const [refreshToken, setRefreshToken] = useState<string | undefined>();
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // Ensure we are in a browser environment before accessing cookies
    if (typeof globalThis !== 'undefined') {
      const accessToken = getItemFromCookies('accessToken');
      const refreshToken = getItemFromCookies('refreshToken');
      const userData = getItemFromCookies('user');

      if (userData && accessToken) {
        const user: safeUser = JSON.parse(userData) as safeUser;
        setUser(user);
        setIsLoggedIn(true);
        setAccessToken(accessToken);
      }
      setRefreshToken(refreshToken);
      setLoading(false);
    }
  }, []);

  const login = (userData: safeUser, accessToken: string, refreshToken: string) => {
    setIsLoggedIn(true);
    setUser(userData);
    setAccessToken(accessToken);

    try {
      setItemInCookies('user', JSON.stringify(userData));
      setItemInCookies('accessToken', accessToken);
      setItemInCookies('refreshToken', refreshToken);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error saving to local storage:', error);
    }
  };

  const finalizeLogout = async (logoutChallenge: string) => {
    if (!logoutChallenge) {
      // eslint-disable-next-line no-console
      console.error('Logout Challenge not found');
      return;
    }

    try {
      setAccessToken(undefined);
      setUser(undefined);
      removeItemFromCookies('accessToken');
      removeItemFromCookies('refreshToken');
      removeItemFromCookies('user');
    } catch (error) {
      return error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        user,
        loading,
        login,
        finalizeLogout,
        refreshToken,
        accessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
