export const WarningIcon = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.72503 18C1.5417 18 1.37503 17.9542 1.22503 17.8625C1.07503 17.7708 0.958367 17.65 0.875034 17.5C0.7917 17.35 0.745867 17.1875 0.737534 17.0125C0.7292 16.8375 0.775034 16.6667 0.875034 16.5L10.125 0.5C10.225 0.333333 10.3542 0.208333 10.5125 0.125C10.6709 0.0416667 10.8334 0 11 0C11.1667 0 11.3292 0.0416667 11.4875 0.125C11.6459 0.208333 11.775 0.333333 11.875 0.5L21.125 16.5C21.225 16.6667 21.2709 16.8375 21.2625 17.0125C21.2542 17.1875 21.2084 17.35 21.125 17.5C21.0417 17.65 20.925 17.7708 20.775 17.8625C20.625 17.9542 20.4584 18 20.275 18H1.72503ZM3.45003 16H18.55L11 3L3.45003 16ZM11 15C11.2834 15 11.5209 14.9042 11.7125 14.7125C11.9042 14.5208 12 14.2833 12 14C12 13.7167 11.9042 13.4792 11.7125 13.2875C11.5209 13.0958 11.2834 13 11 13C10.7167 13 10.4792 13.0958 10.2875 13.2875C10.0959 13.4792 10 13.7167 10 14C10 14.2833 10.0959 14.5208 10.2875 14.7125C10.4792 14.9042 10.7167 15 11 15ZM11 12C11.2834 12 11.5209 11.9042 11.7125 11.7125C11.9042 11.5208 12 11.2833 12 11V8C12 7.71667 11.9042 7.47917 11.7125 7.2875C11.5209 7.09583 11.2834 7 11 7C10.7167 7 10.4792 7.09583 10.2875 7.2875C10.0959 7.47917 10 7.71667 10 8V11C10 11.2833 10.0959 11.5208 10.2875 11.7125C10.4792 11.9042 10.7167 12 11 12Z"
      fill="currentColor"
    />
  </svg>
);
