export const ImagesIconInverted = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 19C2.45 19 1.97917 18.8042 1.5875 18.4125C1.19583 18.0208 1 17.55 1 17V7C1 6.45 1.19583 5.97917 1.5875 5.5875C1.97917 5.19583 2.45 5 3 5H13C13.55 5 14.0208 5.19583 14.4125 5.5875C14.8042 5.97917 15 6.45 15 7V17C15 17.55 14.8042 18.0208 14.4125 18.4125C14.0208 18.8042 13.55 19 13 19H3ZM3 17H13V7H3V17ZM7.5 14L6.5 12.675C6.4 12.5417 6.26667 12.4792 6.1 12.4875C5.93333 12.4958 5.8 12.5667 5.7 12.7L4.575 14.2C4.44167 14.3667 4.42917 14.5417 4.5375 14.725C4.64583 14.9083 4.8 15 5 15H11C11.2 15 11.35 14.9083 11.45 14.725C11.55 14.5417 11.5333 14.3667 11.4 14.2L9.8 12.025C9.7 11.8917 9.56667 11.825 9.4 11.825C9.23333 11.825 9.1 11.8917 9 12.025L7.5 14ZM18 19C17.7167 19 17.4792 18.9042 17.2875 18.7125C17.0958 18.5208 17 18.2833 17 18V6C17 5.71667 17.0958 5.47917 17.2875 5.2875C17.4792 5.09583 17.7167 5 18 5C18.2833 5 18.5208 5.09583 18.7125 5.2875C18.9042 5.47917 19 5.71667 19 6V18C19 18.2833 18.9042 18.5208 18.7125 18.7125C18.5208 18.9042 18.2833 19 18 19ZM22 19C21.7167 19 21.4792 18.9042 21.2875 18.7125C21.0958 18.5208 21 18.2833 21 18V6C21 5.71667 21.0958 5.47917 21.2875 5.2875C21.4792 5.09583 21.7167 5 22 5C22.2833 5 22.5208 5.09583 22.7125 5.2875C22.9042 5.47917 23 5.71667 23 6V18C23 18.2833 22.9042 18.5208 22.7125 18.7125C22.5208 18.9042 22.2833 19 22 19Z"
      fill="#1C1B1F"
    />
  </svg>
);
