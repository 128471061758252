export const SortByIcon = (props: Omit<React.ComponentProps<'svg'>, 'children'>) => (
  <svg
    {...props}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="sortByIcon">
      <path
        id="Vector"
        d="M6.74961 9.7501C6.53711 9.7501 6.35898 9.67822 6.21523 9.53447C6.07148 9.39072 5.99961 9.2126 5.99961 9.0001V4.36885L4.59336 5.7751C4.45586 5.9126 4.28398 5.98135 4.07773 5.98135C3.87148 5.98135 3.69336 5.9126 3.54336 5.7751C3.39336 5.6251 3.31836 5.44697 3.31836 5.24072C3.31836 5.03447 3.39336 4.85635 3.54336 4.70635L6.22461 2.0251C6.29961 1.9501 6.38086 1.89697 6.46836 1.86572C6.55586 1.83447 6.64961 1.81885 6.74961 1.81885C6.84961 1.81885 6.94336 1.83447 7.03086 1.86572C7.11836 1.89697 7.19961 1.9501 7.27461 2.0251L9.97461 4.7251C10.1246 4.8751 10.1965 5.0501 10.1902 5.2501C10.184 5.4501 10.1059 5.6251 9.95586 5.7751C9.80586 5.9126 9.63086 5.98447 9.43086 5.99072C9.23086 5.99697 9.05586 5.9251 8.90586 5.7751L7.49961 4.36885V9.0001C7.49961 9.2126 7.42773 9.39072 7.28398 9.53447C7.14023 9.67822 6.96211 9.7501 6.74961 9.7501ZM11.2496 16.1813C11.1496 16.1813 11.0559 16.1657 10.9684 16.1345C10.8809 16.1032 10.7996 16.0501 10.7246 15.9751L8.02461 13.2751C7.87461 13.1251 7.80273 12.9501 7.80898 12.7501C7.81523 12.5501 7.89336 12.3751 8.04336 12.2251C8.19336 12.0876 8.36836 12.0157 8.56836 12.0095C8.76836 12.0032 8.94336 12.0751 9.09336 12.2251L10.4996 13.6313V9.0001C10.4996 8.7876 10.5715 8.60947 10.7152 8.46572C10.859 8.32197 11.0371 8.2501 11.2496 8.2501C11.4621 8.2501 11.6402 8.32197 11.784 8.46572C11.9277 8.60947 11.9996 8.7876 11.9996 9.0001V13.6313L13.4059 12.2251C13.5434 12.0876 13.7152 12.0188 13.9215 12.0188C14.1277 12.0188 14.3059 12.0876 14.4559 12.2251C14.6059 12.3751 14.6809 12.5532 14.6809 12.7595C14.6809 12.9657 14.6059 13.1438 14.4559 13.2938L11.7746 15.9751C11.6996 16.0501 11.6184 16.1032 11.5309 16.1345C11.4434 16.1657 11.3496 16.1813 11.2496 16.1813Z"
        fill="#1C1B1F"
      />
    </g>
  </svg>
);
