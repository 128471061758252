'use client';
import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
} from '../alert-dialog';

type ModalPropsType = {
  onClose?: () => void;
  children: React.ReactNode;
  title?: string;
  isOpen?: boolean;
  rounded?: 'small' | 'md' | 'large' | 'extraLarge' | 'xl2' | 'xl3' | 'xl4';
  variant?: 'default' | 'lightDark';
  zIndexVariant?: 'none' | 'low' | 'medium' | 'high';
};

export const Modal = ({
  onClose,
  children,
  title,
  isOpen,
  rounded = 'md',
  variant = 'default',
  zIndexVariant = 'medium',
}: ModalPropsType) => {
  return (
    <Dialog onOpenChange={onClose} open={isOpen}>
      <DialogOverlay variant={variant} zIndexVariant={zIndexVariant} />
      <DialogContent rounded={rounded}>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        {children}
      </DialogContent>
    </Dialog>
  );
};
