import React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { Icon } from '@icons/icons';
import { cn } from '../../../lib/utils';

const Select = SelectPrimitive.Root;
const SelectGroup = SelectPrimitive.Group;
const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
    variant?: 'default' | 'outlined' | 'filled';
    size?: 'small' | 'medium' | 'large';
    iconLeft?: JSX.Element;
    iconRight?: JSX.Element;
    fullRound?: boolean;
    fullWidth?: boolean;
  }
>(
  (
    {
      className,
      children,
      variant = 'default',
      size = 'medium',
      iconLeft,
      iconRight,
      fullRound = false,
      fullWidth = false,
      ...props
    },
    ref
  ) => {
    const variantClasses = {
      default: 'border border-input bg-inputFieldDefaultBackground',
      outlined: 'border border-gray-300 bg-white text-black',
      filled: 'bg-secondaryBackground text-white',
    };

    const sizeClasses = {
      small: 'h-8 px-2 py-1 text-xs',
      medium: 'px-2 py-3 text-sm',
      large: 'h-14 md:h-16 px-2 py-4 text-lg',
    };

    const fullRoundClass = fullRound ? 'rounded-full' : 'rounded-md';
    const fullWidthClass = fullWidth ? 'w-full' : '';

    return (
      <SelectPrimitive.Trigger
        ref={ref}
        className={cn(
          'flex items-center justify-between group',
          variantClasses[variant],
          sizeClasses[size],
          fullRoundClass,
          fullWidthClass,
          className
        )}
        {...props}
      >
        {iconLeft && <span className="mx-1.5 ">{iconLeft}</span>}
        <div className="flex-1 text-left text-sm">{children}</div>
        {iconRight ? (
          <span>{iconRight}</span>
        ) : (
          <SelectPrimitive.Icon asChild>
            <Icon
              name="KeyboardArrowDownRoundedIcon"
              className="transition duration-300 group-data-[state=open]:rotate-180"
              aria-hidden="true"
            />
          </SelectPrimitive.Icon>
        )}
      </SelectPrimitive.Trigger>
    );
  }
);
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> & {
    position?: 'popper';
    title?: string;
    variant?: 'default' | 'flex';
    rounded?: 'none' | 'small' | 'medium' | 'large' | 'full' | 'bottom';
    marginTop?: number;
  }
>(
  (
    {
      className,
      children,
      position = 'popper',
      title,
      variant = 'default',
      rounded = 'medium',
      marginTop = 0.25,
      ...props
    },
    ref
  ) => {
    const roundedClasses = {
      none: 'rounded-none',
      small: 'rounded-xl',
      medium: 'rounded-2xl',
      large: 'rounded-3xl',
      full: 'rounded-full',
      bottom: 'rounded-b-2xl rounded-t-md',
    };

    const variantClasses = {
      default: 'flex flex-col gap-2 shadow-xl w-[var(--radix-select-trigger-width)]', //match with the width of the trigger button
      flex: 'flex flex-wrap gap-2 shadow-xl p-6 max-w-xs',
    };

    return (
      <SelectPrimitive.Portal>
        <SelectPrimitive.Content
          ref={ref}
          className={cn(
            'relative z-50 max-h-96 overflow-hidden border bg-popover text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 shadow-[0_8px_30px_rgb(0,0,0,0.12)]',
            roundedClasses[rounded],
            position === 'popper' && 'translate-x-0 translate-y-0 w-full',
            className
          )}
          style={{
            marginTop: `${marginTop}rem`,
          }}
          position={position}
          {...props}
        >
          {title && (
            <div className="text-xl text-primaryText font-regular px-6 pt-4">
              {title}
            </div>
          )}
          <SelectScrollUpButton />
          <SelectPrimitive.Viewport className={variantClasses[variant]}>
            {children}
          </SelectPrimitive.Viewport>
          <SelectScrollDownButton />
        </SelectPrimitive.Content>
      </SelectPrimitive.Portal>
    );
  }
);
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & {
    iconName?: JSX.Element;
    variant?: 'default' | 'outlined';
  }
>(({ className, children, iconName, variant = 'default', ...props }, ref) => {
  const variantClasses = {
    default:
      'pl-4 hover:pl-[1rem] border-l-2 border-transparent hover:border-primaryColour text-black',
    outlined:
      'border-2 border-secondaryBtnBorder text-eyebrowText font-mediumitems-center justify-center rounded-full px-4 py-2',
  };

  return (
    <SelectPrimitive.Item
      ref={ref}
      className={cn(
        'inline-flex px-4 py-2 text-sm outline-none cursor-default select-none hover:bg-gray-200 focus:bg-accent focus:text-accent-foreground aria-selected:bg-blue-500 aria-selected:text-white',
        variantClasses[variant],
        className
      )}
      {...props}
    >
      {iconName && <span className="mr-2">{iconName}</span>}
      {children}
    </SelectPrimitive.Item>
  );
});
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    ref={ref}
    className={cn('flex cursor-default items-center justify-center py-1', className)}
    {...props}
  >
    <Icon name="KeyboardArrowUpRoundedIcon" size={5} />
  </SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    ref={ref}
    className={cn('flex cursor-default items-center justify-center py-1', className)}
    {...props}
  >
    <Icon name="KeyboardArrowDownRoundedIcon" size={5} />
  </SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn('py-1.5 pl-8 pr-2 text-sm font-semibold', className)}
    {...props}
  />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn('-mx-1 my-1 h-px bg-muted', className)}
    {...props}
  />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectScrollUpButton,
  SelectScrollDownButton,
};
