'use client';
import React, { useState, useMemo, Suspense } from 'react';
import { Icon, IconName } from '@icons/icons';
import Button from '@ui/components/ui/button/Button';
import {
  NavigationHeader,
  NavigationItem,
} from './navigation-header/navigation-header';
import { Container } from '@ui/components';
import { ModalCountry } from '../modal-country/modal-country';
import {
  type AirportFieldsFragment,
  useHeaderAirportsQuery,
  useModalCountryQuery,
} from '../../../graphql/generated/graphql';
import { HeaderMobile } from './header-mobile';
import { useTranslations } from 'next-intl';

export const SiteHeader = () => {
  const t = useTranslations('header');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleGlobeClick = () => {
    setIsModalOpen(!isModalOpen);
  };
  // Fetch data for Header
  const whereFilter = { domain: 'vliegenenparkeren.nl' };
  const [{ data: navigationData }] = useHeaderAirportsQuery({
    variables: { where: whereFilter },
  });

  // Data fetching for modal
  const [{ data: modalData, error: modalError }] = useModalCountryQuery({
    variables: { where: whereFilter },
  });

  // Transform data to fit the navigationLinks structure
  const navigationLinks: Array<NavigationItem> = useMemo(() => {
    // Transform airport data into the required structure for 'Airports'
    if (!navigationData?.getSiteBy?.airports) return [];

    // Directly use airports if they already match the type since we use generated type from codegen
    const countryLinks = navigationData.getSiteBy
      .airports as Array<AirportFieldsFragment>;

    return [
      {
        label: t('airports'),
        items: countryLinks,
      },
      {
        label: t('type_of_parking'),
        items: [
          { label: 'Shuttle', icon: 'AirportShuttleOutlinedIcon', link: '#' },
          { label: 'Valet', icon: 'ValetIcon', link: '#' },
          { label: 'Within walking distance', icon: 'WalkingIcon', link: '#' },
          { label: 'Park, sleep & fly', icon: 'HotelOutlinedIcon', link: '#' },
        ],
      },
      { label: t('blog'), link: '#' },
      {
        label: t('help'),
        items: [
          { label: t('frequently_asked_questions'), link: '#' },
          { label: t('contact'), link: '#' },
        ],
      },
    ] as Array<NavigationItem>;
  }, [navigationData, t]);

  // ModalCountry Data
  const countries = useMemo(() => {
    if (!modalData?.getSiteBy?.siteCountries) return [];

    return modalData.getSiteBy.siteCountries
      .filter(
        country => country.country && country.country.name && country.country.iso
      ) // Ensure valid data
      .map(country => ({
        label: country.country!.name,
        img: <span>{country.country!.iso}</span>,
        tag: country.country?.iso || '',
        isDefault: country.isDefault || false,
      }));
  }, [modalData]);

  const currencies = useMemo(() => {
    if (!modalData?.getSiteBy?.siteCurrencies) return [];

    return modalData.getSiteBy.siteCurrencies
      .filter(
        currency =>
          currency.currency && currency.currency.name && currency.currency.iso
      ) // Ensure valid data
      .map(currency => ({
        label: currency.currency!.name,
        img: <span>{currency.currency!.iso}</span>,
        tag: currency.currency?.iso || '',
        isDefault: currency.isDefault || false,
      }));
  }, [modalData]);

  const languages = useMemo(() => {
    if (!modalData?.getSiteBy?.siteLanguages) return [];

    return modalData.getSiteBy.siteLanguages
      .filter(
        language =>
          language.language && language.language.name && language.language.iso
      ) // Ensure valid data
      .map(language => ({
        label: language.language!.name,
        img: <span>{language.language!.iso}</span>,
        tag: language.language?.tag || '',
        isDefault: language.isDefault || false,
      }));
  }, [modalData]);

  return (
    <header className="sticky top-0 z-50">
      <div className="bg-primaryColour sticky top-0 w-full flex flex-col">
        <Container marginY={5}>
          <div className="flex justify-between items-center">
            <Icon name="VnpLogo" size={14} />
            <div className="hidden lg:flex space-x-1">
              <Suspense>
                <NavigationHeader navigationLinks={navigationLinks} />
              </Suspense>
              <div className="pl-12 flex items-center gap-4 text-base">
                <Button
                  leftIcon="AccountIcon"
                  iconSize={6}
                  variant="outline"
                  iconColor="inherit"
                >
                  {t('my_booking')}
                </Button>
                <div onClick={handleGlobeClick} className="cursor-pointer">
                  <Icon name="LanguageOutlinedIcon" size={8} iconColor="light" />
                </div>
              </div>
            </div>
            <div className="flex items-center gap-6 lg:hidden">
              <HeaderMobile
                navigationLinks={navigationLinks}
                countries={countries}
                currencies={currencies}
                languages={languages}
              />
            </div>
          </div>
        </Container>
      </div>
      <div className="relative z-50">
        {isModalOpen && (
          <Suspense>
            <ModalCountry
              isOpen={isModalOpen}
              onClose={handleGlobeClick}
              countries={countries}
              currencies={currencies}
              languages={languages}
            />
          </Suspense>
        )}
      </div>
    </header>
  );
};
