export const RatingsIcon = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 12.475L13.9 13.625C14.0833 13.7417 14.2667 13.7375 14.45 13.6125C14.6333 13.4875 14.7 13.3167 14.65 13.1L14.15 10.925L15.85 9.45C16.0167 9.3 16.0667 9.12083 16 8.9125C15.9333 8.70417 15.7833 8.59167 15.55 8.575L13.325 8.4L12.45 6.35C12.3667 6.15 12.2167 6.05 12 6.05C11.7833 6.05 11.6333 6.15 11.55 6.35L10.675 8.4L8.45 8.575C8.21667 8.59167 8.06667 8.70417 8 8.9125C7.93333 9.12083 7.98333 9.3 8.15 9.45L9.85 10.925L9.35 13.1C9.3 13.3167 9.36667 13.4875 9.55 13.6125C9.73333 13.7375 9.91667 13.7417 10.1 13.625L12 12.475ZM6 18L3.7 20.3C3.38333 20.6167 3.02083 20.6875 2.6125 20.5125C2.20417 20.3375 2 20.025 2 19.575V4C2 3.45 2.19583 2.97917 2.5875 2.5875C2.97917 2.19583 3.45 2 4 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H6ZM5.15 16H20V4H4V17.125L5.15 16Z"
      fill="#1C1B1F"
    />
  </svg>
);
